<!-- <button type="button" class="btn btn-primary" (click)="openModal(tetmplate)">{{'modal.open-first-modal' | translate}}</button> -->

<!-- Sign up popup start  -->

<ng-template #templateNested>
  <div class="">
    <div class="p-4 p-xs-5">
      <div class="form-side">
        <a class="c-pointer">
          <img [src]="IMAGE_URL + 'web_images/user_logo.png'" alt="logo" class="logo-image mb-3" [ngClass]="{'whiteLogo':(logoClr)}">
        </a>
        <button type="button" class="close pull-right" aria-label="Close" (click)="signUpClose()">
          <span aria-hidden="true">&times;</span>
        </button>
        <div [ngClass]="{'d-none': !admin_detail.is_user_social_login}">
        <div class="row social-menu">
          <div class="col-6 mb-3 fb">
            <a href="javascript:void(0)"  (click)="registerWithFB()" class="social"><img height="43px" width="43px"
                src="../../../../assets/img/social/fb.png" alt=""></a>
          </div>
          <div class="col-6 mb-3 google d-flex align-items-center social">
                <div id="buttonDiv1"></div>
          </div>
        </div>
        <div class="text-center mb-1">{{ 'label-title.or' | translate }}</div>
        </div>
        <h6 class="mb-4">{{'user.register' | translate}}</h6>
        <form [formGroup]="signupForm" (ngSubmit)="signUp()">
          <div class="form-group row">
            <label for="name" class="col-sm-3 col-3 col-form-label">{{ 'forms.name' | translate }}</label>
            <div class="col-sm-9 col-9">
              <div class="row">
                <div class="col-6">
                  <input (keypress)="helper.space_validation($event)" type="text" class="form-control"
                    placeholder="{{ 'label-title.first-name' | translate }}" formControlName="first_name" required>
                  <div class="error"
                    *ngIf="signupForm.get('first_name').errors && this.signupForm.get('first_name').touched">
                    <span
                      *ngIf="signupForm.get('first_name').errors.required">{{'validation-title.first-name-is-required' |
                      translate}}</span>
                  </div>
                </div>
                <div class="col-6">
                  <input (keypress)="helper.space_validation($event)" type="text "
                    placeholder="{{ 'label-title.last-name' | translate }}" class="form-control"
                    formControlName="last_name">
                  <div class="error"
                    *ngIf="signupForm.get('last_name').errors && this.signupForm.get('last_name').touched">
                    <span *ngIf="signupForm.get('last_name').errors.required">{{'validation-title.last-name-is-required'
                      | translate}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label for="email" class="col-sm-3 col-3 col-form-label">{{ 'forms.email' | translate }}</label>
            <div class="col-sm-9 col-9">
              <input class="form-control" required email formControlName="email"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" placeholder="{{ 'forms.email' | translate }}">
              <div class="error" *ngIf="signupForm.get('email').errors && this.signupForm.get('email').touched">
                <span *ngIf="signupForm.get('email').errors.required">{{'validation-title.email-is-required' |
                  translate}}</span>
                <span *ngIf="(signupForm.get('email').errors.pattern || signupForm.get('email').errors) && !(signupForm.get('email').errors.required)">{{'validation-title.email-is-invalid' |
                  translate}}</span>
                <span class="error"
                  *ngIf="signupForm.get('email').errors.length === 0 && is_invalid_email">{{'validation-title.email-is-invalid'
                  | translate}}</span>
              </div>
            </div>
          </div>
          
          <div class="form-group row">
            <label for="phone" class="col-sm-3 col-3 col-form-label">{{ 'label-title.phone' | translate }}</label>
            <div class="d-flex col-sm-9 col-9">
              <div class="input-group-prepend w-30 ">
                <input (click)="openPhoneCodeModal(openPhoneCode)" type="text" formControlName="country_phone_code"
                  value="{{sel_countryphonecode}}" class="c-pointer input-group-text bg-foreground w-100" readonly>
              </div>
              <input type="text" onDrop="return false" onPaste="return false" formControlName="phone" class="form-control" min="0" [minlength]="minNumberLength"
                [maxlength]="maxNumberLength" placeholder="{{ 'label-title.phone' | translate }}"
                (keypress)="helper.number_validation($event)">
              <div class="error top-100" *ngIf="signupForm.get('phone').errors && this.signupForm.get('phone').touched">
                <span *ngIf="signupForm.get('phone').errors.required">{{'validation-title.phone-is-required' |
                  translate}}</span>
                <span *ngIf="signupForm.get('phone').errors.minlength">{{'validation-title.phone-minlength-is-required'
                  | translate}}</span>
                <span *ngIf="signupForm.get('phone').errors.maxlength">{{'validation-title.phone-maxlength-is-required'
                  | translate}}</span>
              </div>
            </div>
          </div>
          <div class="form-group row" *ngIf="!register_with_social">
            <label for="password" class="col-sm-3 col-3 col-form-label">{{ 'forms.password' | translate }}</label>
            <div class="col-sm-9 col-9">
              <input type="{{showPassword? 'text' : 'password'}}" class="form-control" formControlName="password"
                minlength="6" placeholder="{{ 'forms.password' | translate }}" required (keypress)="helper.nospace_validation($event)">
              <span id="show-pwd-btn-1" *ngIf="showPassword" class="field-icon c-pointer"
                (click)="showPassword=!showPassword">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
                  <path fill="#7f7f7f"
                    d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" />
                </svg>
              </span>
              <span id="hide-pwd-btn-1" *ngIf="!showPassword" class="field-icon c-pointer"
                (click)="showPassword=!showPassword">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
                  <path fill="#7f7f7f"
                    d="M11.885 14.988l3.104-3.098.011.11c0 1.654-1.346 3-3 3l-.115-.012zm8.048-8.032l-3.274 3.268c.212.554.341 1.149.341 1.776 0 2.757-2.243 5-5 5-.631 0-1.229-.13-1.785-.344l-2.377 2.372c1.276.588 2.671.972 4.177.972 7.733 0 11.985-8.449 11.985-8.449s-1.415-2.478-4.067-4.595zm1.431-3.536l-18.619 18.58-1.382-1.422 3.455-3.447c-3.022-2.45-4.818-5.58-4.818-5.58s4.446-7.551 12.015-7.551c1.825 0 3.456.426 4.886 1.075l3.081-3.075 1.382 1.42zm-13.751 10.922l1.519-1.515c-.077-.264-.132-.538-.132-.827 0-1.654 1.346-3 3-3 .291 0 .567.055.833.134l1.518-1.515c-.704-.382-1.496-.619-2.351-.619-2.757 0-5 2.243-5 5 0 .852.235 1.641.613 2.342z" />
                </svg>
              </span>
              <div class="error" *ngIf="signupForm.get('password').errors && this.signupForm.get('password').touched">
                <span *ngIf="signupForm.get('password').errors.required">{{'validation-title.password-is-required' |
                  translate}}</span>
                <span *ngIf="signupForm.get('password').errors.minlength">{{'validation-title.password-is-too-short' |
                  translate}}</span>
              </div>
            </div>
          </div>
          <div class="custom-control custom-checkbox mb-4">
            <input type="checkbox" id="customCheck" class="custom-control-input" formControlName="is_term_checked">
            <label class="d-flex align-item-center custom-control-label" for="customCheck">
              <span>{{'label-title.agree-text' | translate}} <a class="text-info c-pointer" target="_blank"
                  href="{{TERMS_URL}}">{{'button-title.terms-conditions' | translate}}</a>
                {{'label-title.and' | translate}} <a class="text-info c-pointer" target="_blank"
                  href="{{PRIVACY_URL}}">{{'button-title.privacy-policy' |
                  translate}}</a></span>
            </label>

            <div class="text-danger"
              *ngIf="!signupForm.get('is_term_checked').value && signupForm.get('is_term_checked').touched">
              <span>{{'validation-title.privacy-policy-is-required' | translate}}</span>
            </div>
          </div>
          <div id="captcha-container-register"></div>
          <div class="d-flex justify-content-between align-items-center">
            <p class="mb-0 mt-3">{{'user.have-an-account' | translate}}
              <a class="text-info c-pointer" (click)="show();signUpClose();">{{'user.login-title' |
                translate}}</a>.
            </p>
            <button class="btn btn-primary btn-lg btn-shadow btn-multiple-state show-spinner" type="submit" [disabled]="buttonDisabled">
              <span [ngClass]="{'label':(buttonDisabled)}">{{ 'button-title.submit' | translate }}</span>
              <span *ngIf="buttonDisabled" [ngClass]="{'opacity-100':(buttonDisabled)}"><div class="spinner d-inline-block">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
              </div></span>
            </button>
          </div>
        </form>

      </div>
    </div>
  </div>
</ng-template>

<!-- Sign up popup end  -->

<!-- Sign in popup start  -->

<ng-template #template>
  <div class="">
    <div class="p-4 p-xs-5">
      <div class="form-side">
        <a class="c-pointer">
          <img [src]="IMAGE_URL + 'web_images/user_logo.png'" alt="logo" class="logo-image mb-3" [ngClass]="{'whiteLogo':(logoClr)}">
        </a>
        <button type="button" class="close pull-right" aria-label="Close" (click)="loginClose()">
          <span aria-hidden="true">&times;</span>
        </button>
        <div [ngClass]="{'d-none': !admin_detail.is_user_social_login}">
        <div class="row social-menu">
          <div class="col-6 mb-3 fb">
            <a href="javascript:void(0)" (click)="signInWithFB()"  class="social"><img height="43px" width="43px"
                src="../../../../assets/img/social/fb.png" alt=""></a>
          </div>
          <div class="col-6 mb-3 google d-flex align-items-center social">
                <div id="buttonDiv"></div>
          </div>
        </div>
        <div class="text-center mb-1">{{ 'label-title.or' | translate }}</div>
        </div>
        <h6 class="mb-4">{{'forms.login' | translate}}</h6>
        <form [formGroup]="loginForm" (ngSubmit)="signIn()">

          <div class="form-group row">
            <label for="phone" class="col-12 col-form-label">{{ 'label-title.phone' | translate }}</label>
            <div class="d-flex col-12">
              <div class="input-group-prepend w-20">
                <input (click)="openPhoneCodeModal(openPhoneCode)" type="text" formControlName="country_phone_code"
                  value="{{sel_countryphonecode}}" class="c-pointer input-group-text bg-foreground w-100" readonly>
              </div>
              <input type="text" onDrop="return false" onPaste="return false" name="phone" class="form-control w-80" formControlName="phone"
                (keypress)="helper.number_validation($event)" placeholder="{{ 'label-title.phone' | translate }}">
              <div class="error top-100" *ngIf="loginForm.get('phone').errors && this.loginForm.get('phone').touched">
                <span *ngIf="loginForm.get('phone').errors.required">{{'validation-title.phone-is-required' |
                  translate}}</span>
              </div>
            </div>
            <span class="col-12 form-text text-muted text-small">{{'label-title.phone-statement' | translate}}</span>
          </div>
          <div class="form-group row mb-4">
            <label for="password" class="col-12 col-form-label">{{ 'forms.password' | translate }}</label>
            <div class="col-12">
              <input type="{{showPassword? 'text' : 'password'}}" class="form-control" name="password"
                formControlName="password" placeholder="{{ 'forms.password' | translate }}">
              <span id="show-pwd-btn-1" *ngIf="showPassword" class="field-icon c-pointer"
                (click)="showPassword=!showPassword">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
                  <path fill="#7f7f7f"
                    d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" />
                </svg>
              </span>
              <span id="hide-pwd-btn-1" *ngIf="!showPassword" class="field-icon c-pointer"
                (click)="showPassword=!showPassword">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
                  <path fill="#7f7f7f"
                    d="M11.885 14.988l3.104-3.098.011.11c0 1.654-1.346 3-3 3l-.115-.012zm8.048-8.032l-3.274 3.268c.212.554.341 1.149.341 1.776 0 2.757-2.243 5-5 5-.631 0-1.229-.13-1.785-.344l-2.377 2.372c1.276.588 2.671.972 4.177.972 7.733 0 11.985-8.449 11.985-8.449s-1.415-2.478-4.067-4.595zm1.431-3.536l-18.619 18.58-1.382-1.422 3.455-3.447c-3.022-2.45-4.818-5.58-4.818-5.58s4.446-7.551 12.015-7.551c1.825 0 3.456.426 4.886 1.075l3.081-3.075 1.382 1.42zm-13.751 10.922l1.519-1.515c-.077-.264-.132-.538-.132-.827 0-1.654 1.346-3 3-3 .291 0 .567.055.833.134l1.518-1.515c-.704-.382-1.496-.619-2.351-.619-2.757 0-5 2.243-5 5 0 .852.235 1.641.613 2.342z" />
                </svg>
              </span>
              <div class="error" *ngIf="loginForm.get('password').errors && this.loginForm.get('password').touched">
                <span *ngIf="loginForm.get('password').errors.required">{{'validation-title.password-is-required' |
                  translate}}</span>
              </div>
            </div>
          </div>
          <div id="captcha-container-login"></div>
          <div class="d-flex justify-content-between align-items-center">
            <a class="text-info c-pointer" (click)="openforgotPassModal(forgotPass);loginClose()">{{
              'user.forgot-password' |
              translate }}?</a>
            <button class="btn btn-primary btn-lg btn-shadow btn-multiple-state show-spinner" type="submit" [disabled]="buttonDisabled">
              <span [ngClass]="{'label':(buttonDisabled)}">{{ 'button-title.submit' | translate }}</span>
              <span *ngIf="buttonDisabled" [ngClass]="{'opacity-100':(buttonDisabled)}"><div class="spinner d-inline-block">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
              </div></span>
            </button>
          </div>
        </form>
        <p class="mb-0 mt-3 text-center">{{'user.if-you-are-not-a-member-please' | translate}}
          <a (click)="openModal2(templateNested);loginClose()" class="text-info c-pointer">{{'forms.register' |
            translate}}</a>.
        </p>
      </div>
    </div>
  </div>
</ng-template>

<!-- Sign in popup end  -->

<!-- CountryPhoneCode popup start  -->

<ng-template #openPhoneCode>
  <div class="">
    <div class="py-3 shadow-lg" style="border-radius: 0.75rem;">
      <div class="form-floating rtl-ml-4 mx-4 mb-2">
        <input type="search" name="search" class="form-control" placeholder="{{'label-title.select-country' | translate}}" [(ngModel)]="SearchText" id="search-country">
      </div>

      <div class="p-3" style="height: 280px ; overflow-y:auto;">
        <div class="row c-pointer mx-2 border-bottom border-dark py-1 selected-countrycode" (click)="countryPhoneCode(item)"
          *ngFor="let item of countries | filter:SearchText ">
          <div class="w-20">{{item.code}}</div>
          <div class="w-80">{{item.name}}</div>
        </div>
        <div *ngIf="(countries | filter:SearchText)?.length === 0" class="text-center mt-2">
          <p class="mb-0 font-weight-semibold">{{'label-title.no-matching-country-found' | translate}}</p>
          <!-- <p class="font-weight-semibold mb-4">Please contact the admin.</p> -->
          <div class="mt-4">
            <div class="text-large color-theme-1 mb-1">{{'label-title.contact-us' | translate}}</div>
            <div class="text-one mb-1">
              <a href="tel:{{admin_detail.admin_phone}}" class="color-theme-1"><i
                  class="demo-icon iconsminds-smartphone-3"></i>{{admin_detail.admin_phone}}
              </a>
            </div>
            <div class="text-one mb-1">
              <a href="mailto:{{admin_detail.contactUsEmail}}" class="color-theme-1"><i class="demo-icon iconsminds-email"></i>{{admin_detail.contactUsEmail}}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- CountryPhoneCode popup end  -->

<!-- Forgot Password start  -->

<ng-template #forgotPass>
  <div class="">
    <div class="p-4 p-xs-5">
      <div class="form-side">
        <a class="c-pointer">
          <img [src]="IMAGE_URL + 'web_images/user_logo.png'" alt="logo" class="logo-image" [ngClass]="{'whiteLogo':(logoClr)}">
        </a>
        <button type="button" class="close pull-right" aria-label="Close" (click)="forgotClose()">
          <span aria-hidden="true">&times;</span>
        </button>
        <div *ngIf="!otpFiled && !updatePassField">
          <h6 class="mb-4">{{'forms.forgot-password' | translate}}</h6>
          <div class="d-flex">
            <div class="custom-control custom-radio mb-2 mr-3">
              <input type="radio" id="typename1" name="typename" checked (click)="forgotInput(true)" value="typename1"
                class="custom-control-input">
              <label for="typename1" class="custom-control-label text-one">{{ 'forms.email' | translate }}</label>
            </div>
            <div class="custom-control custom-radio mb-2">
              <input type="radio" id="typename2" name="typename" (click)="forgotInput(false)" value="typename2"
                class="custom-control-input">
              <label for="typename2" class="custom-control-label text-one">{{ 'label-title.phone' | translate }}</label>
            </div>
          </div>
          <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()">
            <div class="form-group row" *ngIf="isChecked">
              <label for="email" class="col-12 col-form-label" hidden>{{ 'forms.email' | translate }}</label>
              <div class="input-group col-12">
                <input class="form-control" required email formControlName="email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" placeholder="{{ 'forms.email' | translate }}">
                <div class="error top-100"
                  *ngIf="forgotPasswordForm.get('email').errors && this.forgotPasswordForm.get('email').touched">
                  <span *ngIf="forgotPasswordForm.get('email').errors.required">{{'validation-title.email-is-required' |
                    translate}}</span>
                  <span *ngIf="(forgotPasswordForm.get('email').errors.pattern || forgotPasswordForm.get('email').errors) && !(forgotPasswordForm.get('email').errors.required)">{{'validation-title.email-is-invalid' |
                    translate}}</span>
                  <span class="error"
                    *ngIf="forgotPasswordForm.get('email').errors.length === 0 && is_invalid_email">{{'validation-title.email-is-invalid'
                    | translate}}</span>
                </div>
              </div>
            </div>
            <div class="form-group row" *ngIf="!isChecked">
              <label for="phone" class="col-12 col-form-label" hidden>{{ 'label-title.phone' | translate }}</label>
              <div class="d-flex col-12">
                <div class="input-group-prepend w-20">
                  <input (click)="openPhoneCodeModal(openPhoneCode)" type="text" formControlName="country_phone_code"
                    value="{{sel_countryphonecode}}" class="c-pointer input-group-text bg-foreground w-100" readonly>
                </div>
                <input type="text" onDrop="return false" onPaste="return false" name="phone" class="form-control w-80" formControlName="phone"
                  (keypress)="helper.number_validation($event)" placeholder="{{ 'label-title.phone' | translate }}">
                <div class="error top-100"
                  *ngIf="forgotPasswordForm.get('phone').errors && this.forgotPasswordForm.get('phone').touched">
                  <span *ngIf="forgotPasswordForm.get('phone').errors.required">{{'validation-title.phone-is-required' |
                    translate}}</span>
                </div>
              </div>
            </div>
            <div id="captcha-container-forgot"></div>
            <div class="d-flex justify-content-end align-items-center mt-2">
              <button class="btn btn-primary btn-lg btn-shadow btn-multiple-state show-spinner" type="submit" [disabled]="buttonDisabled">
                <span [ngClass]="{'label':(buttonDisabled)}">{{'button-title.submit' | translate}}</span>
                <span *ngIf="buttonDisabled" [ngClass]="{'opacity-100':(buttonDisabled)}"><div class="spinner d-inline-block">
                  <div class="bounce1"></div>
                  <div class="bounce2"></div>
                  <div class="bounce3"></div>
                </div></span>
              </button>
            </div>
          </form>
        </div>
        <div *ngIf="otpFiled && !updatePassField ">
          <h6 class="mb-4">{{'label-title.enter-otp' | translate}}</h6>
          <div class="form-group row">
            <div class="input-group col-12">
              <input class="form-control" onDrop="return false" onPaste="return false" maxlength="6" (keypress)="helper.number_validation($event)"
                [(ngModel)]="otpvalue" required placeholder="{{ 'forms.otp' | translate }}">
            </div>
          </div>
          <div class="d-flex justify-content-end align-items-center mt-2">
            <button class="btn btn-primary btn-lg btn-shadow" type="button" (click)="otpsmsMatch()"
              [ngClass]="{'btn-disabled':(!otpvalue && buttonDisabled)}" [disabled]="!otpvalue && buttonDisabled">
              {{'button-title.submit' | translate}}
            </button>
          </div>
        </div>
        <div *ngIf="updatePassField">
          <h6 class="mb-4">{{'label-title.update-password' | translate}}</h6>
          <form [formGroup]="updatePassForm" (ngSubmit)="updatePassword()">
            <div class="form-group row">
              <label for="phone" class="col-12 col-form-label">{{ 'forms.password' | translate }}</label>

              <div class="col-12">
                <input type="{{showPass? 'text' : 'password'}}" class="form-control" formControlName="password"
                  minlength="6" placeholder="{{ 'forms.password' | translate }}" required>
                <span id="show-pwd-btn-1" *ngIf="showPass" class="field-icon c-pointer" (click)="showPass=!showPass">
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
                    <path fill="#7f7f7f"
                      d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" />
                  </svg>
                </span>
                <span id="hide-pwd-btn-1" *ngIf="!showPass" class="field-icon c-pointer" (click)="showPass=!showPass">
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
                    <path fill="#7f7f7f"
                      d="M11.885 14.988l3.104-3.098.011.11c0 1.654-1.346 3-3 3l-.115-.012zm8.048-8.032l-3.274 3.268c.212.554.341 1.149.341 1.776 0 2.757-2.243 5-5 5-.631 0-1.229-.13-1.785-.344l-2.377 2.372c1.276.588 2.671.972 4.177.972 7.733 0 11.985-8.449 11.985-8.449s-1.415-2.478-4.067-4.595zm1.431-3.536l-18.619 18.58-1.382-1.422 3.455-3.447c-3.022-2.45-4.818-5.58-4.818-5.58s4.446-7.551 12.015-7.551c1.825 0 3.456.426 4.886 1.075l3.081-3.075 1.382 1.42zm-13.751 10.922l1.519-1.515c-.077-.264-.132-.538-.132-.827 0-1.654 1.346-3 3-3 .291 0 .567.055.833.134l1.518-1.515c-.704-.382-1.496-.619-2.351-.619-2.757 0-5 2.243-5 5 0 .852.235 1.641.613 2.342z" />
                  </svg>
                </span>
                <div class="error"
                  *ngIf="updatePassForm.get('password').errors && this.updatePassForm.get('password').touched">
                  <span *ngIf="updatePassForm.get('password').errors.required">{{'validation-title.password-is-required'
                    |
                    translate}}</span>
                  <span
                    *ngIf="updatePassForm.get('password').errors.minlength">{{'validation-title.password-is-too-short' |
                    translate}}</span>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="phone" class="col-12 col-form-label">{{ 'label-title.confirm-password' | translate }}</label>
              <div class="col-12">
                <input type="{{showPassword? 'text' : 'password'}}" class="form-control"
                  formControlName="confirmPassword" minlength="6"
                  placeholder="{{ 'label-title.confirm-password' | translate }}" required>
                <span id="show-pwd-btn-1" *ngIf="showPassword" class="field-icon c-pointer"
                  (click)="showPassword=!showPassword">
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
                    <path fill="#7f7f7f"
                      d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" />
                  </svg>
                </span>
                <span id="hide-pwd-btn-1" *ngIf="!showPassword" class="field-icon c-pointer"
                  (click)="showPassword=!showPassword">
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
                    <path fill="#7f7f7f"
                      d="M11.885 14.988l3.104-3.098.011.11c0 1.654-1.346 3-3 3l-.115-.012zm8.048-8.032l-3.274 3.268c.212.554.341 1.149.341 1.776 0 2.757-2.243 5-5 5-.631 0-1.229-.13-1.785-.344l-2.377 2.372c1.276.588 2.671.972 4.177.972 7.733 0 11.985-8.449 11.985-8.449s-1.415-2.478-4.067-4.595zm1.431-3.536l-18.619 18.58-1.382-1.422 3.455-3.447c-3.022-2.45-4.818-5.58-4.818-5.58s4.446-7.551 12.015-7.551c1.825 0 3.456.426 4.886 1.075l3.081-3.075 1.382 1.42zm-13.751 10.922l1.519-1.515c-.077-.264-.132-.538-.132-.827 0-1.654 1.346-3 3-3 .291 0 .567.055.833.134l1.518-1.515c-.704-.382-1.496-.619-2.351-.619-2.757 0-5 2.243-5 5 0 .852.235 1.641.613 2.342z" />
                  </svg>
                </span>
                <div class="error"
                  *ngIf="updatePassForm.get('confirmPassword').errors && this.updatePassForm.get('confirmPassword').touched">
                  <span
                    *ngIf="updatePassForm.get('confirmPassword').errors.required">{{'validation-title.password-is-required'
                    |
                    translate}}</span>
                  <span
                    *ngIf="updatePassForm.get('confirmPassword').errors.minlength">{{'validation-title.password-is-too-short'
                    |
                    translate}}</span>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end align-items-center mt-2">
              <button class="btn btn-primary btn-lg btn-shadow btn-multiple-state show-spinner" type="submit" [disabled]="buttonDisabled">
                <span [ngClass]="{'label':(buttonDisabled)}">{{'button-title.submit' | translate}}</span>
                <span *ngIf="buttonDisabled" [ngClass]="{'opacity-100':(buttonDisabled)}"><div class="spinner d-inline-block">
                  <div class="bounce1"></div>
                  <div class="bounce2"></div>
                  <div class="bounce3"></div>
                </div></span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Forgot Password end  -->
<!-- Referral Modal start  -->

<div class="custom-model-main " *ngIf="referral_modal" [ngClass]="{'model-open':referral_modal}">
  <div class="custom-model-inner " style="width: 460px;">
    <div class="custom-model-wrap ">
      <div class="p-4 p-xs-5">
        <div class="form-side">
          <a class="c-pointer">
            <img [src]="IMAGE_URL + 'web_images/user_logo.png'" alt="logo" class="logo-image" [ngClass]="{'whiteLogo':(logoClr)}">
          </a>
          <h3 class="mb-4 text-c">{{'label-title.referral-code' | translate }}</h3>
          <div class="form-group row">
            <label for="referral_code" class="col-sm-3 col-3 col-form-label"
              [hidden]="true">{{'label-title.referral-code'
              | translate }}</label>
            <div class="col-12">
              <input type="text" [(ngModel)]="referral_code" (keypress)="helper.space_validation($event)"
                placeholder="{{'label-title.referral-code' | translate }}" class="form-control" />
            </div>
          </div>
          <div class="text-center">
            <p>{{'label-title.referral-code-message' | translate }}</p>
          </div>
          <div class="d-flex flex-wrap justify-content-center align-items-center mt-2">
            <button class="btn btn-secondary btn-lg btn-shadow mr-2 mb-2" type="button" (click)="referralCode(is_skip=1)">
              {{'button-title.skip' | translate}}
            </button>
            <button class="btn btn-primary btn-lg btn-shadow mr-2 mb-2" type="button" (click)="referralCode(is_skip=0)">
              {{'button-title.submit' | translate}}
            </button>
          </div>
          <!-- <div class="d-flex justify-content-center align-items-center mt-2">
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="bg-overlay "></div>
</div>

<!-- Referral Modal end  -->
<!-- OTP Verification Modal start  -->

<div class="custom-model-main " *ngIf="signUp_otp_Verification" [ngClass]="{'model-open':signUp_otp_Verification}">
  <div class="custom-model-inner " style="width: 460px;">
    <div class="custom-model-wrap ">
      <div class="p-4 p-xs-5">
        <div class="form-side">
          <a class="c-pointer">
            <img [src]="IMAGE_URL + 'web_images/user_logo.png'" alt="logo" class="logo-image" [ngClass]="{'whiteLogo':(logoClr)}">
          </a>
          <h3 class="mb-4">{{'label-title.otp-verification' | translate }}</h3>
          <div class="row">
            <div class="col-12 mb-3" *ngIf="admin_detail.userEmailVerification">
              <input type="text" onDrop="return false" onPaste="return false" (keypress)="helper.number_validation($event)"
              [(ngModel)]="emailotpValue" placeholder="{{'label-title.email-otp' | translate }}" class="form-control" />
             <div class="error" *ngIf="emailError">{{'label-title.email-otp-not-match' | translate }}</div>
            </div>
            <div class="col-12" *ngIf="admin_detail.userSms">
              <input type="text" onDrop="return false" onPaste="return false" (keypress)="helper.number_validation($event)"
              [(ngModel)]="smsotpValue" placeholder="{{'label-title.sms-otp' | translate }}" class="form-control" />
              <div class="error" *ngIf="smsError">{{'label-title.sms-otp-not-match' | translate }}</div>
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center mt-3">
            <button class="btn btn-primary btn-lg btn-shadow" type="button" (click)="otpVerification()">
            {{'button-title.submit' | translate}}
          </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-overlay "></div>
</div>

<!-- OTP Verification Modal end  -->