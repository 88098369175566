<h5 class="mb-4">{{ 'wizard.icons' | translate }}</h5>
<div class="card">
  <div class="card-body">
    <aw-wizard [navBarLayout]="'large-empty-symbols'">
      <aw-wizard-step stepTitle="Personal Info" style="min-height: 230px;">
        <ng-template awWizardStepSymbol><i class="simple-icon-user"></i></ng-template>
        <div>


		   <form>
				<div class="form-row">
					<div class="form-group col-md-6"><label for="inputEmail4">First name</label><input type="email" id="inputEmail4" class="form-control" placeholder="Enter name"></div>
					<div class="form-group col-md-6"><label for="inputPassword4">Last name</label><input type="password" id="inputPassword4" class="form-control" placeholder="Enter name"></div>
				</div>
				<div class="form-row">
					<div class="form-group col-md-6"><label for="inputCity">Email</label><input type="text" id="inputCity" class="form-control" placeholder="Enter email"></div>
					<div class="form-group col-md-2"><label for="inputState">Country</label><select id="inputState" class="form-control"><option selected="">Select Country</option><option>Country1</option><option>Country2</option></select></div>
					<div class="form-group col-md-4"><label for="inputZip">Phone number</label><input type="text" id="inputZip" class="form-control" placeholder="Enter phone number"></div>
				</div>

		   </form>


          <!-- <div class="form-group">
            <label>{{ 'forms.firstname' | translate }}</label>
            <input type="text" class="form-control" name="name">
          </div>
          <div class="form-group">
            <label>{{ 'forms.lastname' | translate }}</label>
            <input type="text" class="form-control" name="name">
          </div> -->

        </div>
        <div class="text-center">
          <button type="button" class="btn btn-primary" awPreviousStep disabled>{{ 'wizard.prev' | translate }}</button>{{" "}}
          <button type="button" class="btn btn-primary" awNextStep>{{ 'wizard.next' | translate }}</button>
        </div>
      </aw-wizard-step>
	  <aw-wizard-step stepTitle="Location" style="min-height: 230px;">
        <ng-template awWizardStepSymbol><i class="simple-icon-location-pin"></i></ng-template>
        <div>
          <div class="form-group">
            <label>Pickup address</label>
            <input type="text" class="form-control" name="name">
          </div>
          <div class="form-group">
            <label>Destination address</label>
            <input type="text" class="form-control" name="name">
          </div>
        </div>
        <div class="text-center">
          <button type="button" class="btn btn-primary" awPreviousStep>{{ 'wizard.prev' | translate }}</button>{{" "}}
          <button type="button" class="btn btn-primary" awNextStep>{{ 'wizard.next' | translate }}</button>
        </div>
      </aw-wizard-step>
      <aw-wizard-step stepTitle="Location" style="min-height: 230px;">
        <ng-template awWizardStepSymbol><i class="simple-icon-location-pin"></i></ng-template>
        <div>
          <div class="form-group">
            <label>Pickup address</label>
            <input type="text" class="form-control" name="name">
          </div>
          <div class="form-group">
            <label>Destination address</label>
            <input type="text" class="form-control" name="name">
          </div>
        </div>
        <div class="text-center">
          <button type="button" class="btn btn-primary" awPreviousStep>{{ 'wizard.prev' | translate }}</button>{{" "}}
          <button type="button" class="btn btn-primary" awNextStep>{{ 'wizard.next' | translate }}</button>
        </div>
      </aw-wizard-step>
      <aw-wizard-completion-step stepTitle="{{ 'wizard.done' | translate }}" style="min-height: 230px;">
        <ng-template awWizardStepSymbol><i class="simple-icon-check"></i></ng-template>
        <div class="text-center complete-message-centered">
          <h2 class="mb-2">{{ 'wizard.content-thanks' | translate }}</h2>
          <p>{{ 'wizard.registered' | translate }}</p>
        </div>
      </aw-wizard-completion-step>
    </aw-wizard>
  </div>
</div>
















<!-- <h5 class="mb-4">{{ 'wizard.icons' | translate }}</h5>
<div class="card">
  <div class="card-body">
    <aw-wizard [navBarLayout]="'large-empty-symbols'">
      <aw-wizard-step stepTitle="Personal Info" style="min-height: 230px;">
        <ng-template awWizardStepSymbol><i class="simple-icon-user"></i></ng-template>
        <div>
          <div class="form-group">
            <label>{{ 'forms.firstname' | translate }}</label>
            <input type="text" class="form-control" name="name">
          </div>
          <div class="form-group">
            <label>{{ 'forms.lastname' | translate }}</label>
            <input type="text" class="form-control" name="name">
          </div>
        </div>
        <div class="text-center">
          <button type="button" class="btn btn-primary" awPreviousStep disabled>{{ 'wizard.prev' | translate }}</button>{{" "}}
          <button type="button" class="btn btn-primary" awNextStep>{{ 'wizard.next' | translate }}</button>
        </div>
      </aw-wizard-step>
      <aw-wizard-step stepTitle="Delivery Location" style="min-height: 230px;">
        <ng-template awWizardStepSymbol><i class="simple-icon-location-pin"></i></ng-template>
        <div>
          <div class="form-group">
            <label>{{ 'forms.city' | translate }}</label>
            <input type="text" class="form-control" name="name">
          </div>
          <div class="form-group">
            <label>{{ 'forms.address' | translate }}</label>
            <textarea class="form-control" rows="2"></textarea>
          </div>
        </div>
        <div class="text-center">
          <button type="button" class="btn btn-primary" awPreviousStep>{{ 'wizard.prev' | translate }}</button>{{" "}}
          <button type="button" class="btn btn-primary" awNextStep>{{ 'wizard.next' | translate }}</button>
        </div>
      </aw-wizard-step>
      <aw-wizard-completion-step stepTitle="{{ 'wizard.done' | translate }}" style="min-height: 230px;">
        <ng-template awWizardStepSymbol><i class="simple-icon-check"></i></ng-template>
        <div class="text-center complete-message-centered">
          <h2 class="mb-2">{{ 'wizard.content-thanks' | translate }}</h2>
          <p>{{ 'wizard.registered' | translate }}</p>
        </div>
      </aw-wizard-completion-step>
    </aw-wizard>
  </div>
</div> -->
