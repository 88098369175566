import { Component } from '@angular/core';

@Component({
  selector: 'app-wizard-vertical',
  templateUrl: './wizard-vertical.component.html'
})
export class WizardVerticalComponent  {

  constructor() { }



}
