import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { apiColletions } from '../constants/api_collection';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(private _api: ApiService) { }

  get_history(parameters): Promise<any> {
    return new Promise((resolve, reject) => {

      try {
        this._api.post({ url: apiColletions.userhistory, parameters }).then(response => {
          if (response.success) {
            resolve(response.data)

          } else {
            resolve([])
          }
        })
      } catch {
        resolve([])
      }
    })
  }
  rate_Us(parameters): Promise<any> {
    return new Promise((resolve, reject) => {

      try {
        this._api.post({ url: apiColletions.usergiverating, parameters }).then(response => {
          if (response.success) {
            resolve(response.data)

          } else {
            resolve([])
          }
        })
      } catch {
        resolve([])
      }
    })
  }
  get_usertripdetail(parameters): Promise<any> {
    return new Promise((resolve, reject) => {

      try {
        this._api.post({ url: apiColletions.usertripdetail, parameters }).then(response => {
          if (response.success) {
            resolve(response.data)

          } else {
            resolve([])
          }
        })
      } catch {
        resolve([])
      }
    })
  }
  get_getfuture_history(parameters): Promise<any>{
    return new Promise((resolve, reject) => {

      try {
        this._api.post({ url: apiColletions.getfuturetrip, parameters }).then(response => {
          if (response.success) {
            resolve(response.data)

          } else {
            resolve([])
          }
        })
      } catch {
        resolve([])
      }
    })
  }


  get_usergettripstatus(parameters): Promise<any> {
    return new Promise((resolve, reject) => {

      try {
        parameters.is_show_error_toast = false;
        parameters.is_show_success_toast = false;
        this._api.post({ url: apiColletions.usergettripstatus, parameters }).then(response => {
          if (response.success) {
            resolve(response.data)

          } else {
            resolve([])
          }
        })
      } catch {
        resolve([])
      }
    })
  }

  get_provider_info(parameters): Promise<any> {
    return new Promise((resolve, reject) => {

      try {
        parameters.is_show_error_toast = false;
        parameters.is_show_success_toast = false;
        this._api.post({ url: apiColletions.get_provider_info, parameters }).then(response => {
          if (response.success) {
            resolve(response.data)

          } else {
            resolve([])
          }
        })
      } catch {
        resolve([])
      }
    })
  }


  user_submit_invoice(parameters): Promise<any> {
    return new Promise((resolve, reject) => {

      try {
        this._api.post({ url: apiColletions.user_submit_invoice, parameters }).then(response => {
          if (response.success) {
            resolve(response.data)

          } else {
            resolve([])
          }
        })
      } catch {
        resolve([])
      }
    })
  }

}
