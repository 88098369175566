import { Component} from '@angular/core';

@Component({
  selector: 'app-wizard-end-step',
  templateUrl: './wizard-end-step.component.html'
})
export class WizardEndStepComponent  {

  constructor() { }



}
