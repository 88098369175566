import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DEFAULT_IMAGE, SPLIT_PAYMENT } from 'src/app/constants/constants';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { CreateTripService } from 'src/app/services/create-trip.service';
import { PaymentService } from 'src/app/services/payment.service';
import { Helper } from 'src/app/shared/helper';
import { LangService, Language } from 'src/app/shared/lang.service';
import { environment } from 'src/environments/environment';
import { AddcardModalComponent } from '../../pages/addcard-modal/addcard-modal.component';
import { SocketService } from 'src/app/services/socket.service';
import { NotifiyService } from 'src/app/services/notifier.service';
declare var stripe: any;

@Component({
  selector: 'app-topnav2',
  templateUrl: './topnav2.component.html',
  styleUrls: ['./topnav2.component.scss']
})
export class Topnav2Component implements OnInit,OnChanges {

  IMAGE_URL = environment.IMAGE_URL;
  DEFAULT_USER_PROFILE = DEFAULT_IMAGE.USER_PROFILE;
  languages: Language[];
  isSingleLang;
  splitPaymentRequest: boolean;
  corporateRequestModal: boolean;
  go_to_payment: boolean;
  cardData: any;
  split_payment_request: any;
  confirm: number = 0;
  status: number;
  payment_mode: number = 1;
  corporate_details: any;

  @Input() is_address_changed;

  @ViewChild('addCardModal', { static: true }) addCardModal: AddcardModalComponent;
  
  constructor(public _notifierService: NotifiyService, public _authService: AuthService, private _socket: SocketService, public helper: Helper, private langService: LangService, private _createTripService: CreateTripService, private _commonService: CommonService, private _paymentService: PaymentService) {
    this.languages = this.langService.supportedLanguages;
    this.isSingleLang = this.langService.isSingleLang;
  }

  @Output() addNewItem: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    if (this.helper.user_details) {
      this.helper.islogin = false;
      this.fetchCardList();
      this.fetchUserSettingDetail(this.helper.user_details._id);
      this.socket(this.helper.user_details._id)
      this.adminDeclineSocket(this.helper.user_details._id)
    }
    if (this.helper.user_details == null) {
      this.helper._route.navigate(['/app/create-trip'])
    }
        
  }

  ngOnChanges(changes: SimpleChanges): void {
    
  }

  adminDeclineSocket(id: any) {
    
    let listner = id    
    this._socket.listener(listner).subscribe((res: any) => {     
      console.log(res);
      if (res.is_admin_decline) {
        let json: any = { token: this.helper.user_details.server_token, user_id: this.helper.user_details._id,is_admin_decline:true };
        this._authService.user_logout(json).then(()=>{})
    }
    })
  }
  signOut() {
    let json: any = { token: this.helper.user_details.server_token, user_id: this.helper.user_details._id };
    this._authService.user_logout(json);
  }

  onAddNewItem(): void {
    this.addNewItem.emit(null);
  }

  onLanguageChange(lang): void {
    this.langService.language = lang.code;
    // window.location.reload();
  }

  async fetchCardList() {
    let json: any = { server_token: this.helper.user_details.server_token, user_id: this.helper.user_details._id, type: 10 };
    this.cardData = await this._paymentService.get_card_list(json);

  }


  payAgain() {
    if (this.cardData.payment_gateway_type == 12) {
      let json: any = { user_id: this.helper.user_details._id, token: this.helper.user_details.server_token, trip_id: this.split_payment_request._id, payment_gateway_type: this.cardData.payment_gateway_type, type: 10 }
      this._paymentService.get_payu_payment_intent_wallet(json).then((response) => {
        var template = document.getElementById('Payu');
        template.innerHTML = response.data.html_form;
        document.body.appendChild(template);
        (<HTMLFormElement>document.getElementById("myForm")).submit();
      })

    } else {
      const idx = this.cardData.card.findIndex(_c => _c.is_default);
      var card_id = this.cardData.card[idx]._id;
      if (this.cardData.payment_gateway_type == 10) {
        let json: any = { card_id: card_id, trip_id: this.split_payment_request.trip_id, user_id: this.helper.user_details._id, token: this.helper.user_details.server_token, amount: this.split_payment_request.total, payment_gateway_type: this.cardData.payment_gateway_type, type: 10 }
        this._paymentService.get_stripe_payment_intent_wallet(json).then((response: any) => {
          if (response.success) {
            if (response.message == 109) {
            } else {
              stripe.confirmCardPayment(response.client_secret, { payment_method: response.payment_method }).then((result: any) => {
                if (result.paymentIntent) {
                  let json: any = { user_id: this.helper.user_details._id, token: this.helper.user_details.server_token, payment_gateway_type: this.cardData.payment_gateway_type, payment_intent_id: result.paymentIntent.id, trip_id: this.split_payment_request._id, type: 10 }
                  if (result.paymentIntent) {
                    this._paymentService.pay_stripe_intent_payment(json).then(is_added => {
                      if (is_added) {
                        this.split_pay = false
                      }
                    })
                  }
                } else {
                  this.split_pay = true;
                  this._notifierService.showNotification('error', result.error.message);
                }
              });
            }
          } else {
            this._notifierService.showNotification('error', response.error);
          }
        }).catch(error => {
          this._notifierService.showNotification('error', error);
        })
      }
      else if (this.cardData.payment_gateway_type == 11) {
        let json: any = { card_id: card_id, trip_id: this.split_payment_request.trip_id, user_id: this.helper.user_details._id, token: this.helper.user_details.server_token, payment_gateway_type: this.cardData.payment_gateway_type }
        this._paymentService.get_paystack_payment_intent_wallet(json).then((response) => {
          if (response.success) {
            this.split_pay = false;
          }
          if (!response.success && response.data) {
            this.split_pay = true
          }
        })
      }
    }
  }

  fetchUserSettingDetail(id) {
    let json: any = { token: this.helper.user_details.server_token, user_id: id }
    this._commonService.get_setting_detail(json).then((user_setting_detail) => {
      this.helper.created_at.next(user_setting_detail.user_detail.created_at);
      if (user_setting_detail.split_payment_request) {
        this.split_payment_request = user_setting_detail.split_payment_request;
        this.splitPaymentRequest = true;
        if (this.split_payment_request.is_trip_end == 1) {
          this.splitPaymentRequest = false;
        }
      }
      if (user_setting_detail.user_detail.is_document_uploaded == 0) {
        this._notifierService.showNotification('error', this.helper.trans.instant('validation-title.add-mandatory-documents'));
      }
      if (user_setting_detail.user_detail.corporate_detail && user_setting_detail.user_detail.corporate_detail.status == 0) {
        this.corporate_details = user_setting_detail.user_detail.corporate_detail;
        this.corporateRequestModal = true;
      }
    })
  }
  socket(id: any) {
    let listner = id
    this._socket.listener(listner).subscribe((res: any) => {
      this.fetchUserSettingDetail(res.type_id)
      this.split_card_pay(res.type_id)
    })
  }
  split_pay = false;
  split_pay_json: any
  split_card_pay(id) {
    let json: any = { token: this.helper.user_details.server_token, user_id: id }
    this._commonService.get_setting_detail(json).then((user_setting_detail) => {
      if (user_setting_detail.split_payment_request) {
        this.split_payment_request = user_setting_detail.split_payment_request;
        if (this.split_payment_request.is_trip_end == 1 && this.split_payment_request.payment_mode == 0 && this.split_payment_request.payment_status != 1) {
          this.split_pay = true
          this.split_pay_json = this.split_payment_request
        } else {
          this.split_pay = false
        }
      }
    })
  }

  accept_reject() {
    // if(this.cardData.card.length == 0){
    //   return
    // }
    if (this.payment_mode == 0) {
      this.accept();
    }
    if (this.payment_mode == 1) {
      this.accept()
    }
    if ((this.payment_mode == 1 && this.confirm == 1) || (this.cardData.wallet !== 0 && this.payment_mode == 0 && this.confirm == 1)) {
      this.status = SPLIT_PAYMENT.ACCEPTED
    } else {
      this.status = SPLIT_PAYMENT.REJECTED
    }
    let json: any = { token: this.helper.user_details.server_token, user_id: this.helper.user_details._id, trip_id: this.split_payment_request.trip_id, status: this.status };
    this._createTripService.accept_or_reject_split_payment_request(json).then(response => {
      if (response.success) {
        this.splitPaymentRequest = false;
      }
    })
  }

  selectMethod(method) {
    if (method == 'cash') {
      this.payment_mode = 1;
    }
    if (method == 'card') {
      this.payment_mode = 0;
    }
  }

  accept() {
    let json: any = { token: this.helper.user_details.server_token, user_id: this.helper.user_details._id, trip_id: this.split_payment_request.trip_id, payment_mode: this.payment_mode }
    this._createTripService.update_split_payment_payment_mode(json).then(response => {
      if (response.success) {
        this.confirm = 1;
      }
    })
  }

  openaddCardModal(): void {
    this.addCardModal.show();
    // if (this.cardData.payment_gateway_type == 10) {
    this.addCardModal.openaddCardModal();
    // }
  }

  selectDefault(card) {
    var card_id = card._id;
    var payment_gateway_type = card.payment_gateway_type;
    let json: any = { card_id: card_id, payment_gateway_type: payment_gateway_type, user_id: this.helper.user_details._id, token: this.helper.user_details.server_token, type: 10 }
    this._paymentService.select_card(json).then((is_selected: any) => {
      if (is_selected) {
        this.cardData.card.forEach((element: any) => {
          element.is_default = 0
        });
        const index = this.cardData.card.findIndex((item: any) => {
          return item._id == is_selected.data.card._id
        });
        this.cardData.card[index].is_default = 1
      }
    })
  }

  yes(bool) {
    let json: any = { user_id: this.helper.user_details._id, token: this.helper.user_details.server_token, is_accepted: bool, corporate_id: this.corporate_details._id }
    this._commonService.user_accept_reject_corporate_request(json).then((response) => {
      if (response.success) {
        this.corporateRequestModal = false;
      }
    })
  }

}
