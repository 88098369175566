import { Component} from '@angular/core';

@Component({
  selector: 'app-wizard-basic',
  templateUrl: './wizard-basic.component.html'
})
export class WizardBasicComponent  {

  constructor() { }



}
