<app-auth-modal #authModal></app-auth-modal>

<div [ngClass]="{'landing-page': true,'show-mobile-menu': showMobileMenu}">
    <div class="mobile-menu" (click)="$event.stopPropagation()">
        <a (click)="scrollTo('scroll_1')" href="javascript:;" class="">
            <img [src]="IMAGE_URL + 'web_images/user_logo.png'" alt="logo" class="logo-image">
        </a>
        <ul class="navbar-nav">
            <li class="nav-item" *ngIf="citytypes"><a (click)="scrollTo('scroll_2')"
                    href="javascript:;">{{'home-page.ride-with-us' | translate}}</a>
            </li>
            <li class="nav-item"><a href="{{DRIVER_WEB}}" target="_blank">{{'home-page.drive-with-us' | translate}}</a>
            </li>
            <li class="nav-item"><a (click)="scrollTo('scroll_3')" href="javascript:;">{{'home-page.app' | translate}}</a>
            </li>
        </ul>
    </div>

    <div class="main-container">
        <ngx-headroom class="landing-page-nav">
            <nav>
                <div class="container d-flex align-items-center justify-content-between">
                    <a class="navbar-logo">
                        <span class="white">
                            <img [src]="IMAGE_URL + 'web_images/user_logo.png'" alt="logo" class="navbar-logo whiteLogo">
                        </span>
                        <span class="dark">
                            <img [src]="IMAGE_URL + 'web_images/user_logo.png'" alt="logo" class="navbar-logo"
                                *ngIf="!logoClr">
                            <img [src]="IMAGE_URL + 'web_images/user_logo.png'" alt="logo" class="navbar-logo whiteLogo"
                                *ngIf="logoClr">
                        </span>
                    </a>
                    <div class="d-flex align-items-center">
                        <ul class="navbar-nav d-none d-lg-flex flex-row">
                            <li class="nav-item" *ngIf="citytypes"><a (click)="scrollTo('scroll_2')"
                                    href="javascript:;">{{'home-page.ride-with-us' |
                                    translate}}</a></li>
                            <li class="nav-item"><a href="{{DRIVER_WEB}}" target="_blank">{{'home-page.drive-with-us' |
                                    translate}}</a>
                            </li>
                            <li class="nav-item"><a (click)="scrollTo('scroll_3')" href="javascript:;">{{'home-page.app' |
                                    translate}}</a></li>
                        </ul>
                        <ul class="navbar-nav d-flex flex-row">
                            <li class="nav-item pr-0">
                                <div dropdown>
                                    <a *ngIf="true" type="button" dropdownToggle=""
                                        class="btn btn-outline-primary userName d-flex align-items-center fz-1rem"
                                        aria-haspopup="true">
                                        <span class="userName truncate">{{this.langService.languageShorthand |
                                            uppercase}} </span>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right mt-3 position-absolute" *dropdownMenu
                                        role="menu">
                                        <a class="dropdown-item c-pointer" *ngFor="let item of languages"
                                            (click)="onLanguageChange(item)">{{item.label | titlecase}}</a>
                                    </div>
                                </div>
                            </li>
                            <li class="nav-item">
                                <a class="btn btn-outline-primary btn-sm pr-4 pl-4 d-flex align-items-center c-pointer"
                                    (click)="openModal()" *ngIf="!helper.user_details">
                                    {{'button-title.login' | translate }}
                                </a>
                                <div dropdown>
                                    <button *ngIf="helper.user_details" type="button" dropdownToggle=""
                                        class="btn btn-primary userName d-flex align-items-center" aria-haspopup="true">
                                        <img class="lazyload userimg" (error)="$event.target.src = DEFAULT_USER_PROFILE"
                                            [src]="helper.user_details.picture ? (IMAGE_URL + helper.user_details.picture) : DEFAULT_USER_PROFILE"
                                            [alt]="helper.user_details.first_name">
                                        <span
                                            class="userName truncate d-none d-xs-block">{{helper.user_details.first_name}}</span>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right mt-3 position-absolute" *dropdownMenu
                                        role="menu">
                                        <a class="dropdown-item c-pointer"
                                            routerLink="/app/profile">{{'label-title.account' | translate}}</a>
                                        <a class="dropdown-item c-pointer"
                                            routerLink="/app/create-trip">{{'label-title.book-trip' | translate}}</a>
                                        <a class="dropdown-item c-pointer"
                                            routerLink="/app/history">{{'label-title.history' | translate}} </a>
                                        <a class="dropdown-item c-pointer"
                                            routerLink="/app/running-request">{{'label-title.running-request' |
                                            translate}}
                                        </a>

                            <li class="divider dropdown-divider"></li>
                            <a class="dropdown-item c-pointer " (click)="signOut()">{{'label-title.sign-out' |
                                translate}}</a>
                    </div>
                </div>
                </li>
                </ul>
                <span class="mobile-menu-button pl-4"
                    (click)="showMobileMenu=!showMobileMenu; $event.stopPropagation()">
                    <i class="simple-icon-menu"></i>
                </span>
    </div>
</div>
</nav>
</ngx-headroom>


<div class="content-container" id="scroll_1">

    <div class="section home" [ngStyle]="{background: 'url('+ IMAGE_URL+'user_panel_images/bg_image.jpg' + ') no-repeat 0 0 / cover', height: '100vh'}" style="background-repeat: no-repeat; background-size: cover">

        <div class="container">
            <div class="row home-row">
                <div class="col-12 d-block d-md-none">
                </div>

                <div class="col-12 col-xl-4 col-lg-5 col-md-6">
                    <div class="home-text">
                        <div class="display-1 mb-0">{{'home-page.book_taxi_at' | translate}}</div>
                        <div class="col-12 p-0 footer-content my-2">
                            <a (click)="scrollTo('scroll_1')" href="javascript:;">
                                <img class="eber-logo whiteLogo" alt="footer logo" [src]="IMAGE_URL + 'web_images/user_logo.png'">
                            </a>
                        </div>

                        <p class="white mb-0">
                            {{'home-page.need_comfortable_ride' | translate}}<br />
                            <br />
                            {{'home-page.book_with_eber' | translate}} <br />
                            <br />
                        </p>
                        <div class="d-flex flex-column w-fit-content home-btn">
                            <a class="btn btn-light btn-xl mr-2 mb-2 c-pointer" *ngIf="!helper.user_details"
                                (click)="RegisterModal()">
                                {{'home-page.login_now' | translate}}<i class="simple-icon-arrow-right"></i>
                            </a>
                            <a class="btn btn-light btn-xl mr-2 mb-2 c-pointer" routerLink="/app/create-trip"
                                *ngIf="helper.user_details">
                                {{'home-page.ride_now' | translate}}<i class="simple-icon-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
            </div>
        </div>
    </div>

    <div class="section mb-0 pb-0" *ngIf="!helper.user_details">
        <div class="container" id="get_fare_estimate">
            <div>
                <div class="row overflow-hidden">
                    <div class="col-12 col-md-6 col-lg-6">
                        <img alt="feature.title" [src]="IMAGE_URL+'user_panel_images/map.jpg'"
                            style="object-position: unset;height: 400px;"
                            class="feature-image-left feature-image-charts fare-estimate-img" />
                    </div>
                    <div
                        class="col-12 col-md-6 offset-md-0 col-lg-5 offset-lg-1 d-flex align-items-center justify-content-center text-center">
                        <div class="feature-text-container mt-4">
                            <h2>{{'home-page.to_get_fare_estimate' | translate}}</h2>
                            <a class="btn btn-primary btn-xl mr-2 mb-2 c-pointer" routerLink="/app/create-trip">
                                {{'home-page.Get_Fare_Estimate' | translate | uppercase}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="section">
        <div class="container" id="features">
            <div class="row">
                <div class="col-12 offset-0 col-lg-8 offset-lg-2 text-center">
                    <h1>{{'home-page.looking_for_worldclass_taxi' | translate}}</h1>
                    <p>
                        {{'home-page.get_started_with_eber_taxi' | translate}}
                    </p>
                </div>
            </div>

            <div>
                <div class="row feature-row overflow-hidden">
                    <div class="col-12 col-md-6 col-lg-5 d-flex align-items-center">
                        <div class="feature-text-container">
                            <h2>{{'home-page.rentals' | translate}}</h2>
                            <p>{{'home-page.about_rentals' | translate}}</p>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-6 offset-lg-1 offset-md-0 position-relative">
                        <img alt="{{'home-page.rentals' | translate}}" [src]="IMAGE_URL+'user_panel_images/rentals.png'"
                            class="feature-image-right feature-image-charts position-relative" />
                    </div>
                </div>
            </div>
            <div>
                <div class="row feature-row overflow-hidden">
                    <div class="col-12 col-md-6 col-lg-6 order-2 order-md-1">
                        <img alt="{{'home-page.taxi_hailing' | translate}}" [src]="IMAGE_URL+'user_panel_images/taxi_hailing.jpeg'"
                            class="feature-image-left feature-image-charts" />
                    </div>
                    <div
                        class="col-12 col-md-6 offset-md-0 col-lg-5 offset-lg-1 d-flex align-items-center order-1 order-md-2">
                        <div class="feature-text-container">
                            <h2>{{'home-page.taxi_hailing' | translate}}</h2>
                            <p class="mb-4">{{'home-page.about_taxi_hailing1' | translate}}</p>
                            <p>{{'home-page.about_taxi_hailing2' | translate}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="row feature-row overflow-hidden">
                    <div class="col-12 col-md-6 col-lg-5 d-flex align-items-center">
                        <div class="feature-text-container">
                            <h2>{{'home-page.ride_share' | translate}}</h2>
                            <p class="mb-4">{{'home-page.about_ride_share1' | translate}}</p>
                            <p>{{'home-page.about_ride_share2' | translate}}</p>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-6 offset-lg-1 offset-md-0 position-relative">
                        <img alt="{{'home-page.ride_share' | translate}}" [src]="IMAGE_URL+'user_panel_images/ride_share.jpg'"
                            class="feature-image-right feature-image-charts position-relative" />
                    </div>
                </div>
            </div>
            <div>
                <div class="row feature-row overflow-hidden">
                    <div class="col-12 col-md-6 col-lg-6 order-2 order-md-1">
                        <img alt="{{'home-page.split_payment' | translate}}" [src]="IMAGE_URL+'user_panel_images/split_payment.jpg'"
                            class="feature-image-left feature-image-charts" />
                    </div>
                    <div
                        class="col-12 col-md-6 offset-md-0 col-lg-5 offset-lg-1 d-flex align-items-center order-1 order-md-2">
                        <div class="feature-text-container">
                            <h2>{{'home-page.split_payment' | translate}}</h2>
                            <p class="mb-4">{{'home-page.about_split_payment1' | translate}}</p>
                            <p>{{'home-page.about_split_payment2' | translate}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="row feature-row overflow-hidden">
                    <div class="col-12 col-md-6 col-lg-5 d-flex align-items-center">
                        <div class="feature-text-container">
                            <h2>{{'home-page.driver_going_to_home' | translate}}</h2>
                            <p class="mb-4">{{'home-page.about_driver_going_to_home1' | translate}}</p>
                            <p>{{'home-page.about_driver_going_to_home2' | translate}}</p>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-6 offset-lg-1 offset-md-0 position-relative">
                        <img alt="{{'home-page.driver_going_to_home' | translate}}" [src]="IMAGE_URL+'user_panel_images/driver_going_home.jpg'"
                            class="feature-image-right feature-image-charts position-relative" />
                    </div>
                </div>
            </div>
            <div>
                <div class="row feature-row overflow-hidden">
                    <div class="col-12 col-md-6 col-lg-6 order-2 order-md-1">
                        <img alt="MULTIPLE STOP" [src]="IMAGE_URL+'user_panel_images/multiple_stop.png'"
                            class="feature-image-left feature-image-charts" />
                    </div>
                    <div
                        class="col-12 col-md-6 offset-md-0 col-lg-5 offset-lg-1 d-flex align-items-center order-1 order-md-2">
                        <div class="feature-text-container">
                            <h2>{{'home-page.multiple_stop' | translate}}</h2>
                            <p class="mb-4">{{'home-page.about_multiple_stop1' | translate}}</p>
                            <p>{{'home-page.about_multiple_stop2' | translate}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section background">
        <div class="container" id="layouts">
            <div class="row">
                <div class="col-12 offset-0 col-lg-8 offset-lg-2 text-center">

                    <h1>{{'home-page.ride_comfortably_with_eber' | translate}}</h1>

                    <p>
                        {{'home-page.do_you_need_taxi_at_your_doorsteps' | translate}}
                        <br>
                        {{'home-page.choose_eber_fixed_prices' | translate}}
                        <br>

                    </p>
                </div>
            </div>

            <div class="row pt-5">
                <div class="col-6 col-xs-4 col-sm-4 col-md-3 col-lg-2 mb-5 ">
                    <img class="img-fluid border-radius depth-2 mb-3 semi-rounded" style="padding: 15px;" [src]="IMAGE_URL+'user_panel_images/extensive_range_of_cabs.png'">
                    <h4 class="text-center">{{'home-page.extensive_range_of_cabs' | translate}}</h4>
                </div>
                <div class="col-6 col-xs-4 col-sm-4 col-md-3 col-lg-2 mb-5 ">
                    <img class="img-fluid border-radius depth-2 mb-3 semi-rounded" style="padding: 15px;" [src]="IMAGE_URL+'user_panel_images/secure_and_swift_ride.png'">
                    <h4 class="text-center">{{'home-page.secure_and_swift_ride' | translate}}</h4>
                </div>
                <div class="col-6 col-xs-4 col-sm-4 col-md-3 col-lg-2 mb-5 ">
                    <img class="img-fluid border-radius depth-2 mb-3 semi-rounded" style="padding: 15px;" [src]="IMAGE_URL+'user_panel_images/licensed_drivers.png'">
                    <h4 class="text-center">{{'home-page.licensed_drivers' | translate}}</h4>
                </div>
                <div class="col-6 col-xs-4 col-sm-4 col-md-3 col-lg-2 mb-5 ">
                    <img class="img-fluid border-radius depth-2 mb-3 semi-rounded" style="padding: 15px;" [src]="IMAGE_URL+'user_panel_images/cashless_rides.png'">
                    <h4 class="text-center">{{'home-page.cashless_rides' | translate}}</h4>
                </div>
                <div class="col-6 col-xs-4 col-sm-4 col-md-3 col-lg-2 mb-5 ">
                    <img class="img-fluid border-radius depth-2 mb-3 semi-rounded" style="padding: 15px;" [src]="IMAGE_URL+'user_panel_images/airport_transfer.png'">
                    <h4 class="text-center">{{'home-page.airport_transfer' | translate}}</h4>
                </div>
                <div class="col-6 col-xs-4 col-sm-4 col-md-3 col-lg-2 mb-5 ">
                    <img class="img-fluid border-radius depth-2 mb-3 semi-rounded" style="padding: 15px;" [src]="IMAGE_URL+'user_panel_images/flexible_booking.png'">
                    <h4 class="text-center">{{'home-page.flexible_bookings' | translate}}</h4>
                </div>
            </div>
        </div>
    </div>

    <div class="section" *ngIf="citytypes">
        <div class="container" id="scroll_2">
            <div class="row">
                <div class="col-12 offset-0 col-lg-8 offset-lg-2 text-center mb-4">
                    <h1>{{'home-page.taxi' | translate | titlecase}}</h1>
                    <p class="section-text">
                        {{'home-page.options_for_where_you_going' | translate}}
                    </p>
                </div>
            </div>
            <div class="row screenshots">
                <div class="col-12 text-center mb-4">
                    <tabset class="nav nav-tabs justify-content-center" [justified]="true">
                        <tab heading="{{ 'home-page.normal' | translate}}" class="card-body h-100">
                            <!-- Now -->
                            <!-- <a> -->
                                <div class="row pt-2">
                                    <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-left "
                                        *ngFor="let item1 of citytypes; let i=index">
                                        <div class="">
                                            <app-profile-user-portfolio>
                                                <a>
                                                    <div class="card mb-4 " style="text-align: -webkit-left;">
                                                        <img (error)="$event.target.src = TAXI_ICON" alt="Profile"
                                                            [src]="citytypes[i].type_details.type_image_url ? (IMAGE_URL + citytypes[i].type_details.type_image_url) : TAXI_ICON"
                                                            alt="vehicle" class="card-img-top object-fit-contain"
                                                            style="height: 100px;">
                                                        <div class="card-body p-3">
                                                            <h4 class="text-center">{{item1.type_details.typename}}
                                                            </h4>
                                                            <p class="text-muted text-small mb-2">
                                                                {{'home-page.about' | translate}}</p>
                                                            <p class="text-muted text-small mb-2">
                                                                {{'home-page.location' | translate}}</p>
                                                            <p class="mb-3">{{item1.cityname}},
                                                                {{item1.countryname}}</p>
                                                            <div class="ps-content">
                                                                <table class="table table-sm table-borderless taxi-table">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td class="td-left"> <span
                                                                                    class="font-weight-medium">{{'home-page.base_price'
                                                                                    | translate}}</span> </td>
                                                                            <td class="text-right">
                                                                                <span
                                                                                    class="text-muted">{{typelist.currency}}{{math.round(item1.base_price)}}/
                                                                                    <span
                                                                                        *ngIf="item1.base_price_distance>1">{{item1.base_price_distance}}</span>
                                                                                    {{'home-page.km' | translate}}
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="td-left">
                                                                                <span
                                                                                    class="font-weight-medium">{{'home-page.distance_price'
                                                                                    | translate}}</span>
                                                                            </td>
                                                                            <td class="text-right">
                                                                                <span
                                                                                    class="text-muted">{{typelist.currency}}{{item1.price_per_unit_distance}}</span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="td-left">
                                                                                <span
                                                                                    class="font-weight-medium">{{'home-page.time_price'
                                                                                    | translate}}</span>
                                                                            </td>
                                                                            <td class="text-right">
                                                                                <span
                                                                                    class="text-muted">{{typelist.currency}}{{item1.price_for_total_time}}</span>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </app-profile-user-portfolio>
                                        </div>
                                    </div>
                                </div>
                            <!-- </a> -->
                        </tab>

                        <tab heading="{{ 'home-page.ride-share' | translate}}" class="card-body h-100">
                            <!-- Ride Share -->
                            <!-- <a> -->
                                <div class="row pt-2">
                                    <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-left "
                                        *ngFor="let item2 of pooltypes; let i=index">
                                        <div class="">
                                            <app-profile-user-portfolio>
                                                <a>
                                                    <div class="card mb-4 " style="text-align: -webkit-left;">
                                                        <img (error)="$event.target.src = TAXI_ICON" alt="Profile"
                                                            [src]="pooltypes[i].type_details.type_image_url ? (IMAGE_URL + pooltypes[i].type_details.type_image_url) : TAXI_ICON"
                                                            alt="vehicle" class="card-img-top object-fit-contain"
                                                            style="height: 100px;">
                                                        <div class="card-body p-3">
                                                            <h4 class="text-center">{{item2.type_details.typename}}
                                                            </h4>
                                                            <p class="text-muted text-small mb-2">
                                                                {{'home-page.about' | translate}}</p>
                                                            <p class="text-muted text-small mb-2">
                                                                {{'home-page.location' | translate}}</p>
                                                            <p class="mb-3">{{item2.cityname}},
                                                                {{item2.countryname}}</p>
                                                            <div class="ps-content">
                                                                <table class="table table-sm table-borderless">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td class="td-left"> <span
                                                                                    class="font-weight-medium">{{'home-page.base_price'
                                                                                    | translate}}</span> </td>
                                                                            <td class="text-right">
                                                                                <span
                                                                                    class="text-muted">{{typelist.currency}}{{math.round(item2.base_price)}}/
                                                                                    <span
                                                                                        *ngIf="item2.base_price_distance>1">{{item2.base_price_distance}}</span>
                                                                                    {{'home-page.km' | translate}}
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="td-left">
                                                                                <span
                                                                                    class="font-weight-medium">{{'home-page.distance_price'
                                                                                    | translate}}</span>
                                                                            </td>
                                                                            <td class="text-right">
                                                                                <span
                                                                                    class="text-muted">{{typelist.currency}}{{item2.price_per_unit_distance}}</span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="td-left">
                                                                                <span
                                                                                    class="font-weight-medium">{{'home-page.time_price'
                                                                                    | translate}}</span>
                                                                            </td>
                                                                            <td class="text-right">
                                                                                <span
                                                                                    class="text-muted">{{typelist.currency}}{{item2.price_for_total_time}}</span>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </app-profile-user-portfolio>
                                        </div>
                                    </div>
                                </div>
                            <!-- </a> -->
                        </tab>
                    </tabset>
                </div>
            </div>
        </div>
    </div>

    <div class="section pb-0">
        <div class="container" id="scroll_3">
            <div class="row">
                <div class="col-12 offset-0 col-lg-8 offset-lg-2 text-center">
                    <h1>{{'home-page.download_app' | translate}}</h1>
                    <p>
                        {{'home-page.download_eber_for_booking' | translate}}
                    </p>
                </div>
                <div class="col-12 offset-0 col-lg-6 offset-lg-3 newsletter-input-container">
                    <div class="text-center mb-3">
                        <img src="../../../assets/img/home page/google-play.svg" alt="PLAY STORE" class="img-store m-0">
                        <img src="../../../assets/img/home page/5a354ef694c9a9.4930814115134430626094.png"
                            alt="APPLE STORE" class="img-store">
                    </div>
                    <div class="text-center mb-3">
                        <a href="https://play.google.com/store/apps/details?id=com.elluminatiinc.taxi.user"
                            class="btn btn-primary btn-store" target="_blank">{{'home-page.play_store' |
                            translate}}
                        </a>
                        <a href="https://apps.apple.com/in/app/eber-client/id1184832545"
                            class="btn btn-primary btn-store" target="_blank">{{'home-page.apple_store' |
                            translate}}
                        </a>
                    </div>
                    <div class="text-center mb-3 d-flex flex-wrap align-items-center justify-content-center">
                        <a href="tel:{{admin_detail.admin_phone}}" class="p-2" style="margin-left: 6px;"><i class="demo-icon iconsminds-smartphone-3"></i>
                            {{admin_detail.admin_phone}}
                        </a>/
                        <a href="mailto:{{admin_detail.contactUsEmail}}" class="p-2"><i class="demo-icon iconsminds-email"></i>
                            {{admin_detail.contactUsEmail}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="container copyright pt-5">
            <div class="row">
                <div class="col-12"></div>
                <div class="col-12 text-center">
                    <p class="mb-0 rtl-d-none">{{current_year}}{{'home-page.eber_elluminati' | translate}}</p>
                    <p class="mb-0 rtl-d-block d-none">© {{current_year}}{{'home-page.rtl_eber_elluminati' | translate}}</p>
                </div>
            </div>
        </div>
    </div>

</div>
</div>
<div class="scroll-to-top" *ngIf="showBtn$ | async">
    <button (click)="scrollTo('scroll_1')" class="btn btn-outline-primary scrollup-icon-btn">
        <i class="fa simple-icon-arrow-up"></i>
    </button>
</div>
</div>
