import {Component,OnInit,Renderer2,OnDestroy,HostListener,ElementRef, ViewChild} from '@angular/core';
import { environment } from 'src/environments/environment';
import { carouselData, ICarouselItem } from 'src/app/data/carousels';
import { VehicleService } from 'src/app/services/vehicle.service';
import { LocationService } from 'src/app/services/location.service';
import { Helper } from 'src/app/shared/helper';
import { CommonService } from 'src/app/services/common.service';
import { DEFAULT_IMAGE } from 'src/app/constants/constants';
import { AuthModalComponent } from 'src/app/containers/pages/auth-modal/auth-modal.component';
import { AuthService } from 'src/app/services/auth.service';
import { LangService, Language } from 'src/app/shared/lang.service';
import { debounceTime, map } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
export class admin_detail {
  admin_phone: number;
  contactUsEmail: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit, OnDestroy {

  math = Math;
  typelist:any;
  citytypes:any;
  pooltypes:any;
  user_reviews:any;
  IMAGE_URL = environment.IMAGE_URL;
  DEFAULT_USER_PROFILE = DEFAULT_IMAGE.USER_PROFILE;
  TAXI_ICON = DEFAULT_IMAGE.TAXI_ICON;
  current_year:number;
  @ViewChild('authModal', { static: true }) authModal: AuthModalComponent;
  DRIVER_WEB= environment.DRIVER_WEB;
  darkTheme = localStorage.getItem('vien-themecolor')
  logoClr:boolean=false;
  languages: Language[];
  isSingleLang;
  current_language = localStorage.getItem("theme_lang");
  admin_detail:admin_detail = new admin_detail();

  constructor(private renderer: Renderer2, private elRef: ElementRef, public helper: Helper, public _locationService: LocationService,public _vehicleService: VehicleService,private _commonService: CommonService,public _authService: AuthService,private langService: LangService) {
    this.languages = this.langService.supportedLanguages;
    this.isSingleLang = this.langService.isSingleLang;
  }
    carouselItems: ICarouselItem[] = carouselData;
  showMobileMenu = false;

  buyUrl = environment.buyUrl;
  adminRoot = environment.adminRoot;

  slideSettings = {
    type: 'carousel',
    gap: 30,
    perView: 4,
    hideNav: true,
    peek: { before: 10, after: 10 },
    breakpoints: {
      600: { perView: 1 },
      992: { perView: 2 },
      1200: { perView: 3 },
    },
  };

  slideItems = [
    {
      icon: 'iconsminds-mouse-3',
      title: 'Right Click Menu',
      detail:
        'Increases overall usability of the project by providing additional actions menu.',
    },
    {
      icon: 'iconsminds-electric-guitar',
      title: 'Video Player',
      detail:
        'Carefully themed multimedia players powered by Video.js library with Youtube support.',
    },
    {
      icon: 'iconsminds-keyboard',
      title: 'Keyboard Shortcuts',
      detail:
        'Easily configurable keyboard shortcuts plugin that highly improves user experience.',
    },
    {
      icon: 'iconsminds-three-arrow-fork ',
      title: 'Two Panels Menu',
      detail:
        'Three states two panels icon menu that looks good, auto resizes and does the job well.',
    },
    {
      icon: 'iconsminds-deer',
      title: 'Icons Mind',
      detail:
        '1040 icons in 53 different categories, designed pixel perfect and ready for your project.',
    },
    {
      icon: 'iconsminds-palette',
      title: '20 Color Schemes',
      detail:
        'Colors, icons and design harmony that creates excellent themes to cover entire project.',
    },
    {
      icon: 'iconsminds-air-balloon-1',
      title: '3 Applications',
      detail:
        'Applications that mostly made of components are the way to get started to create something similar.',
    },
    {
      icon: 'iconsminds-resize',
      title: 'Extra Responsive',
      detail:
        'Custom Bootstrap 4 xxs & xxl classes delivers better experiences for smaller and larger screens.',
    },
  ];

  features_old = [
    {
      title: 'Pleasant Design',
      img: '/assets/img/landing-page/features/pleasant-design.png',
      detail:
        'As a web developer we enjoy to work on something looks nice. It is not an absolute necessity but it really motivates us that final product will look good for user point of view. <br /><br />So we put a lot of work into colors, icons, composition and design harmony. Themed components and layouts with same design language. <br /><br />We kept user experience principles always at the heart of the design process.',
    },
    {
      title: 'Extra Responsive',
      img: '/assets/img/landing-page/features/extra-responsive.png',
      detail:
        'Xxs breakpoint is for smaller screens that has a resolution lower than 420px. Xs works between 576px and 420px. Xxl breakpoint is for larger screens that has a resolution higher than 1440px. Xl works between 1200px and 1440px.<br><br>With this approach we were able to create better experiences for smaller and larger screens.',
    },
    {
      title: 'Superfine Charts',
      img: '/assets/img/landing-page/features/superfine-charts.png',
      detail:
        'Using charts is a good way to visualize data but they often look ugly and break the rhythm of design. <br /><br />We concentrated on a single chart library and tried to create charts that look good with color, opacity, border and shadow. <br /><br />Used certain plugins and created some to make charts even more useful and beautiful.',
    },
    {
      title: 'Layouts for the Job',
      img: '/assets/img/landing-page/features/layouts-for-the-job.png',
      detail:
        'Layouts are the real thing, they need to be accurate and right for the job. They should be functional for both user and developer. <br /><br />We created lots of different layouts for different jobs.<br /><br />Listing pages with view mode changing capabilities, shift select and select all functionality, application layouts with an additional menu, authentication and error layouts which has a different design than the other pages were our main focus. We also created details page with tabs that can hold many components.',
    },
    {
      title: 'Smart Menu',
      img: '/assets/img/landing-page/features/smart-menu.png',
      detail:
        'Instead of good old single panel menus with accordion structure that looks over complicated, we created 2 panels and categorized pages accordingly.<br><br>The default menu auto hides sub panel when resolution is under some breakpoint to open some space. You may also hide menu completely or use only main panel open only.',
    },
  ];

  features = [
    {
      title: 'RENTALS',
      img: '../../../assets/img/home page/rentals.png',
      detail:
        'With Eber’s Rental services, you get a taxi at your disposal. You can rent a taxi for a short period of time in the day. Whether you want to attend a meeting, a trip to the outskirts of the city, or just want to visit popular places within the town, our taxi rental facility has covered all. All you need to do is enter the necessary details such as contact information, how many hours you need a taxi, pay with a convenient method, and you are done.',
    },
    {
      title: 'TAXI HAILING',
      img: '../../../assets/img/home page/taxi hail.jpeg',
      detail:
        'Far from the city location? Don’t worry. Book a taxi with Eber wherever you are and know in advance what the exact price of your ride will be. Enter contact details along with pick up- drop addresses and get the taxi in no time at your preferred location.<br /><br />Avoid any nasty surprises with Eber. We will show you accurate prices before you choose your ride. Our prices are calculated on the basis of kilometers and are comparatively lower than others. What’s more? Book or schedule a ride, share and rate your ride at your fingertips 24/7',
    },
    {
      title: 'RIDE SHARE',
      img: '../../../assets/img/home page/ridesharing.jpg',
      detail:
        'Now share a ride with your friends or colleagues. Simply enter the details with whom you are about to share the ride. Eber helps commuters to start sharing the ride instead of traveling alone.<br><br>Connect, match the routes, coordinate and share total cost in a seamless manner within the app. Eber automates the end-to-end process of car sharing and makes the entire journey comfortable and affordable for the passengers.',
    },
	{
      title: 'SPLIT PAYMENT',
      img: '../../../assets/img/home page/split payment.jpg',
      detail:
        'Split the fare with other passengers and enjoy the ride together. Yes, you can split the fare with co-passengers that have an account on the platform. Split the taxi bill between all the passengers and pay only your fare from your respective account.<br><br>Eber allows the users to split the total bill within the app. This feature is highly suitable for those who share the same route daily. It makes the ride affordable and convenient by splitting the bill with other passengers equally.',
    },
	{
      title: 'DRIVER GOING TO HOME',
      img: '../../../assets/img/home page/driver going home.jpg',
      detail:
        'Whenever the driver is about to finish his working hours, he can add the status in the app. The rides that start from the nearby location and have a destination on his route to home or nearby will be assigned to the driver. In this ride, the driver can accept or reject the ride.<br><br>This way driver gets to earn from one more ride and earn a little extra. Moreover, this will save the driver time to conduct an extra ride.  All of this makes ride-hailing more convenient and efficient for riders and drivers.',
    },
	{
      title: 'MULTIPLE STOP',
      img: '../../../assets/img/home page/add multi stop.png',
      detail:
        'Riders, while booking the ride, can add multiple stops during the ride. For all these stops, they will have to pay extra fees depending on the stops and the time it holds at each location.<br><br>Drivers will stop the ride at each location and will start the timer to record the duration of the stop. It makes the ride convenient for the riders, as they can fulfill several purposes in a single ride.',
    },
  ];


  layouts_old = [
    { title: 'Menu Default', img: '/assets/img/landing-page/layouts/menu-default.jpg' },
    { title: 'Menu Subhidden', img: '/assets/img/landing-page/layouts/menu-subhidden.jpg' },
    { title: 'Menu Hidden', img: '/assets/img/landing-page/layouts/menu-hidden.jpg' },
    { title: 'Image List', img: '/assets/img/landing-page/layouts/image-list.jpg' },
    { title: 'Thumb List', img: '/assets/img/landing-page/layouts/thumb-list.jpg' },
    { title: 'Data List', img: '/assets/img/landing-page/layouts/data-list.jpg' },
    { title: 'Details', img: '/assets/img/landing-page/layouts/details.jpg' },
    { title: 'Authentication', img: '/assets/img/landing-page/layouts/authentication.jpg' },
    { title: 'Search Results', img: '/assets/img/landing-page/layouts/search-result.jpg' },
    {
      title: 'Single Page Application',
      img: '/assets/img/landing-page/layouts/spa.jpg',
    },
    {
      title: 'Data List App Menu Hidden',
      img: '/assets/img/landing-page/layouts/data-list-app-menu-hidden.jpg',
    },
    { title: 'Tabs', img: '/assets/img/landing-page/layouts/tabs.jpg' },
  ];

 layouts = [
    { title: 'Extensive Range of Cabs', img: '../../../assets/img/home page/Extensive Range of Cabs.png' },
    { title: 'Secure and Swift Ride', img: '../../../assets/img/home page/Secure and Swift Ride.png' },
    { title: 'Licensed Drivers', img: '../../../assets/img/home page/Licensed Drivers.png' },
    { title: 'Cashless Rides', img: '../../../assets/img/home page/Cashless Rides.png' },
    { title: 'Airport Transfer', img: '../../../assets/img/home page/Airport Transfer.png' },
    { title: 'Flexible Booking', img: '../../../assets/img/home page/Flexible Booking.png' },

  ];

  applications_old = [
    {
      title: 'All',
      path: `${this.adminRoot}/#survey`,
      img: '/assets/img/landing-page/applications/survey.jpg',
    },
    {
      title: 'Chat',
      path: `${this.adminRoot}/#chat`,
      img: '/assets/img/landing-page/applications/chat.jpg',
    },
    {
      title: 'Blac',
      path: `${this.adminRoot}/#todo`,
      img: '/assets/img/landing-page/applications/todo.jpg',
    },
  ];

   applications = [
    {
      title: 'All',
      path: `${this.adminRoot}/#survey`,
      img: '/assets/img/landing-page/taxi/survey.jpg',
	  layouts : [
			{ title: 'Black', img: '/assets/img/landing-page/taxi/Black.jpg' , details:"" , base_rate : 2 , base_rate_km : 1 , per_mile_km : 10 , per_min : 1 , with : [ "Wi-fi", "TV" , "Water"] },
			{ title: 'BlackSUV', img: '/assets/img/landing-page/taxi/BlackSUV.jpg' , details:"" , base_rate : 2 , base_rate_km : 1 , per_mile_km : 10 , per_min : 1 , with : [ "Wi-fi", "TV" , "Water"] },
			{ title: 'Comfort', img: '/assets/img/landing-page/taxi/Comfort.jpg' , details:"" , base_rate : 2 , base_rate_km : 1 , per_mile_km : 10 , per_min : 1 , with : [ "Wi-fi", "TV" , "Water"] },
			{ title: 'Flash', img: '/assets/img/landing-page/taxi/Flash.jpg' , details:"" , base_rate : 2 , base_rate_km : 1 , per_mile_km : 10 , per_min : 1 , with : [ "Wi-fi", "TV" , "Water"] },
			{ title: 'jump-no-logo', img: '/assets/img/landing-page/taxi/jump-no-logo.jpg' , details:"" , base_rate : 2 , base_rate_km : 1 , per_mile_km : 10 , per_min : 1 , with : [ "Wi-fi", "TV" , "Water"] },
			{ title: 'Lux', img: '/assets/img/landing-page/taxi/Lux.jpg' , details:"" , base_rate : 2 , base_rate_km : 1 , per_mile_km : 10 , per_min : 1 , with : [ "Wi-fi", "TV" , "Water"] },
			{ title: 'Pool', img: '/assets/img/landing-page/taxi/Pool.jpg' , details:"" , base_rate : 2 , base_rate_km : 1 , per_mile_km : 10 , per_min : 1 , with : [ "Wi-fi", "TV" , "Water"] },
			{ title: 'Taxi', img: '/assets/img/landing-page/taxi/Taxi.jpg' , details:"" , base_rate : 2 , base_rate_km : 1 , per_mile_km : 10 , per_min : 1 , with : [ "Wi-fi", "TV" , "Water"] },
			{ title: 'TukTuk', img: '/assets/img/landing-page/taxi/TukTuk.jpg' , details:"" , base_rate : 2 , base_rate_km : 1 , per_mile_km : 10 , per_min : 1 , with : [ "Wi-fi", "TV" , "Water"] },
			{ title: 'EberX', img: '/assets/img/landing-page/taxi/UberX.jpg' , details:"" , base_rate : 2 , base_rate_km : 1 , per_mile_km : 10 , per_min : 1 , with : [ "Wi-fi", "TV" , "Water"] },
			{ title: 'WAV', img: '/assets/img/landing-page/taxi/WAV.jpg' , details:"" , base_rate : 2 , base_rate_km : 1 , per_mile_km : 10 , per_min : 1 , with : [ "Wi-fi", "TV" , "Water"] },
			{ title: 'XL', img: '/assets/img/landing-page/taxi/XL.jpg' , details:" Affordable rides for groups up to 6" , base_rate : 2 , base_rate_km : 1 , per_mile_km : 10 , per_min : 1 , with : [ "Wi-fi", "TV" , "Water"] },
			{ title: 'uber-green', img: '/assets/img/landing-page/taxi/uber-green.jpg' , details:"" , base_rate : 2 , base_rate_km : 1 , per_mile_km : 10 , per_min : 1 , with : [ "Wi-fi", "TV" , "Water"] },
			{ title: 'generic-scooter', img: '/assets/img/landing-page/taxi/generic-scooter.jpg' , details:"" , base_rate : 2 , base_rate_km : 1 , per_mile_km : 10 , per_min : 1 , with : [ "Wi-fi", "TV" , "Water"] },
	  ],
	  data : [
			{  title : "Title 1" , img: '/assets/img/landing-page/applications/survey.jpg' , details:"" , base_rate : 2 , base_rate_km : 1 , per_mile_km : 10 , per_min : 1 , with : [ "Wi-fi", "TV" , "Water"] },
			{  title : "Title 2" , img: '/assets/img/landing-page/applications/survey.jpg' , details:"" , base_rate : 2 , base_rate_km : 1 , per_mile_km : 10 , per_min : 1 , with : [ "Wi-fi", "TV" , "Water"] },
		]
    },
    {
      title: 'Black',
      path: `${this.adminRoot}/#chat`,
      img: '/assets/img/landing-page/applications/chat.jpg',
	  layouts : [
			{ title: 'Black', img: '/assets/img/landing-page/taxi/Black.jpg' },
			{ title: 'BlackSUV', img: '/assets/img/landing-page/taxi/BlackSUV.jpg' },
			{ title: 'Lux', img: '/assets/img/landing-page/taxi/Lux.jpg' },
	  ],
	  data : [
			{  title : "Title 1" , img: '/assets/img/landing-page/applications/survey.jpg' } ,
			{  title : "Title 2" , img: '/assets/img/landing-page/applications/survey.jpg' }
		]
    },
	{
      title: 'White',
      path: `${this.adminRoot}/#chat`,
      img: '/assets/img/landing-page/applications/chat.jpg',
	  layouts : [

			{ title: 'Comfort', img: '/assets/img/landing-page/taxi/Comfort.jpg' },
			{ title: 'EberX', img: '/assets/img/landing-page/taxi/UberX.jpg' },
			{ title: 'WAV', img: '/assets/img/landing-page/taxi/WAV.jpg' },
			{ title: 'XL', img: '/assets/img/landing-page/taxi/XL.jpg' },
			{ title: 'uber-green', img: '/assets/img/landing-page/taxi/uber-green.jpg' },
	  ],
	  data : [
			{  title : "Title 1" , img: '/assets/img/landing-page/applications/survey.jpg' } ,
			{  title : "Title 2" , img: '/assets/img/landing-page/applications/survey.jpg' }
		]
    },
	{
      title: 'Green',
      path: `${this.adminRoot}/#chat`,
      img: '/assets/img/landing-page/applications/chat.jpg',
	  layouts : [
			{ title: 'jump-no-logo', img: '/assets/img/landing-page/taxi/jump-no-logo.jpg' },
			{ title: 'generic-scooter', img: '/assets/img/landing-page/taxi/generic-scooter.jpg' },
			{ title: 'uber-green', img: '/assets/img/landing-page/taxi/uber-green.jpg' },
	  ],
	  data : [
			{  title : "Title 1" , img: '/assets/img/landing-page/applications/survey.jpg' } ,
			{  title : "Title 2" , img: '/assets/img/landing-page/applications/survey.jpg' }
		]
    },
	{
      title: 'Other',
      path: `${this.adminRoot}/#chat`,
      img: '/assets/img/landing-page/applications/chat.jpg',
	  layouts : [

			{ title: 'Flash', img: '/assets/img/landing-page/taxi/Flash.jpg' },
			{ title: 'Pool', img: '/assets/img/landing-page/taxi/Pool.jpg' },
			{ title: 'Taxi', img: '/assets/img/landing-page/taxi/Taxi.jpg' },
			{ title: 'TukTuk', img: '/assets/img/landing-page/taxi/TukTuk.jpg' },

	  ],
	  data : [
			{  title : "Title 1" , img: '/assets/img/landing-page/applications/survey.jpg' } ,
			{  title : "Title 2" , img: '/assets/img/landing-page/applications/survey.jpg' }
		]
    },
    {
      title: 'Pool',
      path: `${this.adminRoot}/#todo`,
      img: '/assets/img/landing-page/applications/todo.jpg',
	  layouts : [

			{ title: 'Comfort', img: '/assets/img/landing-page/taxi/Comfort.jpg' },
			{ title: 'Flash', img: '/assets/img/landing-page/taxi/Flash.jpg' },
			{ title: 'Pool', img: '/assets/img/landing-page/taxi/Pool.jpg' },
	  ],
	  data : [
			{  title : "Title 1" , img: '/assets/img/landing-page/applications/survey.jpg' } ,
			{  title : "Title 2" , img: '/assets/img/landing-page/applications/survey.jpg' }
		]
    },
  ];


  themes = [
    { title: 'Navy Blue', class: 'bluenavy' },
    { title: 'Olympic Blue', class: 'blueolympic' },
    { title: 'Yale Blue', class: 'blueyale' },
    { title: 'Moss Green', class: 'greenmoss' },
    { title: 'Lime Green', class: 'greenlime' },
    { title: 'Carrot Orange', class: 'carrotorange' },
    { title: 'Ruby Red', class: 'rubyred' },
    { title: 'Monster Purple', class: 'monsterpurple' },
    { title: 'Steel Grey', class: 'steelgrey' },
    { title: 'Granola Yellow', class: 'granolayellow' },
  ];

  ngOnInit(): void {
    this._locationService.set_current_location();
    this.get_vehicle_list();
    this.userReviews();
    this.renderer.addClass(document.body, 'no-footer');
    var date = new Date();
    this.current_year = date.getFullYear();
    if(this.darkTheme.slice(0,4) == 'dark' ){
      this.logoClr=true;
    }
    if(this.current_language !== "en-US"){
      this.layouts = [];
    }
    this._commonService.get_setting_detail({}).then((setting_detail) => {
      this.admin_detail=setting_detail.setting_detail;
    })
  }
  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'no-footer');
  }
  openModal(): void {
    this.authModal.show();
  }
  RegisterModal():void{
    this.authModal.showRegister();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    const homeRect = this.elRef.nativeElement
      .querySelector('.home-row')
      .getBoundingClientRect();

    if (event.target.innerWidth >= 992) {
      this.renderer.removeClass(
        this.elRef.nativeElement.querySelector('.landing-page'),
        'show-mobile-menu'
      );
    }
  }

  @HostListener('window:click', ['$event'])
  onClick(event): void {
    this.showMobileMenu = false;
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event): void {
    this.showMobileMenu = false;
  }

  scrollTo(target): void {
    var targetMoveNum:string = target.split('_')[1];
    var elem:any = document.getElementById('scroll_'+targetMoveNum);
    if(elem){
      const y:any = elem.offsetTop - 150;
      window.scrollTo({top: y, behavior: 'smooth'});
    }
  }

  get_vehicle_list() {
    this.helper.ngZone.run(() => {
      let json: any = { token: 'token', user_id: null, country: this._locationService._current_location.country_name, latitude: this._locationService._current_location.latitude, longitude: this._locationService._current_location.longitude }
      if (this.helper.user_details) {
        json['user_id'] = this.helper.user_details._id;
        json['token'] = this.helper.user_details.server_token;
      }
      json.is_show_error_toast = false;
      this._vehicleService.get_vehicles_list(json).then(res_data => {
        this.typelist = res_data;
        this.citytypes = res_data.citytypes;
        this.pooltypes = res_data.pooltypes;
      })
    });

  }

  userReviews(){
    var json={}
    this._commonService.user_reviews(json).then((res_data)=>{
      if(res_data){
        this.user_reviews = res_data.userReviews;
      }
    })
  }

  signOut() {
    let json: any = { token: this.helper.user_details.server_token, user_id: this.helper.user_details._id };
    this._authService.user_logout(json);
  }

  onLanguageChange(lang): void {
    this.langService.language = lang.code;
    // window.location.reload();
  }
  showBtn$ = fromEvent(document, 'scroll').pipe(
    debounceTime(50),
    map(() => window.scrollY > 200)
  );
}