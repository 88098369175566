import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DEFAULT_IMAGE } from 'src/app/constants/constants';
import { LocationModel } from 'src/app/services/location.service';
import { Helper } from 'src/app/shared/helper';
import { environment } from 'src/environments/environment';
import * as $ from "jquery";

declare var google: any

@Component({
  selector: 'app-register-address-model',
  templateUrl: './register-address-model.component.html',
  styleUrls: ['./register-address-model.component.scss']
})
export class RegisterAddressModelComponent implements OnInit {

  addressCloseModal: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-xl modal-dialog-centered',
    // keyboard: false
  };

  @ViewChild('addressModal', { static: true }) addressModal: TemplateRef<any>;

  @Output() saveAddress : EventEmitter<any> = new EventEmitter();
  // Address: LocationModel = new LocationModel();
  map_addresses:any=[];
  address : any;
  map: any = null;
  lat : number = 22.3039;
  lng : number = 70.8022;
  marker:any;
  type: string = '';
  constructor(private modalService: BsModalService, private helper: Helper) { }

  ngOnInit() {
  }
  openModal(addressModal: TemplateRef<any>): void {
    this.addressCloseModal = this.modalService.show(addressModal, this.config)
  }

  @HostListener('document:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    if (event.key === 'Escape' || event.code === 'Escape') {
      if(this.addressCloseModal){
        this.addressCloseModal.onHidden.subscribe(() => {
          setTimeout(() => {
            this.address = '';
            this.lat = 22.3039;
            this.lng = 70.8022;
          }, 500);
        })
      }
    }
  }

  closeModal(){
    this.addressCloseModal.hide();
    setTimeout(() => {
      this.address = '';
      this.lat = 22.3039;
      this.lng = 70.8022;
    }, 500);
  }

  show(address): void {
    if (address.latitude) {
      this.address = address.address
      this.lat = address.latitude
      this.lng = address.longitude
    }
    if (address.type) {
      this.type = address.type
    }
    this.addressCloseModal = this.modalService.show(this.addressModal, this.config)
    this.helper.loadGoogleScript("https://maps.googleapis.com/maps/api/js?key=" + environment.GOOGLE_KEY + "&libraries=places").then(() => {
      this.set_map(address.map_pin)
      this._inithomeAutoComplete();
    })
    
  }
 
  _inithomeAutoComplete() {
    var autoEletment = document.getElementById("_address") as HTMLInputElement;
    let autocomplete = new google.maps.places.Autocomplete((autoEletment), { types: [] });
    autocomplete.addListener('place_changed', () => {
      var place = autocomplete.getPlace();

      var lat = place.geometry.location.lat();
      var lng = place.geometry.location.lng();
      var address = place['formatted_address'];

      this.lat = lat;
      this.lng = lng;
      this.address = address;
      this.marker.setPosition(place.geometry.location);
      this.map.setCenter(place.geometry.location);
      // place['address_components'].forEach(element => {
      //   var type = element.types[0]
      //   switch (type) {
      //     case 'country':
      //       this.Address.country_name = element.long_name;
      //       this.Address.country_code = element.short_name;
      //       break;
      //     case 'administrative_area_level_1':
      //       this.Address.state_code = element.short_name;
      //       this.Address.state_name = element.long_name;
      //       break;
      //     case 'administrative_area_level_2':
      //       this.Address.city_name = element.short_name;
      //       break;
      //     case 'postal_code':
      //       break;
      //     default:
      //       break;
      //   }
      // });
      this.map_addresses ={
        "address": this.address,
        "latitude": this.lat,
        "longitude" : this.lng,
        "type" : this.type
      }
    });
    $('.address-search-location').find("#_address").on("focus click keypress", () => {
      $('.address-search-location').find("#_address").parent(".input-wrp").css({ position: "relative" }).append($(".pac-container"));
    });
  }

  set_map(map_pin){
    this.map = new google.maps.Map(document.getElementById('map1'), {
      zoom: 12,
      center: { lat : this.lat, lng: this.lng },
      draggable: true,
      zoomControl: true,
      scrollwheel: true,
      disableDoubleClickZoom: false,
      fullscreenControl: true
    });

    let location = new google.maps.LatLng(this.lat, this.lng);
          this.marker = new google.maps.Marker({
            position: location,
            map: this.map,
            icon: map_pin,
            draggable : true
          });

          google.maps.event.addListener(this.marker, 'dragend', async (marker) => {
            this.lat = marker.latLng.lat();
            this.lng = marker.latLng.lng();
            var geocoder = new google.maps.Geocoder();
            let location =new google.maps.LatLng(this.lat, this.lng)
            let request = { latLng:  location};
            this.marker.setPosition(location);
            this.map.setCenter(location);
            geocoder.geocode(request, (results, status) => {
              if (status == google.maps.GeocoderStatus.OK) {
                this.address = results[0].formatted_address;
                this.map_addresses ={
                  "address": this.address,
                  "latitude": this.lat,
                  "longitude" : this.lng,
                  "type" : this.type
                }
              }
            });
          })
  }
  


  mapAddress() {
      this.saveAddress.emit(this.map_addresses);
      this.addressCloseModal.hide()
  }


}
