import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { apiColletions } from "../constants/api_collection";
import { UserModel } from "../models/user.model";
import { Helper } from "../shared/helper";
import { ApiService } from "./api.service";
import { NotifiyService } from "./notifier.service";


@Injectable({ providedIn: 'root' })
export class AuthService {

    public logginUser: UserModel;
    public user_details = JSON.parse(localStorage.getItem('userData'));
    private loginSubject = new BehaviorSubject<UserModel>(this.user_details);
    loginObservable: Observable<UserModel> = this.loginSubject.asObservable();

    private loginStore = new BehaviorSubject<any>(null);
    loginSession = this.loginStore.asObservable();


    get isAuthenticated(): boolean {
        return (!this.logginUser || this.logginUser === null) ? false : true;
    }

    get user_detail() {
        return this.logginUser ? this.logginUser : new UserModel();
    }

    constructor(private _api: ApiService, private helper: Helper,private _notificationservice:NotifiyService) { }

    // Login

    user_login(parameters): Promise<boolean> {

        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.login, parameters }).then((response) => {
                    if (response.success) {
                        this.logginUser = response.data.user_detail;

                        var localStorageData = {
                            _id: this.logginUser.user_id,
                            corporate_id: response.data.user_detail.corporate_detail ? response.data.user_detail.corporate_detail._id : null,
                            server_token: this.logginUser.token,
                            country: this.logginUser.country,
                            country_phone_code: this.logginUser.country_phone_code,
                            first_name: this.logginUser.first_name,
                            last_name: this.logginUser.last_name,
                            email: this.logginUser.email,
                            phone: this.logginUser.phone,
                            login_by: this.logginUser.login_by,
                            referral_code: this.logginUser.referral_code,
                            wallet_currency_code: this.logginUser.wallet_currency_code,
                            picture: this.logginUser.picture
                        }
                        this.helper.user_details = localStorageData;
                        localStorage.setItem('userData', JSON.stringify(localStorageData))

                        this.loginSubject.next(this.logginUser);

                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
            } catch (err) {
                resolve(true);
            }
        })
    }

    user_logout(parameters): Promise<any> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.logout, parameters }).then(response => {
                    if (response.success) {
                        if(parameters.is_admin_decline){
                            this._notificationservice.showNotification('error', this.helper.trans.instant('error-code.4010'));
                        }
                        this.logginUser = null;
                        this.loginSubject.next(this.logginUser);
                        localStorage.removeItem('userData');
                        this.helper.isUpadtedlocalStorage();
                        this.helper._route.navigate(['/']);
                        resolve(true)
                    } else {
                        resolve(null)
                    }
                })
            } catch (err) {
                resolve(null)
            }
        })
    }

    user_social_login(parameters): Promise<boolean> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.user_social_login_web, parameters }).then((response) => {
                    if (response.success) {
                        this.logginUser = response.data.user_detail;
                        this.logginUser.user_id = response.data.user_detail._id
                        var localStorageData = {
                            _id: this.logginUser.user_id,
                            corporate_id: response.data.user_detail.corporate_detail ? response.data.user_detail.corporate_detail._id : null,
                            server_token: this.logginUser.token,
                            country: this.logginUser.country,
                            country_phone_code: this.logginUser.country_phone_code,
                            first_name: this.logginUser.first_name,
                            last_name: this.logginUser.last_name,
                            email: this.logginUser.email,
                            phone: this.logginUser.phone,
                            login_by: this.logginUser.login_by,
                            referral_code: this.logginUser.referral_code,
                            wallet_currency_code: this.logginUser.wallet_currency_code,
                            picture: this.logginUser.picture
                        }
                        this.helper.user_details = localStorageData;
                        localStorage.setItem('userData', JSON.stringify(localStorageData))

                        this.loginSubject.next(this.logginUser);

                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
            } catch (err) {
                resolve(true);
            }
        })
    }



    user_forgot_password_Email(parameters): Promise<boolean> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.forgot_password, parameters }).then((response) => {
                    if (response.success) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
            } catch (err) {
                resolve(false);
            }
        })
    }
    
    forgotPasswordPhone(parameters): Promise<any> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.get_otp, parameters }).then((response) => {
                    if (response.success) {
                        resolve(response.data);
                    } else {
                        resolve(false);
                    }
                })
            } catch (err) {
                resolve(false);
            }
        })
    }
    userupdatePassword(parameters): Promise<any> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.update_password, parameters }).then((response) => {
                    if (response.success) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
            } catch (err) {
                resolve(false);
            }
        })
    }

    // Register

    user_register(userForm): Promise<any> {

        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.register, parameters: userForm }).then((response) => {

                    if (response.success) {

                        this.logginUser = response.data.user_detail;
                        this.loginSubject.next(this.logginUser);
                        var localStorageData = {
                            _id: this.logginUser.user_id,
                            server_token: this.logginUser.token,
                            first_name: this.logginUser.first_name,
                            country: this.logginUser.country
                        }
                        localStorage.setItem('userData', JSON.stringify(localStorageData))
                        resolve(response);
                    } else {
                        resolve([]);
                    }
                })
            } catch (err) {
                resolve([]);
            }
        })
    }

    // Refrral Code Apply

    UserRefrral(parameters): Promise<any> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.apply_referral_code, parameters }).then((response) => {
                    if (response.success) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
            } catch (err) {
                resolve(false);
            }
        })
    }
     // OTP Verification Apply

    Verification(parameters): Promise<any> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.verification, parameters }).then((response) => {
                    if (response.success) {
                        resolve(response.data);
                    } else {
                        resolve(false);
                    }
                })
            } catch (err) {
                resolve(false);
            }
        })
    }
}
