<ng-template #template>
    <modal-container role="dialog" tabindex="-1" class="modal fade show" style="display: block;" aria-modal="true">
        <div role="document" class="modal-dialog modal-right">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left"  *ngIf="tripdetail">{{'label-title.ride-id' | translate }}:{{tripdetail.unique_id}}
                    </h4>
                    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body p-4 card-body">
                   <!-- <tabset class="card-tabs" [justified]="true"> -->
                        <!-- <tab  class="card -body"> -->
                            <div class="row" *ngIf="is_provider">
                                <div class="col-12 d-flex flex-row mb-3 pb-3 border-bottom justify-content-between align-items-center">
                                  <a href="javascript:;">
                                    <img (error)="$event.target.src = DEFAULT_IMAGE"
                                      class=" img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall"
                                      [src]="profile_image ? profile_image : DEFAULT_IMAGE" [alt]="" style="width:40px;">
                                  </a>
                                  <div class="pl-3 flex-fill">
                                    <p class="font-weight-medium mb-0">
                                      {{provider.first_name}} {{provider.last_name}}
                                    </p>
                                    <p class="text-muted mb-0 text-small">{{provider.phone}}</p>
                                  </div>
                            
                                </div>
                              </div>
                            <div
                                class="d-flex flex-row mb-3 pb-3 border-bottom justify-content-between align-items-center">
                                <div class="flex-fill">
                                    <a href="javascript:;">
                                        <p class="font-weight-medium mb-0">{{'label-title.pickup-location' | translate}}</p>
                                        <div class="d-flex align-items-center pt-3">
                                            <!-- <img src="../../../../assets/img/address/user_pin.png" alt="user_pin" class="my-img"> -->
                                            <div class="pin-svg my-img">
                                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                                  width="16px" height="16px" viewBox="0 0 68.000000 68.000000"
                                                  preserveAspectRatio="xMidYMid meet">
                                                  <metadata>
                                                  Created by potrace 1.16, written by Peter Selinger 2001-2019
                                                  </metadata>
                                                  <g transform="translate(0.000000,68.000000) scale(0.100000,-0.100000)"
                                                   stroke="none">
                                                  <path d="M203 656 c-72 -34 -137 -97 -173 -169 -26 -52 -30 -71 -30 -141 0
                                                  -52 6 -99 17 -130 25 -68 100 -148 175 -185 54 -27 71 -31 147 -31 70 0 96 5
                                                  138 24 72 34 137 97 173 169 26 53 30 70 30 147 0 77 -4 94 -30 147 -36 72
                                                  -101 135 -173 169 -74 34 -200 34 -274 0z m245 -115 c158 -80 162 -300 9 -398
                                                  -36 -23 -53 -27 -117 -27 -64 0 -81 4 -117 27 -119 76 -149 224 -66 335 65 87
                                                  190 114 291 63z"/>
                                                  <path d="M268 480 c-105 -56 -116 -195 -22 -267 132 -100 315 59 232 202 -45
                                                  76 -135 104 -210 65z"/>
                                                  </g>
                                                </svg>
                                              </div>
                                            <p class="text-muted mb-0 text-small" *ngIf="tripdetail">{{tripdetail.source_address}}</p>
                                        </div>
                                    </a>
                                </div>
                                <!-- <div><i class="iconsminds-map-marker-2 h3 font-weight-normal"></i></div> -->
                            </div>
                            <div
                                class="d-flex flex-row mb-3 pb-3 border-bottom justify-content-between align-items-center">
                                <div class="flex-fill">
                                    <p class="font-weight-medium mb-0">{{'label-title.destination-address' | translate }}</p>
                                    <div class="stop-addresses pt-3 pl-6px" *ngIf="destinationAddress">
                                        <div *ngFor="let data of destinationAddress" class="border-img-left">
                                            <div class="pin-svg position-absolute my-img" style="width : 24px">
                                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                                  width="16px" height="16px" viewBox="0 0 68.000000 68.000000"
                                                  preserveAspectRatio="xMidYMid meet">
                                                  <metadata>
                                                  Created by potrace 1.16, written by Peter Selinger 2001-2019
                                                  </metadata>
                                                  <g transform="translate(0.000000,68.000000) scale(0.100000,-0.100000)"
                                                   stroke="none">
                                                  <path d="M203 656 c-72 -34 -137 -97 -173 -169 -26 -52 -30 -71 -30 -141 0
                                                  -52 6 -99 17 -130 25 -68 100 -148 175 -185 54 -27 71 -31 147 -31 70 0 96 5
                                                  138 24 72 34 137 97 173 169 26 53 30 70 30 147 0 77 -4 94 -30 147 -36 72
                                                  -101 135 -173 169 -74 34 -200 34 -274 0z m245 -115 c158 -80 162 -300 9 -398
                                                  -36 -23 -53 -27 -117 -27 -64 0 -81 4 -117 27 -119 76 -149 224 -66 335 65 87
                                                  190 114 291 63z"/>
                                                  <path d="M268 480 c-105 -56 -116 -195 -22 -267 132 -100 315 59 232 202 -45
                                                  76 -135 104 -210 65z"/>
                                                  </g>
                                                </svg>
                                              </div>
                                            <p class="text-muted mb-0 text-small">{{data.address}}</p>
                                        </div>
                                    </div>
                                    <a href="javascript:;" class="d-flex" *ngIf="tripdetail && destinationAddress && destinationAddress.length>0">
                                        <!-- <img src="../../../../assets/img/address/destination_pin.png" alt="destination_pin" class="my-img"> -->
                                        <div class="pin-svg my-img" style="margin-top: -3px;">
                                            <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                                              viewBox="0 0 65.000000 65.000000" preserveAspectRatio="xMidYMid meet">
                                              <metadata>
                                                Created by potrace 1.16, written by Peter Selinger 2001-2019
                                              </metadata>
                                              <g transform="translate(0.000000,65.000000) scale(0.100000,-0.100000)" stroke="none">
                                                <path d="M16 634 c-14 -13 -16 -55 -16 -308 0 -271 1 -294 18 -309 16 -15 53
                                              -17 309 -17 268 0 291 1 306 18 15 16 17 53 17 309 0 268 -1 291 -18 306 -16
                                              15 -53 17 -310 17 -251 0 -293 -2 -306 -16z m494 -309 l0 -185 -185 0 -185 0
                                              0 185 0 185 185 0 185 0 0 -185z" />
                                                <path d="M204 437 c-2 -7 -3 -62 -2 -123 l3 -109 120 0 120 0 0 120 0 120
                                              -118 3 c-91 2 -119 0 -123 -11z" />
                                              </g>
                                            </svg>
                                          </div>
                                        <p class="text-muted mb-0 text-small">
                                          <span *ngIf="tripdetail.destination_address">
                                            {{tripdetail.destination_address}}
                                          </span>
                                          <span *ngIf="!tripdetail.destination_address">
                                            {{'label-title.add-destination-later' | translate}}
                                          </span>
                                        </p>
                                    </a>
                                    <a href="javascript:;" class="d-flex align-items-center" *ngIf="tripdetail && destinationAddress && destinationAddress.length==0">
                                        <!-- <img src="../../../../assets/img/address/user_pin.png" alt="user_pin" class="my-img"> -->
                                        <div class="pin-svg my-img">
                                            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                              width="16px" height="16px" viewBox="0 0 68.000000 68.000000"
                                              preserveAspectRatio="xMidYMid meet">
                                              <metadata>
                                              Created by potrace 1.16, written by Peter Selinger 2001-2019
                                              </metadata>
                                              <g transform="translate(0.000000,68.000000) scale(0.100000,-0.100000)"
                                               stroke="none">
                                              <path d="M203 656 c-72 -34 -137 -97 -173 -169 -26 -52 -30 -71 -30 -141 0
                                              -52 6 -99 17 -130 25 -68 100 -148 175 -185 54 -27 71 -31 147 -31 70 0 96 5
                                              138 24 72 34 137 97 173 169 26 53 30 70 30 147 0 77 -4 94 -30 147 -36 72
                                              -101 135 -173 169 -74 34 -200 34 -274 0z m245 -115 c158 -80 162 -300 9 -398
                                              -36 -23 -53 -27 -117 -27 -64 0 -81 4 -117 27 -119 76 -149 224 -66 335 65 87
                                              190 114 291 63z"/>
                                              <path d="M268 480 c-105 -56 -116 -195 -22 -267 132 -100 315 59 232 202 -45
                                              76 -135 104 -210 65z"/>
                                              </g>
                                            </svg>
                                          </div>
                                        <p class="text-muted mb-0 text-small">
                                          <span *ngIf="tripdetail.destination_address">
                                            {{tripdetail.destination_address}}
                                          </span>
                                          <span *ngIf="!tripdetail.destination_address">
                                            {{'label-title.add-destination-later' | translate}}
                                          </span>
                                        </p>
                                    </a>
                                </div>
                                <!-- <div><i class="iconsminds-location-2 h3 font-weight-normal"></i></div> -->
                            </div>
                            <div class="row mb-4">
                                <!-- <div class="col-4 px-1">
                                    <div class="justify-content-center card card-box">
                                        <div
                                            class=" pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                                            <div class="min-width-zero" class="white-space text-center">
                                                <a href="javascript:;" [ngSwitch]="tripdetail.trip_type" *ngIf="tripdetail">
                                                    <p class="list-item-heading mb-1 "
                                                        *ngSwitchCase="TRIP_TYPE_NORMAL">
                                                        {{'label-title.normal-service' | translate }}</p>
                                                    <p class="list-item-heading mb-1 "
                                                        *ngSwitchCase="TRIP_TYPE_HOTEL">{{'label-title.hotel-service' |
                                                        translate }}</p>
                                                    <p class="list-item-heading mb-1 "
                                                        *ngSwitchCase="TRIP_TYPE_DISPATCHER">
                                                        {{'label-title.dispatcher-service' | translate }}</p>
                                                    <p class="list-item-heading mb-1 "
                                                        *ngSwitchCase="TRIP_TYPE_SCHEDULE">
                                                        {{'label-title.schedule-service'
                                                        | translate }}</p>
                                                    <p class="list-item-heading mb-1 "
                                                        *ngSwitchCase="TRIP_TYPE_PROVIDER">
                                                        {{'label-title.provider-service'
                                                        | translate }}</p>
                                                    <p class="list-item-heading mb-1 "
                                                        *ngSwitchCase="TRIP_TYPE_CORPORATE">
                                                        {{'label-title.corporate-service'
                                                        | translate }}</p>
                                                    <p class="list-item-heading mb-1 "
                                                        *ngSwitchCase="TRIP_TYPE_AIRPORT">
                                                        {{'label-title.airport-service' |
                                                        translate }}</p>
                                                    <p class="list-item-heading mb-1 "
                                                        *ngSwitchCase="TRIP_TYPE_ZONE">{{'label-title.zone-service' |
                                                        translate }}</p>
                                                    <p class="list-item-heading mb-1 "
                                                        *ngSwitchCase="TRIP_TYPE_CITY">{{'label-title.city-service' |
                                                        translate }}</p>
                                                    <p class="list-item-heading mb-1 "
                                                        *ngSwitchCase="TRIP_TYPE_CAR_RENTAL">
                                                        {{'label-title.car-rental-service' | translate }}</p>
                                                    <p class="list-item-heading mb-1 "
                                                        *ngSwitchCase="TRIP_TYPE_GUEST_TOKEN">
                                                        {{'label-title.guest-token-service' | translate }}</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-5 px-2">
                                    <div class="justify-content-center card card-box">
                                        <div
                                            class=" pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                                            <div class="min-width-zero">
                                                <a href="javascript:;">
                                                    <p class="list-item-heading mb-1 truncate" *ngIf="tripdetail && tripdetail.fixed_price >= 0">{{tripdetail.currency
                                                    }} {{tripdetail.fixed_price.toFixed(helper.to_fixed_number)}}</p>
                                                </a>
                                                <p class="mb-2 text-muted text-small">{{'label-title.estimate-price' | translate}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-5 px-2">
                                    <div class="justify-content-center card card-box">
                                        <div
                                            class=" pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                                            <div class="min-width-zero">
                                                <a href="javascript:;">
                                                     <p class="list-item-heading mb-1 truncate"  *ngIf="tripdetail.service_type">
                                                        {{tripdetail.service_type.service_type_name}}</p>
                                                </a>
                                            <p class="mb-2 text-muted text-small">{{'label-title.service-type' |
                                                    translate }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="mt-4"><label>{{'label-title.map' | translate}}</label>
                            </div> -->
                            <div class="mt-4" id="map" style="width: 100%; min-height: 300px;"></div>
                        <!-- </tab> -->
                      
                    <!-- </tabset> -->
                </div>
                <div class="modal-footer">
                    <!-- <button id="button-split" type="button" class="btn btn-outline-primary"
                        (click)="modalRef.hide()">{{'button-title.close' | translate}}</button> -->
                    <button id="button-split" type="button" class="btn btn-primary"
                    (click)="cancletrip();modalRef.hide()">{{'button-title.cancel-trip' | translate}}</button>
                </div>
            </div>
        </div>
    </modal-container>
</ng-template>

<ng-template #confirmationTemplate>
    <div class="modal-header">
      <h4 class="modal-title pull-left" *ngIf="tripdetail">{{'label-title.trip-id' | translate }} :
        {{tripdetail.unique_id}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-0">
      <div class="p-4">
        <p>
          {{'label-title.are-you-sure-you-want-to-cancel-this-trip' | translate}}
        </p>
      </div>
    </div>
    <div class="modal-footer">
      <!-- <button type="button" (click)="cancel()" class="btn btn-primary float-right">{{'button-title.cancel' |
        translate}}</button> -->
      <button type="button" (click)="confirm()" class="btn btn-primary float-right">{{'button-title.confirm' |
        translate}}</button>
    </div>
  </ng-template>
