import { Injectable } from "@angular/core";
import { apiColletions } from "../constants/api_collection";
import { CountryModel } from "../models/country.model";
import { ApiService } from "./api.service";

export interface Lang {
  code: string,
  name: string
  string_file_path: string
}

@Injectable({ providedIn: 'root' })
export class CommonService {

  constructor(private _api: ApiService) { }

  get_country_list(): Promise<Array<CountryModel>> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.get({ url: apiColletions.get_countries, parameters: {} }).then(response => {
          if (response.success) {
            resolve(response.data.country)
          } else {
            resolve([])
          }
        })
      } catch (err) {
        resolve([])
      }
    })
  }

  get_setting_detail(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        parameters.is_show_error_toast = false;
        parameters.is_show_success_toast = false;
        this._api.post({ url: apiColletions.get_user_setting_detail, parameters }).then(response => {
          if (response.success) {
            resolve(response.data)
          } else {
            resolve(null)
          }
        })
      } catch (err) {
        resolve(null)
      }
    })
  }

  user_reviews(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.get({ url: apiColletions.userReviews, parameters:{} }).then(response => {
          if (response.success) {
            resolve(response.data)
          } else {
            resolve(null)
          }
        })
      } catch (err) {
        resolve(null)
      }
    })
  }

  UserAccountDelete(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.delete_user, parameters }).then(response => {
          if (response.success) {
            resolve(response.data)
          } else {
            resolve(null)
          }
        })
      } catch (err) {
        resolve(null)
      }
    })
  }
  UserHomeaddress(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.set_home_address, parameters }).then(response => {
          if (response.success) {
            resolve(response.data)
          } else {
            resolve(null)
          }
        })
      } catch (err) {
        resolve(null)
      }
    })
  }
  UserWorkaddress(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.set_home_address, parameters }).then(response => {
          if (response.success) {
            resolve(response.data)
          } else {
            resolve(null)
          }
        })
      } catch (err) {
        resolve(null)
      }
    })
  }
  get_address(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.get_home_address, parameters }).then(response => {
          if (response.success) {
            resolve(response.data)
          } else {
            resolve(null)
          }
        })
      } catch (err) {
        resolve(null)
      }
    })
  }
  privacyPolicy(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.get({ url: apiColletions.terms_and_condition, parameters:{} }).then(response => {
          if (response.success) {
            resolve(response.data)
          } else {
            resolve(null)
          }
        })
      } catch (err) {
        resolve(null)
      }
    })
  }
  generate_user_history_export_excel(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.generate_user_history_export_excel, parameters }).then(response => {
          if (response) {
            resolve(response.data)
          } else {
            resolve(null)
          }
        })
      } catch (err) {
        resolve(null)
      }
    })
  }

  user_accept_reject_corporate_request(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.user_accept_reject_corporate_request, parameters }).then(response => {
          if (response) {
            resolve(response.data)
          } else {
            resolve(null)
          }
        })
      } catch (err) {
        resolve(null)
      }
    })
  }

  pay_tip_payment(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.pay_tip_payment, parameters }).then(response => {
          if (response) {
            resolve(response.data)
          } else {
            resolve(null)
          }
        })
      } catch (err) {
        resolve(null)
      }
    })
  }

  getLanguageList(): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.get({ url: apiColletions.get_language_list ,parameters:[]}).then((res) => {

          if (res.success) {
            resolve(res.data)
          } else {
            rejects(null)
          }
        })
      } catch (error) {
        resolve(null);
      }
    })
  }
}


