<app-glide #carousel [settings]="{
    type: 'carousel',
    perView: 5,
    hideNav: false,
    breakpoints: {
      '2640' : { perView: 3 },
      '576' : { perView: 3},
      '420' : { perView: 3 }
    }
}">
  <div *ngFor="let item of citytypes; let i=index" class="px-1">
    <div class="icon-row-item">
      <div class="card bg-transparent shadow-none">
        <div class="card-body bg-transparent p-0 text-center">
          <a href="javascript:;" class="text-small text-center">
            <img [src]="IMAGE_URL + citytypes[i].type_details.type_image_url" alt="not" class="card-img">
            <p class="text-small text-center color-theme-1">{{item.type_details.typename}}</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</app-glide>
