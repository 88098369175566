import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { ViewsModule } from './views/views.module';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from 'src/environments/environment';
import { LayoutContainersModule } from './containers/layout/layout.containers.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
// import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
// import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { ResInterceptInterceptor } from './interceptor/res-intercept.interceptor';

@NgModule({
  imports: [
    BrowserModule,
    ViewsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    LayoutContainersModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    // SocialLoginModule,
    AngularFireModule.initializeApp(environment.firebase),
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: true,
      progressAnimation: 'increasing',
    }),
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResInterceptInterceptor,
      multi: true

    },
    // {
		// 	provide: 'SocialAuthServiceConfig',
		// 	useValue: {
		// 		autoLogin: false,
		// 		providers: [
		// 			// {
		// 			// 	id: GoogleLoginProvider.PROVIDER_ID,
		// 			// 	provider: new GoogleLoginProvider('291342805628-pjkukve8137kif2smf5ggp2dchfc7j59.apps.googleusercontent.com')
		// 			// 	// 062107977876-2ch5ulrgsv7p38qinc68v7rl73kv57fd.apps.googleusercontent.com
		// 			// },
		// 			{
		// 				id: FacebookLoginProvider.PROVIDER_ID,
		// 				provider: new FacebookLoginProvider('1861175364114058')
		// 			}
		// 		]
		// 	} as SocialAuthServiceConfig,
		// },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
