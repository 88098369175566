<ng-template #template>
  <modal-container role="dialog" tabindex="-1" class="modal fade show" style="display: block;" aria-modal="true">
    <div role="document" class="modal-dialog modal-right">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title pull-left">{{'label-title.wallet-history' | translate }}</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h4 class="border-bottom pb-3">{{'label-title.wallet' | translate}} : {{cardData.wallet_currency_code}} {{cardData.wallet}} </h4>
          <div *ngFor="let item of walletHistory" class="d-flex min-width-0 align-items-md-center border-bottom py-3">
            <p class="font-weight-medium mb-0 w-50">{{item.wallet_description}} <br>
                <span class="text-muted small">{{'label-title.current-balance' | translate}} : {{item.total_wallet_amount}}</span>
            </p>
            <p class="font-weight-bold mb-0 w-50 text-right">
                <i *ngIf="item.wallet_status === 1" class="iconsminds-turn-up-2"></i>
                <i *ngIf="item.wallet_status === 2" class="iconsminds-turn-down-2"></i>
                <span [ngClass]="item.wallet_status === 1?'text-success':'text-danger'"> {{item.added_wallet}} {{item.to_currency_code}}</span><br>
                <span class="font-weight-normal">{{item.updated_at | date:_helper.DATE_FORMAT.DD_MM_YYYY}}</span>
            </p>
          </div>
        </div>
        <!-- <div class="modal-footer"></div> -->
      </div>
    </div>
  </modal-container>
</ng-template>
