import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { apiColletions } from '../constants/api_collection';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  constructor(private _api: ApiService) { }

  get_vehicles_list(parameters): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this._api.post({ url: apiColletions.typelist_selectedcountrycity, parameters }).then(response => {
          if (response.success) {
            resolve(response.data)

          } else {
            resolve([])
          }
        })
      } catch {
        resolve([])
      }
    })
  }
  get_promocode(parameters): Promise<any> {
    return new Promise((resolve, reject) => {

      try {
        this._api.post({ url: apiColletions.apply_promo_code, parameters }).then(response => {
          if (response.success) {
            resolve(response.data)
          } else {
            resolve(response.data)
          }
        })
      } catch {
        resolve([])
      }
    })
  }

  remove_promo_code(parameters): Promise<any> {
    return new Promise((resolve, reject) => {

      try {
        this._api.post({ url: apiColletions.remove_promo_code, parameters }).then(response => {
          if (response.success) {
            resolve(response.data)
          } else {
            resolve(response.data)
          }
        })
      } catch {
        resolve([])
      }
    })
  }

  get_fare_estimate(parameters): Promise<any> {
    return new Promise((resolve, reject) => {

      try {
        this._api.post({ url: apiColletions.getfareestimate, parameters }).then(response => {
          if (response.success) {
            resolve(response.data)

          } else {
            resolve([])
          }
        })
      } catch {
        resolve([])
      }
    })
  }


  get_server_time(): Promise<any> {
    return new Promise((resolve, reject) => {

      try {
        this._api.post({ url: apiColletions.get_server_time, parameters : {}}).then(response => {
            resolve(response.data)
        })
      } catch {
        resolve([])
      }
    })
  }


  get_nearby_provider(parameters): Promise<any> {
    return new Promise((resolve, reject) => {

      try {
        this._api.post({ url: apiColletions.get_nearby_provider, parameters }).then(response => {
          if (response.success) {
            resolve(response.data)

          } else {
            resolve([])
          }
        })
      } catch {
        resolve([])
      }
    })
  }
  getnearbyprovider(parameters): Promise<any> {
    return new Promise((resolve, reject) => {

      try {
        this._api.post({ url: apiColletions.getnearbyprovider, parameters }).then(response => {
          if (response.success) {
            resolve(response.data)

          } else {
            resolve([])
          }
        })
      } catch {
        resolve([])
      }
    })
  }
}
