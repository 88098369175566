export var PDFSIZE = 100000;

export var DEFAULT_IMAGE = {
	DEFAULT_PDF_IMG: 'assets/default_images/pdf_img.png',
	USER_SQUARE: 'assets/default_images/user_square.png',
	USER_PROFILE: 'assets/default_images/profile.png',
	DOCUMENT_PROFILE:'assets/default_images/uploading.png',
	PICKUP_ICON: 'assets/default_images/map_pin/pickup.png',
	DESTINATION_ICON: 'assets/default_images/map_pin/destination.png',
	DRIVER_ICON: 'assets/default_images/map_pin/driver.png',
	STOP_ICON: 'assets/default_images/map_pin/stop_icon.svg',
	TAXI_ICON: 'assets/default_images/Taxi.jpg',
}
export var DATE_FORMAT = {
	DD_MM_YYYY_HH_MM_A: 'dd MMM yyyy hh:mm a',
	DD_MMM_YYYY_HH_MM_A: 'dd-MM-yyyy, HH:mm',
	DD_MM_YYYY: 'dd MMM yyyy',
	D_MMM_YY_H_MM_A:"d MMM yy - h:mm a",
}

export var TRIP_TYPE = {
	TRIP_TYPE_NORMAL: 0,
	TRIP_TYPE_VISITOR: 1,
	TRIP_TYPE_HOTEL: 2,
	TRIP_TYPE_DISPATCHER: 3,
	TRIP_TYPE_SCHEDULE: 5,
	TRIP_TYPE_PROVIDER: 6,
	TRIP_TYPE_CORPORATE: 7,

	TRIP_TYPE_AIRPORT: 11,
	TRIP_TYPE_ZONE: 12,
	TRIP_TYPE_CITY: 13,
	TRIP_TYPE_CAR_RENTAL: 14,
	TRIP_TYPE_GUEST_TOKEN: 15,
}

export var SPLIT_PAYMENT = {
    WAITING: 0,
    ACCEPTED: 1,
    REJECTED: 2
}

