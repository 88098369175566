import { Injectable } from '@angular/core';
import { apiColletions } from "../constants/api_collection";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: 'root'
})
export class DocumentService {

    constructor(private _api: ApiService) { }
    fetch_document(parameters): Promise<any> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.get_user_document, parameters }).then((response) => {
                    if (response.success) {
                        resolve(response.data);
                    } else {
                        resolve(false);
                    }
                })
            } catch (err) {
                resolve(null);
            }
        })
    }
    update_document(documentsForm): Promise<any> {
        return new Promise((resolve, rejects) => {
            try {                
                this._api.post({ url: apiColletions.upload_user_document, parameters:documentsForm}).then((response) => {
                    
                    if (response.success) {
                        resolve(response.data);
                        
                    } else {
                        resolve(null);
                    }
                })
            } catch (err) {
                resolve(null);
            }
        })
    }

}
