<div class="row">
  <div class="col-12">
    <!-- <h1>{{'heading-title.profile' | translate }}</h1> -->
    <app-heading></app-heading>
    <div class="top-right-button-container text-zero mb-3 d-flex">
      <div dropdown class="d-inline-block dropleft">
        <button *ngIf="true" type="button" dropdownToggle=""
          class="btn btn-outline-primary userName d-flex align-item-center" aria-haspopup="true">
          <span class="userName truncate">{{this.langService.languageShorthand | uppercase}} </span>
        </button>
        <div class="dropdown-menu dropdown-menu-right mt-3" *dropdownMenu role="menu">
          <a class="dropdown-item c-pointer" *ngFor="let item of languages"
            (click)="onLanguageChange(item)">{{item.label | titlecase}}</a>
        </div>
      </div>
      <div class="user ml-2" *ngIf="is_address_changed && !helper.user_details">
        <button (click)="onAddNewItem()" id="button-basic" type="button" class="btn userName btn-outline-primary">
          {{'button-title.login' | translate }}</button>
      </div>
      <div class="d-inline-block ml-2" dropdown>
        <button *ngIf="helper.user_details" type="button" dropdownToggle=""
          class="btn btn-outline-primary userName d-flex align-item-center" aria-haspopup="true">
          <img class="lazyload userimg" (error)="$event.target.src = DEFAULT_USER_PROFILE"
            [src]="helper.user_details.picture ? (IMAGE_URL + helper.user_details.picture) : DEFAULT_USER_PROFILE"
            [alt]="helper.user_details.first_name">
          <span class="userName truncate d-none d-xs-block">{{helper.user_details.first_name}}</span>
        </button>
        <div class="dropdown-menu dropdown-menu-right mt-3" *dropdownMenu role="menu">
          <a class="dropdown-item c-pointer" routerLink="/app/profile">{{'label-title.account' | translate}}</a>
          <a class="dropdown-item c-pointer" routerLink="/app/create-trip">{{'label-title.book-trip' | translate}}</a>
          <a class="dropdown-item c-pointer" routerLink="/app/history">{{'label-title.history' | translate}} </a>
          <a class="dropdown-item c-pointer" routerLink="/app/running-request">{{'label-title.running-request' |
            translate}}
          </a>

          <li class="divider dropdown-divider"></li>
          <a class="dropdown-item c-pointer " (click)="signOut()">{{'label-title.sign-out' | translate}}</a>
        </div>
      </div>
    </div>
    <!-- <app-breadcrumb></app-breadcrumb> -->
    <div class="separator mb-5"></div>
  </div>
</div>

<!-- <app-addcard-modal (cardDataHandler)="fetchCardList()" #addCardModal></app-addcard-modal> -->

<div class="custom-model-main " *ngIf="splitPaymentRequest" [ngClass]="{'model-open':splitPaymentRequest}">
  <div class="custom-model-inner " style="width: 340px;">
    <div class="custom-model-wrap ">
      <div class="p-3 p-xs-4">
        <div class="text-center mb-4">
          <p class="text-one font-weight-bold">{{'label-title.split_payment_request' | translate}}</p>
        </div>
        <div class="text-center">
          <!-- <div class="mb-3">
            <p class="mb-2">{{'label-title.total' | translate}}</p>
            <p class="text-large">₹ 2604.04</p>
          </div> -->
          <p class="mb-3">{{'label-title.friend_sent_request' | translate}}</p>
          <p class="font-weight-bold mb-0">{{split_payment_request.first_name}} {{split_payment_request.last_name}}</p>
          <p class="font-weight-bold">{{split_payment_request.country_phone_code}} {{split_payment_request.phone}}</p>
          <div class="d-flex justify-content-center mb-3" *ngIf="confirm == 1">
            <div class="custom-control custom-radio mr-4">
              <input type="radio" id="customRadio1" value="cash" name="customRadio" class="custom-control-input" (click)="selectMethod('cash')" [checked]="payment_mode == 1">
              <label for="customRadio1" class="custom-control-label">{{'label-title.cash' | translate}}</label>
            </div>
            <div class="custom-control custom-radio" *ngIf="cardData && cardData.card.length > 0">
              <input type="radio" id="customRadio2" value="card" name="customRadio" class="custom-control-input" (click)="selectMethod('card')" [checked]="payment_mode == 0 && go_to_payment==false">
              <label for="customRadio2" class="custom-control-label">{{'label-title.card' | translate}}</label>
            </div>
          </div>
          <div class="w-100 mb-2" *ngIf="confirm == 0">
            <button type="button" class="btn btn-primary w-50 text-one pull-right"
               aria-label="condition" (click)="accept()">
              <span aria-hidden="true">{{'button-title.accept' | translate}}</span>
            </button>
          </div>
          <div class="w-100" *ngIf="confirm == 0">
            <button type="button" class="btn btn-outline-primary w-50 text-one pull-right"
               aria-label="condition" (click)="accept_reject()">
              <span aria-hidden="true">{{'button-title.reject' | translate}}</span>
            </button>
          </div>
          <div class="w-100 mb-2" *ngIf="confirm == 1">
            <button type="button" class="btn btn-primary w-50 text-one pull-right"
               aria-label="condition" (click)="accept_reject()">
              <span aria-hidden="true">{{'button-title.confirm' | translate}}</span>
            </button>
          </div>
          <div class="w-100" *ngIf="payment_mode == 0">
            <button type="button" class="btn btn-outline-primary text-one pull-right"
               aria-label="condition" style="width: 55%;" (click)="go_to_payment=true">
              <span aria-hidden="true">{{'button-title.go_to_payment' | translate}}</span>
            </button>
          </div>
          <div class="w-100 mt-2" *ngIf="payment_mode == 0 && split_payment_request.payment_mode !== null && this.cardData.card.length == 0">
            <p class="text-danger">{{'label-title.please_add_card_first' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-overlay "></div>
</div>

<div class="custom-model-main " *ngIf="split_pay" [ngClass]="{'model-open':split_pay}">
  <div class="custom-model-inner " style="width: 340px;">
    <div class="custom-model-wrap ">
      <div class="p-3 p-xs-4">
        <div class="text-center mb-4">
          <p class="text-one font-weight-bold">{{'label-title.split_payment_request' | translate}}</p>
        </div>
        <div class="text-center">
          <p class="mb-3">{{'label-title.total' | translate}}</p>
          <p class="text-large font-weight-semibold">{{split_pay_json.total}}</p>
          <div class="w-100 mb-2">
            <button type="button"  (click)="payAgain()" class="btn btn-primary w-50 text-one pull-right"
               aria-label="condition">
              <span aria-hidden="true">Pay</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-overlay "></div>
</div>

<div class="custom-model-main " *ngIf="go_to_payment" [ngClass]="{'model-open':go_to_payment}">
  <div class="custom-model-inner " style="width: 340px;">
    <div class="custom-model-wrap ">
      <div class="p-3 p-xs-4">
        <button type="button" class="close pull-right" aria-label="Close" (click)="go_to_payment=false">
          <span aria-hidden="true">&times;</span>
        </button>
        <h3 class="mb-4 text-center">{{ 'label-title.change-payment-mode' | translate }}</h3>
          <div class="d-flex flex-row justify-content-between mb-1 pb-1">
            <div class=" custom-control custom-radio">
              <input type="radio" id="customRadio2" value="card" name="customRadio" class="custom-control-input" [checked]="payment_mode == 0">
              <label for="customRadio2" class="custom-control-label">{{'label-title.card' | translate}}</label>
            </div>
            <!-- <button type="button" class="btn btn-outline-primary btn-xs mb-2 float-right"
              (click)="openaddCardModal()">{{'button-title.add-card' |
              translate }}</button> -->
          </div>

          <div class="pl-4 mb-4 d-flex flex-column" *ngIf="cardData.card.length > 0">
            <div class="d-flex flex-row justify-content-between mb-1 pb-1"
              *ngFor="let card of cardData.card; let i = index;">
              <div class="custom-control custom-radio" >
                <input type="radio" id="card-{{i}}" value="card-{{i}}" name="cardList" class="custom-control-input"
                  [checked]="card.is_default" (click)="selectDefault(card)">
                <label for="card-{{i}}" class="custom-control-label">XXXX-XXXX-XXXX-{{card.last_four}}</label>
              </div>
            </div>
          </div>
        <div class="d-flex justify-content-center align-item-center">
          <button type="button" class="btn btn-primary  mx-2" (click)="go_to_payment=false">
            {{'button-title.submit' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-overlay "></div>
</div>

<div class="custom-model-main " *ngIf="corporateRequestModal" [ngClass]="{'model-open':corporateRequestModal}">
  <div class="custom-model-inner " style="width: 340px;">
    <div class="custom-model-wrap ">
      <div class="p-3 p-xs-4">
        <div class="text-center mb-4">
          <p class="text-one font-weight-bold">{{'label-title.corporate_request' | translate}}</p>
        </div>
        <div class="text-center">
          <p class="mb-3">{{'label-title.tie_up_with_corporate' | translate}}</p>
          <p class="font-weight-bold mb-0">{{corporate_details.name}}</p>
          <p class="font-weight-bold">{{corporate_details.phone}}</p>
          <div class="w-100 mb-2">
            <button type="button" class="btn btn-primary w-50 text-one pull-right"
               aria-label="condition" (click)="yes(true)">
              <span aria-hidden="true">{{'button-title.yes' | translate}}</span>
            </button>
          </div>
          <div class="w-100">
            <button type="button" class="btn btn-outline-primary w-50 text-one pull-right"
               aria-label="condition" (click)="yes(false)">
              <span aria-hidden="true">{{'button-title.no' | translate}}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-overlay "></div>
</div>