<div class="row">
  <div class="col-12">
    <!-- <div class="card mb-5"> -->
        <!-- added & modified 5209 & 5237 line in scss respectively  -->
      <div class="py-3 px-3">
        <aw-wizard [navBarLocation]="'left'">
          <aw-wizard-step stepTitle="{{ 'wizard.created' | translate }}" style="min-height: 120px;" awCompletedStep>
            <div class="">
                <p class="mb-2">28 Apr 2020 10:30 AM</p>
            </div>
            <!-- <div class="text-center">
              <button type="button" class="btn btn-primary" awPreviousStep disabled>{{ 'wizard.prev' | translate }}</button>{{" "}}
              <button type="button" class="btn btn-primary" awNextStep>{{ 'wizard.next' | translate }}</button>
            </div> -->
          </aw-wizard-step>
          <aw-wizard-step stepTitle="{{ 'wizard.accepted' | translate }}" style="min-height: 120px;" awCompletedStep>
            <div class="">
                <p>28 Apr 2020 10:30 AM</p>
            </div>
            <!-- <div class="text-center">
              <button type="button" class="btn btn-primary" awPreviousStep>{{ 'wizard.prev' | translate }}</button>{{" "}}
              <button type="button" class="btn btn-primary" awNextStep>{{ 'wizard.next' | translate }}</button>
            </div> -->
          </aw-wizard-step>
          <aw-wizard-step stepTitle="{{ 'wizard.arrived' | translate }}" style="min-height: 120px;" awCompletedStep>
            <div class="">
                <p>28 Apr 2020 10:30 AM</p>
            </div>
            <!-- <div class="text-center">
              <button type="button" class="btn btn-primary" awPreviousStep>{{ 'wizard.prev' | translate }}</button>{{" "}}
              <button type="button" class="btn btn-primary" awNextStep>{{ 'wizard.next' | translate }}</button>
            </div> -->
          </aw-wizard-step>
          <aw-wizard-step stepTitle="{{ 'wizard.started' | translate }}" style="min-height: 120px;" awSelectedStep>
            <div class="">
                <p>28 Apr 2020 10:30 AM</p>
            </div>
            <!-- <div class="text-center">
              <button type="button" class="btn btn-primary" awPreviousStep>{{ 'wizard.prev' | translate }}</button>{{" "}}
              <button type="button" class="btn btn-primary" awNextStep>{{ 'wizard.next' | translate }}</button>
            </div> -->
          </aw-wizard-step>
          <aw-wizard-step stepTitle="{{ 'wizard.done' | translate }}" style="height: 120px;">
            <div class="text-center">
              <h2 class="mb-2">{{ 'wizard.content-thanks' | translate }}</h2>
              <p>{{ 'wizard.registered' | translate }}</p>
            </div>
            <!-- <div class="text-center">
              <button type="button" class="btn btn-primary" awPreviousStep>{{ 'wizard.prev' | translate }}</button>{{" "}}
              <button type="button" class="btn btn-primary" awNextStep disabled>{{ 'wizard.next' | translate }}</button>
            </div> -->
          </aw-wizard-step>
        </aw-wizard>
      </div>
    <!-- </div> -->
  </div>
</div>
