<app-topnav2></app-topnav2>

<ng-container>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-hover mb-0 tableScroll">
              <thead class="thead-light">
                <tr>
                  <th scope="col">
                      {{'label-title.trip-id' | translate}}
                  </th>
                  <th scope="col">
                      {{'label-title.service' | translate}}
                  </th>
                  <th scope="col">
                      {{'label-title.estimate-price' | translate}}
                  </th>
                  <th scope="col">
                    {{'label-title.schedule_start_time' | translate}}
                  </th>
                  <th scope="col">
                      {{'label-title.payment' | translate}}
                  </th>
                  <th scope="col">
                    {{'label-title.create-time' | translate}}
                  </th>
                </tr>
              </thead>
              <tbody *ngIf="rows && rows.length>0">
                <tr
                  *ngFor="let row of rows"
                  (click)="showModal(row._id,true)">
                  <td class="text-nowrap">{{row.unique_id}}</td>
                  <td class="text-nowrap">
                    <span *ngIf="row.service_type && row.service_type.service_type_name">{{row.service_type.service_type_name}}</span>
                    <span *ngIf="!row.service_type.service_type_name">--</span>
                  </td>
                  <td class="text-nowrap">
                    <span>{{row.currency}} {{row.fixed_price.toFixed(helper.to_fixed_number)}}</span>
                    <span class="c-pointer" *ngIf="!row.fixed_price && row.fixed_price != 0">--</span>
                  </td>
                  <td class="text-nowrap">
                    <span *ngIf="row.server_start_time_for_schedule">{{row.server_start_time_for_schedule | date:helper.DATE_FORMAT.D_MMM_YY_H_MM_A }}</span>
                    <span *ngIf="!row.server_start_time_for_schedule">--</span>
                  </td>
                  <td class="text-nowrap">
                    <span class=" badge badge-pill badge-secondary w-fit-content" *ngIf="row.payment_mode == 0">
                      {{'label-title.by-card' | translate}}</span>
                    <span class="badge badge-pill badge-primary w-fit-content" *ngIf="row.payment_mode == 1">
                      {{'label-title.by-cash' |translate}}</span>
                    <span class="c-pointer"
                      *ngIf="row.payment_mode != 0 && row.payment_mode != 1">--</span>
                  </td>
                  <td class="text-nowrap">
                    <span *ngIf="row.user_create_time">{{row.user_create_time | date:helper.DATE_FORMAT.D_MMM_YY_H_MM_A}}</span>
                    <span *ngIf="!row.user_create_time">--</span>
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="(rows && rows.length==0) || !rows">
                <tr>
                  <th colspan="10" class="text-center">
                    <span>{{ 'label-title.no-record-found' | translate }}</span>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="rows && rows.length>0">
    <div class="col-12">
      <div class="border-top pt-4">
        <div class="w-100 d-flex justify-content-center">
          <pagination [totalItems]="rowCount" [itemsPerPage]="itemsPerPage" [maxSize]="9"
              (pageChanged)="table.onFooterPage($event)" [customNextTemplate]="nextTemplate"
              [customPreviousTemplate]="prevTemplate">
          </pagination>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
  <i class='simple-icon-arrow-right'></i>
</ng-template>
<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
  <i class='simple-icon-arrow-left'></i>
</ng-template>

<app-running #runninghistoryModal (historyDataHandler)="history_data()"></app-running>