import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Helper } from 'src/app/shared/helper';
import { DEFAULT_IMAGE } from 'src/app/constants/constants';
import { environment } from 'src/environments/environment';
import { LangService ,Language} from 'src/app/shared/lang.service';
@Component({
  selector: 'app-booktrip-profiles-header',
  templateUrl: './booktrip-profiles-header.component.html',
  styleUrls: ['./booktrip-profiles-header.component.scss']
})
export class BooktripProfilesHeaderComponent implements OnInit {
  IMAGE_URL = environment.IMAGE_URL;
  DEFAULT_USER_PROFILE = DEFAULT_IMAGE.USER_PROFILE;
  languages: Language[];
  isSingleLang;
  constructor(public _authService: AuthService, public helper: Helper,   private langService: LangService,) {
      this.languages = this.langService.supportedLanguages;
      this.isSingleLang = this.langService.isSingleLang;
     }

  @Output() addNewItem: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    if (this.helper.user_details) {
      this.helper.islogin = true;
    }
  }
  signOut() {
    let json: any = { token: this.helper.user_details.server_token, user_id: this.helper.user_details._id };
    this._authService.user_logout(json);
  }
  onAddNewItem(): void {
    this.addNewItem.emit(null);
  }

  onLanguageChange(lang): void {
    this.langService.language = lang.code;
    window.location.reload();
  }

}



