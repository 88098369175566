import { Component, OnInit,Input } from '@angular/core';
import topRatedItems, { ITopRatedItem } from 'src/app/data/top-rated-items';
import { VehicleService } from '../../../services/vehicle.service';
import { environment } from 'src/environments/environment';
// import Glide, { Swipe } from '@glidejs/glide/dist/glide.modular.esm'

@Component({
  selector: 'app-create-trip-carousel',
  templateUrl: './create-trip-carousel.component.html',
  styleUrls: ['./create-trip-carousel.component.scss']
})
export class CreateTripCarouselComponent implements OnInit {

  @Input() citytypes :any[] = [];
  IMAGE_URL = environment.IMAGE_URL;

  constructor(public _vehicleService:VehicleService) {
  }

  ngOnInit(): void {}

}
