<ng-template #template>
  <modal-container role="dialog" tabindex="-1" class="modal fade show" style="display: block;" aria-modal="true">
    <div role="document" class="modal-dialog modal-right">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title pull-left">{{user.first_name}} {{user.last_name}}</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="close_modal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="userForm">
            <div class="row d-flex align-items-center">
              <div class="col-sm-4 col-lg-4 col-12 py-3 text-center">
                <div class="position-absolute card-bottom-buttons">
                  <input id="fileInput" type="file" (change)="onSelectImageFile($event)" />
                  <button class="btn btn-outline-primary icon-button"
                    onclick="document.getElementById('fileInput').click();">
                    <i class="simple-icon-pencil" *ngIf="user.picture"></i>
                    <i class="simple-icon-cloud-upload" *ngIf="!user.picture"></i>
                  </button>
                </div>
                <label for="fileInput" class="c-pointer">
                  <img (error)="$event.target.src = DEFAULT_IMAGE"
                    class="border-0 rounded list-thumbnail align-self-center user-profile"
                    [src]="profile_image ? profile_image : DEFAULT_IMAGE" [alt]="user.first_name">
                </label>
                <input (change)="onSelectImageFile($event)" id="fileInput" type="file" />
              </div>
              <div class="col-12 text-center d-sm-none d-block">
                <span class="text-primary"> {{'validation-title.please-upload-image-in-formate' | translate}}</span>
              </div>
              <div class="col-sm-8 col-lg-8 col-12">
                <div class="form-group"><label for="fname">{{'label-title.first-name' | translate }}</label>
                  <input type="text" class="form-control" formControlName="first_name" required (keypress)="_helper.space_validation($event)">
                  <div class="error"
                    *ngIf="userForm.get('first_name').errors && this.userForm.get('first_name').touched">
                    <span
                      *ngIf="userForm.get('first_name').errors.required">{{'validation-title.first-name-is-required'|
                      translate}}</span>
                  </div>
                </div>
                <div class="form-group"><label for="lname">{{'label-title.last-name' | translate }}</label>
                  <input type="text" class="form-control" formControlName="last_name" required (keypress)="_helper.space_validation($event)">
                  <div class="error" *ngIf="userForm.get('last_name').errors && this.userForm.get('last_name').touched">
                    <span *ngIf="userForm.get('last_name').errors.required">{{'validation-title.last-name-is-required'
                      | translate}}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 d-none d-sm-block">
                <span class="text-primary"> {{'validation-title.please-upload-image-in-formate' | translate}}</span>
              </div>
              <div class="col-12 mt-sm-3 mt-0">
                <div class="row">
                  <div class="col-sm-12 col-12">
                    <div class="form-group"><label for="phone">{{'label-title.phone' | translate }} </label>
                      <div class="input-group">
                        <div class="input-group-prepend w-20">
                          <input type="text" formControlName="country_phone_code"
                          class="form-control text-center" disabled>
                        </div>
                        <input type="text" onDrop="return false" onPaste="return false" formControlName="phone" class="form-control" (keypress)="_helper.number_validation($event)" [minlength]="8"
                        [maxlength]="12"
                          placeholder="{{ 'label-title.phone' | translate }}">
                        <div class="error top-100"
                          *ngIf="userForm.get('phone').errors && this.userForm.get('phone').touched">
                          <span *ngIf="userForm.get('phone').errors.required">{{'validation-title.phone-is-required' |
                            translate}}</span>
                          <span
                            *ngIf="userForm.get('phone').errors.minlength">{{'validation-title.phone-minlength-is-required'
                            | translate}}</span>
                          <span
                            *ngIf="userForm.get('phone').errors.maxlength">{{'validation-title.phone-maxlength-is-required'
                            | translate}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-12">
                    <div class="form-group"><label for="email">{{'label-title.email' | translate }}</label>
                      <div class="input-group">
                        <input class="form-control" required email formControlName="email"
                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                        <div class="error top-100"
                          *ngIf="userForm.get('email').errors && this.userForm.get('email').touched">
                          <span *ngIf="userForm.get('email').errors.required">{{'validation-title.email-is-required' |
                            translate}}</span>
                          <span *ngIf="(userForm.get('email').errors.pattern || userForm.get('email').errors) && !(userForm.get('email').errors.required)">{{'validation-title.email-is-invalid' |
                            translate}}</span>
                          <span
                            *ngIf="userForm.get('email').errors.length === 0 && is_invalid_email">{{'validation-title.email-is-invalid'
                            | translate}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="password">{{'label-title.old-password' | translate }}</label>
                  <input id="pwd" minlength="6" formControlName="password" type="{{showPassword? 'text' : 'password'}}"
                    class="form-control" placeholder="******" (keypress)="_helper.nospace_validation($event)">
                  <span id="show-pwd-btn-1" *ngIf="showPassword" class="field-icon c-pointer"
                    (click)="showPassword=!showPassword">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
                      <path fill="#7f7f7f"
                        d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" />
                    </svg>
                  </span>
                  <span id="hide-pwd-btn-1" *ngIf="!showPassword" class="field-icon c-pointer"
                    (click)="showPassword=!showPassword">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
                      <path fill="#7f7f7f"
                        d="M11.885 14.988l3.104-3.098.011.11c0 1.654-1.346 3-3 3l-.115-.012zm8.048-8.032l-3.274 3.268c.212.554.341 1.149.341 1.776 0 2.757-2.243 5-5 5-.631 0-1.229-.13-1.785-.344l-2.377 2.372c1.276.588 2.671.972 4.177.972 7.733 0 11.985-8.449 11.985-8.449s-1.415-2.478-4.067-4.595zm1.431-3.536l-18.619 18.58-1.382-1.422 3.455-3.447c-3.022-2.45-4.818-5.58-4.818-5.58s4.446-7.551 12.015-7.551c1.825 0 3.456.426 4.886 1.075l3.081-3.075 1.382 1.42zm-13.751 10.922l1.519-1.515c-.077-.264-.132-.538-.132-.827 0-1.654 1.346-3 3-3 .291 0 .567.055.833.134l1.518-1.515c-.704-.382-1.496-.619-2.351-.619-2.757 0-5 2.243-5 5 0 .852.235 1.641.613 2.342z" />
                    </svg>
                  </span>
                  <div class="error" *ngIf="userForm.get('password').errors && this.userForm.get('password').touched">
                    <span *ngIf="userForm.get('password').errors.required">{{'validation-title.password-is-required' |
                      translate}}</span>
                    <span *ngIf="userForm.get('password').errors.minlength">{{'validation-title.password-is-too-short' |
                      translate}}</span>
                  </div>
                </div>
                <div class="form-group">
                  <label for="">{{'label-title.new-password' | translate}}</label>
                  <input id="confirm-pwd" (keypress)="_helper.nospace_validation($event)" minlength="6"
                    formControlName="new_password" type="{{showConfirmPassword? 'text' : 'password'}}"
                    class="form-control" placeholder="******">
                  <span id="show-pwd-btn-2" *ngIf="showConfirmPassword" class="field-icon c-pointer"
                    (click)="showConfirmPassword=!showConfirmPassword">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
                      <path fill="#7f7f7f"
                        d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" />
                    </svg>
                  </span>
                  <span id="hide-pwd-btn-2" *ngIf="!showConfirmPassword" class="field-icon c-pointer"
                    (click)="showConfirmPassword=!showConfirmPassword">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
                      <path fill="#7f7f7f"
                        d="M11.885 14.988l3.104-3.098.011.11c0 1.654-1.346 3-3 3l-.115-.012zm8.048-8.032l-3.274 3.268c.212.554.341 1.149.341 1.776 0 2.757-2.243 5-5 5-.631 0-1.229-.13-1.785-.344l-2.377 2.372c1.276.588 2.671.972 4.177.972 7.733 0 11.985-8.449 11.985-8.449s-1.415-2.478-4.067-4.595zm1.431-3.536l-18.619 18.58-1.382-1.422 3.455-3.447c-3.022-2.45-4.818-5.58-4.818-5.58s4.446-7.551 12.015-7.551c1.825 0 3.456.426 4.886 1.075l3.081-3.075 1.382 1.42zm-13.751 10.922l1.519-1.515c-.077-.264-.132-.538-.132-.827 0-1.654 1.346-3 3-3 .291 0 .567.055.833.134l1.518-1.515c-.704-.382-1.496-.619-2.351-.619-2.757 0-5 2.243-5 5 0 .852.235 1.641.613 2.342z" />
                    </svg>
                  </span>
                  <div class="error"
                    *ngIf="userForm.get('new_password').errors && userForm.get('new_password').touched && userForm.get('new_password').errors.minlength"
                    >{{'validation-title.password-is-too-short' | translate}}</div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <!-- <button type="button" class="btn btn-outline-primary" (click)="close_modal();">{{'button-title.cancel' |
            translate }}</button> -->
          <button type="submit" class="btn btn-primary float-right" (click)="updateProfile()" [disabled]="buttonDisabled" [ngClass]="{'btn-disabled':(buttonDisabled)}">{{'button-title.update' |
            translate }}</button>
        </div>
      </div>
    </div>
  </modal-container>
</ng-template>