import { Component, Injectable, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { DocumentService } from 'src/app/services/document.service';
import { NotifiyService } from 'src/app/services/notifier.service';
import { Helper } from 'src/app/shared/helper';
import { RunningComponent } from '../../../containers/pages/running/running.component';
import { HistoryService } from '../../../services/history.service';
import { SocketService } from 'src/app/services/socket.service';

@Component({
  selector: 'app-running-request',
  templateUrl: './running-request.component.html',
  styleUrls: ['./running-request.component.scss']
})
export class RunningRequestComponent implements OnInit,OnDestroy {

  is_field_mandatory_arr: any[] = [];
  @ViewChild('myTable') table: any;
  expanded: any = {};
  timeout: any;
 
rows = [];
  itemsPerPage = 9;
  columns = [
    { prop: 'unique_id', name: 'Title' },
  ]
  temp = [...this.rows];
  @ViewChild('runninghistoryModal', { static: true }) runningrequestModal: RunningComponent;
  history_info: any = [];
  unique_id: any = [];
  history_trips: any[] = [];
  runninghistoryModal: any;
  token: any;
  constructor( private _socket: SocketService,private _historyService: HistoryService, public helper: Helper, private _documentService: DocumentService,private _notifierService: NotifiyService) { }
  ngOnDestroy(): void {
    this._notifierService = null;
  }
  ngOnInit(): void {
    this.history_data();
    this.socket(this.helper.user_details._id)
  }
  socket(id: any) {
    let listner = id
    this._socket.listener(listner).subscribe((res: any) => {
      this.history_data()
    })
  }
  history_data() {
    let json:any = { user_id: this.helper.user_details._id , token:this.token, is_show_error_toast:false}
    this._historyService.get_getfuture_history(json).then(res_data => {
      this.history_info = res_data.scheduledtrip;
      this.rows = this.history_info;})
  }
  onPage(event): void {
  }

  toggleExpandRow(row): void {
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event): void {
  }

  updateFilter(event): void {
    const val = event.target.value.toLowerCase().trim();
    const count = this.columns.length;
    const keys = Object.keys(this.temp[0]);
    const temp = this.temp.filter(item => {
      for (let i = 0; i < count; i++) {
        if ((item[keys[i]] && item[keys[i]].toString().toLowerCase().indexOf(val) !== -1) || !val) {
          return true;
        }
      }
    });
    this.rows = temp;
    this.table.offset = 0;
  }

  showModal(id,boolean = false): void {
    if (!window.matchMedia("(max-width:992px)").matches || boolean){
      this.runningrequestModal.show(id);
    }   
  }
  onActivate(row:any){
    if (row.type === 'click') {
      this. showModal(row.row._id);
    }
  }


}
