import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { apiColletions } from "../constants/api_collection";
import { UserModel } from "../models/user.model";
import { ApiService } from "./api.service";
import { WalletHistory } from "../models/wallet-history.model";
import { Helper } from "../shared/helper";

@Injectable({ providedIn: 'root' })
export class ProfileService {
    
    public logginUser: UserModel;
    private loginSubject = new BehaviorSubject<UserModel>(this._helper.user_details);
    loginObservable: Observable<UserModel> = this.loginSubject.asObservable();

    constructor(private _api: ApiService,private _helper:Helper) { }

    fetch_profile(parameters): Promise<UserModel> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.get_user_detail, parameters }).then((response) => {
                    if (response.success) {
                        resolve(response.data);
                    } else {
                        resolve(null);
                    }
                })
            } catch (err) {
                resolve(null);
            }
        })
    }

    update_profile(profileForm): Promise<Boolean> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.user_update, parameters: profileForm }).then((response) => {

                    if (response.success) {
                        this.logginUser = response.data.user_detail;
                        var localStorageData = {
                            _id: this.logginUser.user_id,
                            server_token: this.logginUser.token,
                            first_name: this.logginUser.first_name,
                            last_name: this.logginUser.last_name,
                            email: this.logginUser.email,
                            phone: this.logginUser.phone,
                            picture: this.logginUser.picture,
                            country: this.logginUser.country,
                            corporate_id: null
                        }
                        if(this._helper.user_details && this._helper.user_details.corporate_id){
                            localStorageData['corporate_id'] = this._helper.user_details.corporate_id;
                        }
                        localStorage.setItem('userData', JSON.stringify(localStorageData))

                        this.loginSubject.next(this.logginUser);
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
            } catch (err) {
                resolve(false);
            }
        })
    }

    get_wallet_history(parameters): Promise<Array<WalletHistory>> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.get_wallet_history, parameters }).then((response) => {
                    if (response.success) {
                        resolve(response.data.wallet_history);
                    } else {
                        resolve([]);
                    }
                })
            } catch (err) {
                resolve([]);
            }
        })
    }

}