import { Injectable } from '@angular/core';
import { apiColletions } from '../constants/api_collection';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CreateTripService {

  constructor(private _api: ApiService) { }
  create_Trip(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.create_trip, parameters }).then((response) => {

          if (response.success) {
            resolve(response);
          } else {
            resolve(response);
          }
        })
      } catch (err) {
        resolve([]);
      }
    })
  }



  cancle_Trip(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.canceltrip, parameters }).then((response) => {

          if (response.success) {
            resolve(response);
          } else {
            resolve(response);
          }
        })
      } catch (err) {
        resolve([]);
      }
    })
  }

  userchangepaymenttype(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.userchangepaymenttype, parameters }).then((response) => {

          if (response.success) {
            resolve(response);
          } else {
            resolve(response);
          }
        })
      } catch (err) {
        resolve([]);
      }
    })
  }

  usersetdestination(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.usersetdestination, parameters }).then((response) => {

          if (response.success) {
            resolve(response);
          } else {
            resolve(response);
          }
        })
      } catch (err) {
        resolve([]);
      }
    })
  }
  
  polyline(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.getgooglemappath, parameters }).then((response) => {

          if (response.success) {
            resolve(response);
          } else {
            resolve(response);
          }
        })
      } catch (err) {
        resolve([]);
      }
    })
  }

  search_user_for_split_payment(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.search_user_for_split_payment, parameters }).then((response) => {

          if (response.success) {
            resolve(response);
          } else {
            resolve(response);
          }
        })
      } catch (err) {
        resolve([]);
      }
    })
  }

  send_split_payment_request(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.send_split_payment_request, parameters }).then((response) => {

          if (response.success) {
            resolve(response);
          } else {
            resolve(response);
          }
        })
      } catch (err) {
        resolve([]);
      }
    })
  }

  accept_or_reject_split_payment_request(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.accept_or_reject_split_payment_request, parameters }).then((response) => {

          if (response.success) {
            resolve(response);
          } else {
            resolve(response);
          }
        })
      } catch (err) {
        resolve([]);
      }
    })
  }

  remove_split_payment_request(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.remove_split_payment_request, parameters }).then((response) => {

          if (response.success) {
            resolve(response);
          } else {
            resolve(response);
          }
        })
      } catch (err) {
        resolve([]);
      }
    })
  }

  update_split_payment_payment_mode(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.update_split_payment_payment_mode, parameters }).then((response) => {

          if (response.success) {
            resolve(response);
          } else {
            resolve(response);
          }
        })
      } catch (err) {
        resolve([]);
      }
    })
  }


}
