<div class="row">
  <div class="col-12">
    <app-heading></app-heading>
    <div class="top-right-button-container text-zero mb-3  d-flex flex-sm-row">
      <div dropdown class="d-inline-block dropleft">
        <button *ngIf="true" [ngClass]="{'userName':(helper.user_details)}"
        type="button" dropdownToggle=""
        class="btn btn-outline-primary d-flex align-item-center" aria-haspopup="true">
        <span  [ngClass]="{'userName':(helper.user_details)}">{{this.langService.languageShorthand | uppercase}} </span>
      </button>
      <div class="dropdown-menu dropdown-menu-right mt-3" *dropdownMenu role="menu">
        <a class="dropdown-item c-pointer" *ngFor="let item of languages" (click)="onLanguageChange(item)">{{item.label}}</a>
      </div>
      </div>
      <div class="user ml-2" [hidden]="helper.user_details">
        <button (click)="onAddNewItem()" id="button-basic" type="button" class="btn btn-secondary">
          {{'button-title.sign-in' | translate }} </button>
      </div>
      <div dropdown class="ml-2" [hidden]="!helper.user_details">
        <button *ngIf="helper.user_details" type="button" dropdownToggle=""
          class="btn btn-outline-primary userName d-flex align-item-center" aria-haspopup="true">
          <img class="lazyload userimg" (error)="$event.target.src = DEFAULT_USER_PROFILE"
            [src]="helper.user_details.picture ? (IMAGE_URL + helper.user_details.picture) : DEFAULT_USER_PROFILE"
            [alt]="helper.user_details.first_name">
          <span class="userName truncate">{{helper.user_details.first_name}}</span>
        </button>
        <div class="dropdown-menu dropdown-menu-right mt-3" *dropdownMenu role="menu">
          <a class="dropdown-item c-pointer" routerLink="/app/profile">{{'label-title.account' | translate}}</a>
          <a class="dropdown-item c-pointer" routerLink="/app/create-trip">{{'label-title.book-trip' | translate}}</a>
          <a class="dropdown-item c-pointer" routerLink="/app/history">{{'label-title.history' | translate}}</a>
          <a class="dropdown-item c-pointer" routerLink="/app/running-request">{{'label-title.running-request' |
            translate}}
          </a>
          <li class="divider dropdown-divider"></li>
          <a class="dropdown-item c-pointer " (click)="signOut()">{{'label-title.sign-out' | translate}}</a>
        </div>
      </div>
    </div>
    <app-breadcrumb></app-breadcrumb>
    <div class="separator mb-5"></div>
  </div>
</div>