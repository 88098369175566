import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewRoutingModule } from './views.routing';
import { SharedModule } from '../shared/shared.module';
// import { AngularFireAuthModule } from '@angular/fire/auth';
// import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { ComponentsCarouselModule } from 'src/app/components/carousel/components.carousel.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeadroomModule } from '@ctrl/ngx-headroom';
import { HomeComponent } from './home/home.component';
import { WizardsContainersModule } from 'src/app/containers/wizard/wizards.containers.module';
import { PrivacyTermsComponent } from './privacy-terms/privacy-terms.component';
import { PagesContainersModule } from "../containers/pages/pages.containers.module";
@NgModule({
    declarations: [HomeComponent, PrivacyTermsComponent],
    providers: [],
    imports: [
        BsDropdownModule.forRoot(),
        CommonModule,
        ViewRoutingModule,
        SharedModule,
        ComponentsCarouselModule,
        TabsModule.forRoot(),
        BrowserAnimationsModule,
        HeadroomModule,
        WizardsContainersModule,
        PagesContainersModule
    ]
})
export class ViewsModule {}
