export var apiColletions = {

    //Common
    "get_countries": "/country_list",
    "get_user_setting_detail": "/get_user_setting_detail",
    "get_language_list":"/get_language_list",

    // Auth
    "login": "/userslogin",
    "register": "/userregister",
    "logout":"/logout",
    "user_social_login_web":'/user_social_login_web',
    "verification":"/verification",

    // vehicle
    "typelist_selectedcountrycity": "/typelist_selectedcountrycity",
    "getfareestimate": "/getfareestimate",
    "get_nearby_provider": "/get_nearby_provider",
    "getnearbyprovider":"/getnearbyprovider",
    "get_server_time" : "/get_server_time",

    // Profile
    "get_user_detail": "/getuserdetail",
    "user_update": "/userupdate",
    "forgot_password": "/forgotpassword",
    "get_otp":"/get_otp",
    "update_password":"/update_password",

    // Document Upload
    "get_user_document": "/getuserdocument",
    "upload_user_document": "/uploaduserdocument",

    // Payment
    "get_card_list": "/payments/cards",
    "select_card": "/payments/card_selection",
    "add_card": "/payments/addcard",
    "get_payment_gateway": "/get_payment_gateway",
    "get_stripe_add_card_intent": "/payments/get_stripe_add_card_intent",
    "get_stripe_payment_intent_wallet": "/payments/get_stripe_payment_intent",
    "send_paystack_required_detail": "/payments/send_paystack_required_detail",
    "add_wallet_amount": "/add_wallet_amount",
    "delete_card": "/payments/delete_card",
    "pay_stripe_intent_payment":"/pay_stripe_intent_payment",

    // Wallet History
    "get_wallet_history": "/get_wallet_history",

    //userhistory
    "userhistory": "/userhistory",
    "usertripdetail": "/usertripdetail",
    "usergettripstatus" : "/usergettripstatus",
    "get_provider_info" : "/get_provider_info",
    "user_submit_invoice" : "/user_submit_invoice",

    //apply_promo_code
    "apply_promo_code": "/apply_promo_code",
    "remove_promo_code" : "/remove_promo_code",

    //Create Trip
    "create_trip": "/createtrip",
    "canceltrip" : "/canceltrip",
    "userchangepaymenttype": "/userchangepaymenttype",
    "usersetdestination" : "/usersetdestination",
    
     //walletstatus
    "change_user_wallet_status": "/change_user_wallet_status",

    //usegiverting
    "usergiverating":"/usergiverating",

    //getfuturetrip
    "getfuturetrip":"/getfuturetrip",

    //gettrippath
    "getgooglemappath":"/getgooglemappath",

    //userReviews
    "userReviews":"/userReviews",

    //referral_code
    "apply_referral_code":"/apply_referral_code",

    //delete_user
    "delete_user":"/delete_user",

    //privacy policy && terms and condition
    "terms_and_condition":"/terms_and_condition",

    //address
    "set_home_address":"/set_home_address",
    "get_home_address":"/get_home_address",

    //split payment
    "search_user_for_split_payment":"/search_user_for_split_payment",
    "send_split_payment_request":"/send_split_payment_request",
    "accept_or_reject_split_payment_request":"/accept_or_reject_split_payment_request",
    "remove_split_payment_request":"/remove_split_payment_request",
    "update_split_payment_payment_mode":"/update_split_payment_payment_mode",

    //corporate request
    "user_accept_reject_corporate_request":"/user_accept_reject_corporate_request",

    //exel shit
    "generate_user_history_export_excel":"/generate_user_history_export_excel",

    //tip
    "pay_tip_payment":"/pay_tip_payment"
}
