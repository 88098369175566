import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../environments/environment";
import { Helper } from "../shared/helper";

export interface ResponseModel {
    success: boolean;
    code: string
    data: any;
}

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    is_loading = false;
    private API_URL = environment.API_URL;
        constructor(private _http: HttpClient, private translateService: TranslateService, public helper: Helper) { }
    post({ url, parameters }): Promise<ResponseModel> {
        this.is_loading = true;
        return new Promise((resolve, rejects) => {
            try {
                var call_url = this.getBaseUrl(url) + url;
                this._http.post(call_url, parameters).toPromise().then(responseData => {
                    setTimeout(() => {
                        if (this.is_loading) {
                            this.is_loading = false;
                        }
                    }, 500);
                    if (responseData['success']) {
                        resolve({ success: responseData['success'], code: responseData['message'], data: responseData })
                    } else if (responseData['error_code'] == 451 || responseData['error_code'] == 474) {
                        localStorage.removeItem('userData');
                        this.helper.isUpadtedlocalStorage();
                        this.helper._route.navigate(['/']).then(()=>{
                            window.location.reload();
                        })
                        resolve({ success: responseData['success'], code: responseData['error_code'], data: responseData })
                    } else {
                        resolve({ success: responseData['success'], code: responseData['error_code'], data: responseData })
                    }
                })
            } catch (err) {
                resolve({ success: false, code: "2003", data: null });
            }
        });
    }

    getBaseUrl(url){
        if(url.split("/")[1] == "payments"){
            return environment.PAYMENTS_API_URL
        }
        return environment.API_URL
    }
    get({ url, parameters }): Promise<ResponseModel> {
        return new Promise((resolve, rejects) => {
            try {
                var call_url = this.getBaseUrl(url) + url;
                this._http.get(call_url, parameters).toPromise().then(responseData => {
                    if (!responseData) {
                        resolve({ success: true, code: '', data: null })
                    } else if (responseData['success']) {
                        resolve({ success: responseData['success'], code: responseData['message'], data: responseData })
                    } else {
                        resolve({ success: responseData['success'], code: responseData['error_code'], data: null })
                    }
                })
            } catch (err) {
                resolve({ success: false, code: "2003", data: null });
            }
        });
    }
}
