import { Component, Input, OnInit, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Helper } from 'src/app/shared/helper';
import { HistoryService } from 'src/app/services/history.service';
import { LocationModel, LocationService } from 'src/app/services/location.service';
import { environment } from 'src/environments/environment';
import { DEFAULT_IMAGE } from 'src/app/constants/constants';
import { historyModelData } from 'src/app/models/history-model.model'
import { TRIP_TYPE } from 'src/app/constants/constants';
import { CommonService } from 'src/app/services/common.service';
import { PaymentService } from 'src/app/services/payment.service';
import { PaymentGateway } from 'src/app/models/payment_gateway.model';
import { Card } from 'src/app/models/card.model';
import { NotifiyService } from 'src/app/services/notifier.service';

declare var $: any;
declare var google;
declare var stripe: any;
// export class tripdetail {
//   history: Array<historyModelData> = [];
// }

export class CardData {
  is_use_wallet: number = 0;
  payment_gateway_type: number;
  wallet: number = 0;
  wallet_currency_code: string = null;
  payment_gateway: Array<PaymentGateway> = [];
  card: Array<Card> = [];
}

@Component({
  selector: 'app-history-modal',
  templateUrl: './history-modal.component.html',
  styleUrls: ['./history-modal.component.scss']
})
export class HistoryModalComponent implements OnInit, OnDestroy {


  modalRef: BsModalRef;
  userrate: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-right'
  };
  userRate_config = {
    backdrop: false,
    ignoreBackdropClick: true,
    class: 'modal-md modal-dialog-centered',
    keyboard: false
  };
  @ViewChild('template', { static: true }) template: TemplateRef<any>;
  // tripdetail: historyModelData = new historyModelData();
  tripdetail: any ;
  tripservice: historyModelData = new historyModelData();
  provider: historyModelData = new historyModelData();

  TRIP_TYPE_NORMAL = TRIP_TYPE.TRIP_TYPE_NORMAL;
  TRIP_TYPE_HOTEL = TRIP_TYPE.TRIP_TYPE_HOTEL;
  TRIP_TYPE_DISPATCHER = TRIP_TYPE.TRIP_TYPE_DISPATCHER;
  TRIP_TYPE_SCHEDULE = TRIP_TYPE.TRIP_TYPE_SCHEDULE;
  TRIP_TYPE_PROVIDER = TRIP_TYPE.TRIP_TYPE_PROVIDER;
  TRIP_TYPE_CORPORATE = TRIP_TYPE.TRIP_TYPE_CORPORATE;
  TRIP_TYPE_AIRPORT = TRIP_TYPE.TRIP_TYPE_AIRPORT;
  TRIP_TYPE_ZONE = TRIP_TYPE.TRIP_TYPE_ZONE;
  TRIP_TYPE_CITY = TRIP_TYPE.TRIP_TYPE_CITY;
  TRIP_TYPE_CAR_RENTAL = TRIP_TYPE.TRIP_TYPE_CAR_RENTAL;
  TRIP_TYPE_GUEST_TOKEN = TRIP_TYPE.TRIP_TYPE_GUEST_TOKEN;
  TRIP_TYPE =  TRIP_TYPE;

  tripAddress;
  trip_id: any;
  res_data: any;
  totaltax: any;
  total: any;
  map: any;
  pickup_marker: any;
  dest_marker: any;
  sourceLocation: any;
  source: any;
  sourcelat: number;
  sourcelet: any;
  sourcelng: any;
  destlat: number;
  destlng: number;
  destination_marker: any;
  destinationlat: any;
  destinationlng: any;
  IMAGE_URL = environment.IMAGE_URL;
  DEFAULT_IMAGE = DEFAULT_IMAGE.USER_PROFILE;
  profile_image: any = this.DEFAULT_IMAGE;
  imagefile: any;

  trip1: any;
  tripa: any;
  tripAddress1: any;
  ratingData: any;
  rating_rate: string;
  rating_comment: any;
  rate: any;
  tab: any;

  is_provider: boolean = false;
  rating_rate_value: string;
  is_tip:boolean;
  tip_amount:number;
  cardData: CardData = new CardData();
  stripe_amount_error:string = '';

  totalcharge: number;
  is_show_total:boolean = true; 
  case_number:number = 0;
  totalOtherCharge: number;
  totalTax:number;
  userpayment: number;
  total_split_payment: number;
  split_payment_users: any;

  constructor(private modalService: BsModalService, private _usertripedetail: HistoryService, public helper: Helper, private usergiverating: HistoryService,private _commonService:CommonService,private _paymentService:PaymentService,private _notifierService:NotifiyService) { }

  ngOnInit(): void {
    this.is_provider = false;
    this.profile_image = "";
    this.provider = null;
  }
  show(id: any, tab: any): void {
    this.modalRef = this.modalService.show(this.template, this.config);
    this.trip_id = id;
    this.userTripe();
    this.tab = tab;
    this.fetchUserSettingDetail();
    this.fetchCardList();
  }
  rate_us(modal: TemplateRef<any>): void {
    this.modalRef.hide();
    this.userrate = this.modalService.show(modal, this.userRate_config);
  }
  onSelectImageFile(event) {
    let files = event.target.files;
    if (files.length === 0)
      return;
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      alert("Only images are supported.")
      files = [];
      return;
    }
    this.imagefile = files[0]
    const reader = new FileReader();
    reader.readAsDataURL(this.imagefile);
    reader.onload = (_event) => {
      this.profile_image = reader.result;

    }
  }

  userTripe() {

    let json: any = { user_id: this.helper.user_details._id, token: this.helper.user_details.server_token, trip_id: this.trip_id }
    json.is_show_success_toast = false;
    this._usertripedetail.get_usertripdetail(json).then(res_data => {

      this.tripdetail = res_data.trip;      

      this.totalcharge = 0;
      this.tripdetail = res_data.trip;
      if (this.tripdetail.trip_type == this.TRIP_TYPE.TRIP_TYPE_AIRPORT) {
        this.is_show_total = false;
        this.case_number = 1;
      } else if (this.tripdetail.trip_type == this.TRIP_TYPE.TRIP_TYPE_ZONE) {
        this.is_show_total = false;
        this.case_number = 2;
      } else if (this.tripdetail.trip_type == this.TRIP_TYPE.TRIP_TYPE_CITY) {
        this.is_show_total = false;
        this.case_number = 3;
      } else if (this.tripdetail.is_fixed_fare == 1) {
        this.is_show_total = false;
        this.case_number = 4;
      } else if (this.tripdetail.is_min_fare_used == 1) {
        this.is_show_total = true;
        this.case_number = 5;
      } else {
        this.is_show_total = true;
        this.case_number = 0;
      }

      this.totalOtherCharge = this.tripdetail.tip_amount + this.tripdetail.toll_amount;
      this.totalTax = this.tripdetail.user_tax_fee + this.tripdetail.tax_fee + this.tripdetail.user_miscellaneous_fee;
      this.userpayment = this.tripdetail.wallet_payment + this.tripdetail.cash_payment + this.tripdetail.card_payment + this.tripdetail.remaining_payment;
      this.split_payment_users = res_data.trip.split_payment_users;
      let total = 0;
      this.split_payment_users.forEach((data) => {
        total += data.total;
      })
      this.total_split_payment = total;

      if (this.is_show_total == true && this.case_number == 0) {
        if (this.tripdetail.base_distance_cost > 0) {
          this.totalcharge += Number(this.tripdetail.base_distance_cost);
        }
        if (this.tripdetail.time_cost > 0) {
          this.totalcharge += Number(this.tripdetail.time_cost);
        }
        if (this.tripdetail.distance_cost > 0) {
          this.totalcharge += Number(this.tripdetail.distance_cost);
        }
        if (this.tripdetail.waiting_time_cost > 0) {
          this.totalcharge += Number(this.tripdetail.waiting_time_cost);
        }
        if (this.tripdetail.stop_waiting_time_cost > 0) {
          this.totalcharge += Number(this.tripdetail.stop_waiting_time_cost);
        }
        if (this.tripdetail.surge_fee > 0) {
          this.totalcharge += Number(this.tripdetail.surge_fee);
        }
      }
      if (this.is_show_total == false || this.case_number != 0) {
        this.totalcharge = this.tripdetail.total_after_surge_fees;
      }


      if (res_data.trip.confirmed_provider !== null && !this.is_provider) {
        let json: any = { user_id: this.helper.user_details._id, token: this.helper.user_details.server_token, trip_id: this.trip_id, provider_id: res_data.trip.confirmed_provider }
        this._usertripedetail.get_provider_info(json).then(res_data_p => {
          this.provider = res_data_p.provider;
          this.is_provider = true;
          if(this.provider){
            this.profile_image = this.IMAGE_URL + this.provider.picture;
          }
        })
      }

      this.rate = res_data.trip.is_provider_rated;

      this.tripservice = res_data.tripservice;
      // this.totalcharge = this.tripservice.base_price + this.tripservice.base_price_distance + this.tripservice.base_price_time + this.tripservice.price_for_waiting_time;
      // this.totaltax = this.tripdetail.tax_fee + this.tripdetail.user_tax_fee;
      // this.totalOtherCharge = this.tripdetail.surge_fee + this.tripdetail.user_miscellaneous_fee + this.tripdetail.tip_amount + this.tripdetail.toll_amount;
      // this.total = this.tripdetail.total + this.tripdetail.promo_payment;
      // this.userpayment = this.tripdetail.wallet_payment + this.tripdetail.cash_payment + this.tripdetail.card_payment;

      // this.totalcharge = Number(this.tripdetail.base_distance_cost)  + Number(this.tripservice.time_cost) + Number(this.tripdetail.distance_cost) + Number(this.tripdetail.waiting_time_cost) 
      // this.totalcharge = 0
      // if (this.tripdetail.trip_type == 0) {
      //   if (this.tripdetail.is_fixed_fare && this.tripdetail.fixed_price > 0) {
      //     this.totalcharge += Number(this.tripdetail.base_distance_cost)
      //   }
      // } else if(this.tripdetail.trip_type_amount > 0 ){ 
      //   this.totalcharge += Number(this.tripdetail.trip_type_amount)
      // }
      // if (this.tripdetail.car_rental_id != null || this.tripdetail.car_rental_id != "") {
      //   this.totalcharge += Number(this.tripdetail.base_distance_cost)
      // }else if (this.tripservice.base_price > 0 && !this.tripdetail.is_fixed_fare ){
      //   this.totalcharge += Number(this.tripservice.base_price)
      // }
      // if (this.tripdetail.base_distance_cost > 0) {
      //   this.totalcharge += Number(this.tripdetail.base_distance_cost)
      // }
      // if (this.tripdetail.time_cost > 0) {
      //   this.totalcharge += Number(this.tripdetail.time_cost)
      // }
      // if (this.tripdetail.distance_cost > 0) {
      //   this.totalcharge += Number(this.tripdetail.distance_cost)
      // }
      // if (this.tripservice.waiting_time_cost > 0) {
      //   this.totalcharge += Number(this.tripservice.waiting_time_cost)
      // }
      // if (Number(this.tripdetail.is_min_fare_used) == 1) {
      //   this.totalcharge = Number(this.tripservice.min_fare)
      // }
      // if (Number(this.tripdetail.fixed_price) > 0) {
      //   this.totalcharge = Number(this.tripdetail.fixed_price)
      // }
      this.sourcelat = res_data.trip.sourceLocation[0];
      this.sourcelng = res_data.trip.sourceLocation[1];
      this.destlat = res_data.trip.destinationLocation[0];
      this.destlng = res_data.trip.destinationLocation[1];
      this.tripAddress = res_data.trip.destination_addresses;
      this.helper.loadGoogleScript("https://maps.googleapis.com/maps/api/js?key=" + environment.GOOGLE_KEY + "&libraries=places,drawing").then(() => {
        this.setMap();
      })
    })


    // if (this.tripdetail.trip_type == 0) {
    //   if (this.tripdetail.is_fixed_fare && this.tripdetail.fixed_price > 0) {
    //     //fixed rate 
    //   }
    // } else if(this.tripdetail.trip_type_amount > 0 ){
    //   //fixed rate
    // }
    // if (this.tripdetail.car_rental_id != null || this.tripdetail.car_rental_id != "") {
    //   //base parice --> trip service in basepeice

    // }else if (this.tripservice.base_price > 0 && !this.tripdetail.is_fixed_fare ){
    //   //base price --> this.tripservice.base_price
    // }


  }
  setMap() {
    let lat = Number(this.sourcelat);
    let lng = Number(this.sourcelng);
    this.map = new google.maps.Map(document.getElementById('map') as HTMLInputElement, {
      zoom: 2,
      center: { lat, lng },
      draggable: true,
      zoomControl: true,
      scrollwheel: true,
      disableDoubleClickZoom: false,
      fullscreenControl: true
    });
    if (this.pickup_marker) {
      this.pickup_marker.setMap(null);
      this.pickup_marker = null;
    }
    var bounds = new google.maps.LatLngBounds();
    if (lat && lng) {
      this.pickup_marker = new google.maps.Marker({
        position: { lat, lng },
        map: this.map,
        draggable: false,
        icon : DEFAULT_IMAGE.PICKUP_ICON,
      });
      bounds.extend(this.pickup_marker.position);
    }

    if (this.tripAddress) {
      this.tripAddress.forEach((address, index) => {
        let location = new google.maps.LatLng(address.location[0], address.location[1]);
        let marker = new google.maps.Marker({
          position: location,
          map: this.map,
          icon : DEFAULT_IMAGE.STOP_ICON
        });
        bounds.extend(marker.position);
      });
    }

    if (this.dest_marker) {
      this.dest_marker.setMap(null);
      this.dest_marker = null;
    }

    let destla = Number(this.destlat);
    let destln = Number(this.destlng);
    let myLatlng = new google.maps.LatLng(this.destlat, this.destlng);
    if (destla && destln) {
      this.dest_marker = new google.maps.Marker({
        position: myLatlng,
        map: this.map,
        draggable: false,
        icon : DEFAULT_IMAGE.DESTINATION_ICON,

      });
      bounds.extend(this.dest_marker.position);
    }
    this.map.fitBounds(bounds);

  }

  submitInvoice(modal: TemplateRef<any>): void {
    let json: any = { user_id: this.helper.user_details._id, token: this.helper.user_details.server_token, trip_id: this.trip_id }
    this.usergiverating.user_submit_invoice(json).then(res_data => {
      if (res_data.success) {
        this.modalRef.hide();
        this.userrate = this.modalService.show(modal, this.userRate_config);
      }
    })
  }
  rateUs() {
    this.stripe_amount_error = '';
    if (!this.rating_rate) {
      this.rating_rate_value = '';
      return;
    }

    if(this.is_tip == true && this.tripdetail.payment_mode == 0 && this.tip_amount && this.tip_amount != 0){
      if (this.cardData.payment_gateway_type == 10) {
        let json: any = { trip_id: this.trip_id, user_id: this.helper.user_details._id, token: this.helper.user_details.server_token, amount: this.tip_amount, type: 10,is_payment_for_tip: true }
        this._paymentService.get_stripe_payment_intent_wallet(json).then((response:any) => {
          if (!response.client_secret || !response.payment_method || response.payment_method === null) {
            var stripe_amount_error = this.helper.trans.instant(response.error);
            this.stripe_amount_error = stripe_amount_error;
            return;
          }
          this.stripe_amount_error = '';
          if(response.success){
            stripe.confirmCardPayment(response.client_secret, { payment_method: response.payment_method }).then((result) => {
              if (result.paymentIntent) {
                let json1:any = {trip_id: this.trip_id,user_id: this.helper.user_details._id, token: this.helper.user_details.server_token}
                this._commonService.pay_tip_payment(json1).then(res => {
                  if(res.success){
                    let json: any = { user_id: this.helper.user_details._id, token: this.helper.user_details.server_token, trip_id: this.trip_id, rating: this.rating_rate, review: this.rating_comment }
                    this.usergiverating.rate_Us(json).then(res_data => {
                      if (res_data) {
                        this.ratingData = res_data;
                        this.rating_comment = "";
                        this.userrate.hide()
                        window.location.reload();
                      }
                    })
                  }
                })
              } else {
                this._notifierService.showNotification('error', result.error.message);
              }
            });
          }
        })
      }
    }

    if (this.rating_rate) {
      this.rating_rate_value = this.rating_rate;
    }
    if(!this.tip_amount || this.tip_amount == 0){
      let json: any = { user_id: this.helper.user_details._id, token: this.helper.user_details.server_token, trip_id: this.trip_id, rating: this.rating_rate, review: this.rating_comment }
        this.usergiverating.rate_Us(json).then(res_data => {
          if (res_data) {
            this.ratingData = res_data;
            this.rating_comment = "";
            this.userrate.hide()
            window.location.reload();
          }
        })
    }
  }

  async fetchCardList() {
    let json: any = { server_token: this.helper.user_details.server_token, user_id: this.helper.user_details._id, type: 10 };
    this.cardData = await this._paymentService.get_card_list(json);
  }

  fetchUserSettingDetail() {
    let json = {}
    this._commonService.get_setting_detail(json).then((user_setting_detail) => {
      this.is_tip = user_setting_detail.setting_detail.is_tip;
    })
  }

  getTipAmount(amount){
    this.tip_amount = amount;
  }

  changeTipAmount($event){
    this.stripe_amount_error = '';
  }

  closemodal() {
    this.modalRef.hide();
    setTimeout(() => {
      this.trip_id = "";
      this.is_provider = false;
      this.profile_image = "";
      this.provider = null;
    }, 300);
  }

  ngOnDestroy(): void {

    this.trip_id = "";
    this.is_provider = false;
    this.profile_image = "";
    this.provider = null;
  }

}






