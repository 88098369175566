import { Component} from '@angular/core';

@Component({
  selector: 'app-wizard-icons',
  templateUrl: './wizard-icons.component.html'
})
export class WizardIconsComponent  {

  constructor() { }



}
