import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HistoryService } from '../../../services/history.service'
import { LocationModel, LocationService } from 'src/app/services/location.service';
import { Helper } from 'src/app/shared/helper'
import { environment } from 'src/environments/environment';
import { historyModelData } from 'src/app/models/history-model.model'
import { DEFAULT_IMAGE } from 'src/app/constants/constants';
import { TRIP_TYPE } from 'src/app/constants/constants';
import { CreateTripService } from 'src/app/services/create-trip.service';

declare var google: any;

@Component({
  selector: 'app-running',
  templateUrl: './running.component.html',
  styleUrls: ['./running.component.scss']
})
export class RunningComponent implements OnInit {
  modalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-right'
  };
  cancelModelConfig = {
    backdrop: true,
    ignoreBackdropClick: true,
  };
  confirmModelRef: BsModalRef;
  @Output() historyDataHandler : EventEmitter<any> = new EventEmitter();
  @ViewChild('template', { static: true }) template: TemplateRef<any>;
  tripdetail: historyModelData = new historyModelData();
  provider: historyModelData = new historyModelData();
  trip_id: any;
  destinationAddress: any;
  map: any = null;
  destination_marker: any;
  pickup_marker: any;
  change_lat: any;
  address: any;
  address_error: string;
  mylocation: any;
  pickup_address: any;
  destinationlat: any;
  destinationlng: any;
  sourcelat: any;
  sourcelng: any;
  imagefile: any;
  IMAGE_URL = environment.IMAGE_URL;
  DEFAULT_IMAGE = DEFAULT_IMAGE.USER_PROFILE;
  profile_image: any = this.DEFAULT_IMAGE;
  destlat: any;
  destlng: any;
  dest_marker: any;
  ratingData: any;
  rating_rate: any;
  rating_comment: any;
  TRIP_TYPE_NORMAL = TRIP_TYPE.TRIP_TYPE_NORMAL;
  TRIP_TYPE_HOTEL = TRIP_TYPE.TRIP_TYPE_HOTEL;
  TRIP_TYPE_DISPATCHER = TRIP_TYPE.TRIP_TYPE_DISPATCHER;
  TRIP_TYPE_SCHEDULE = TRIP_TYPE.TRIP_TYPE_SCHEDULE;
  TRIP_TYPE_PROVIDER = TRIP_TYPE.TRIP_TYPE_PROVIDER;
  TRIP_TYPE_CORPORATE = TRIP_TYPE.TRIP_TYPE_CORPORATE;
  TRIP_TYPE_AIRPORT = TRIP_TYPE.TRIP_TYPE_AIRPORT;
  TRIP_TYPE_ZONE = TRIP_TYPE.TRIP_TYPE_ZONE;
  TRIP_TYPE_CITY = TRIP_TYPE.TRIP_TYPE_CITY;
  TRIP_TYPE_CAR_RENTAL = TRIP_TYPE.TRIP_TYPE_CAR_RENTAL;
  TRIP_TYPE_GUEST_TOKEN = TRIP_TYPE.TRIP_TYPE_GUEST_TOKEN;
  private _id: any;
  token: any;
  phoneNumber: any;
  is_provider: boolean;
  trip_path_array: any[] = [];
  triplocation: any;

  @ViewChild('confirmationTemplate', { static: true }) confirmationTemplate: TemplateRef<any>;

  constructor(private modalService: BsModalService, private _usertripedetail: HistoryService, public _locationService: LocationService, public helper: Helper, private _createTripService: CreateTripService) { }

  ngOnInit(): void {
  }
  show(id: any): void {
    this.modalRef = this.modalService.show(this.template, this.config);
    this.trip_id = id;
    this.userTripe();
  }

  onSelectImageFile(event) {
    let files = event.target.files;
    if (files.length === 0)
      return;
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      alert("Only images are supported.")
      files = [];
      return;
    }
    this.imagefile = files[0]
    const reader = new FileReader();
    reader.readAsDataURL(this.imagefile);
    reader.onload = (_event) => {
      this.profile_image = reader.result;

    }
  }
  userTripe() {
    let json: any = { user_id: this.helper.user_details._id, token: this.helper.user_details.ser, trip_id: this.trip_id }
    this._usertripedetail.get_getfuture_history(json).then(res_data => {
      res_data.scheduledtrip.forEach((element :any) => {
        if (element._id == this.trip_id) {
          this.tripdetail = element;
          this.phoneNumber = this.helper.user_details;
          this.profile_image = this.IMAGE_URL + this.phoneNumber.picture;
          this.sourcelat = element.sourceLocation[0];
          this.sourcelng = element.sourceLocation[1];
          this.destlat = element.destinationLocation[0];
          this.destlng = element.destinationLocation[1];
          if (element.destination_addresses) {
            this.destinationAddress = element.destination_addresses;
          }

          if(element.is_provider_accepted == 1 && element.is_provider_status >= 6){
            var json: any = { trip_id: this.tripdetail._id }
            this._createTripService.polyline(json).then(response => {
              this.triplocation = response.data.triplocation;
              this.trip_path_array = [];
              this.triplocation.startTripToEndTripLocations.forEach(location => {
                let lat = location[0];
                let lng = location[1];
                let trip_path = { 'lat': lat, 'lng': lng };
                this.trip_path_array.push(trip_path);
              });
              var trippath = new google.maps.Polyline({
                path: this.trip_path_array,
                geodesic: true,
                strokeColor: '#FF0000',
                strokeOpacity: 1.0,
                strokeWeight: 2
              });
              trippath.setMap(this.map);
            });
          }
        }
      });

      this.helper.loadGoogleScript("https://maps.googleapis.com/maps/api/js?key=" + environment.GOOGLE_KEY + "&libraries=places").then(() => {
        this.setMap();
      })
      if (this.tripdetail.confirmed_provider) {
        let json: any = { user_id: this.helper.user_details._id, token: this.helper.user_details.server_token, trip_id: this.trip_id, provider_id: this.tripdetail.confirmed_provider }
        this._usertripedetail.get_provider_info(json).then(res_data_p => {
          this.provider = res_data_p.provider;
          this.is_provider = true
          this.profile_image = this.IMAGE_URL + this.provider.picture;
        })
      }
    })

  }
  setMap() {
    let lat = Number(this.sourcelat);
    let lng = Number(this.sourcelng);

    this.map = new google.maps.Map(document.getElementById('map'), {
      zoom: 2,
      center: { lat, lng },
      draggable: true,
      zoomControl: true,
      scrollwheel: true,
      disableDoubleClickZoom: false,
      fullscreenControl: true
    });

    if (this.pickup_marker) {
      this.pickup_marker.setMap(null);
      this.pickup_marker = null;
    }
    const labels = "AB";
    let labelIndex = 0;
    let dest_labelIndex = 1;
    var bounds = new google.maps.LatLngBounds();
    if (lat && lng) {
      this.pickup_marker = new google.maps.Marker({
        position: { lat, lng },
        map: this.map,
        draggable: false,
        icon : DEFAULT_IMAGE.PICKUP_ICON,
      });
      bounds.extend(this.pickup_marker.position);
    }
    if (this.destinationAddress) {
      this.destinationAddress.forEach((address, index) => {
        let location = new google.maps.LatLng(address.location[0], address.location[1]);
        let marker = new google.maps.Marker({
          position: location,
          map: this.map,
          icon : DEFAULT_IMAGE.STOP_ICON
        });
        bounds.extend(marker.position);
      });
    }
   
    if (this.dest_marker) {
      this.dest_marker.setMap(null);
      this.dest_marker = null;
    }

    let destla = Number(this.destlat);
    let destln = Number(this.destlng);

    let myLatlng = new google.maps.LatLng(this.destlat, this.destlng);
    if (destla && destln) {
      this.dest_marker = new google.maps.Marker({
        position: myLatlng,
        map: this.map,
        draggable: false,
        icon : DEFAULT_IMAGE.DESTINATION_ICON,
      });
      bounds.extend(this.dest_marker.position);
    }

    this.map.fitBounds(bounds);

  }

  cancletrip() {
    this.confirmModelRef = this.modalService.show(this.confirmationTemplate, this.cancelModelConfig);
  }

  confirm(){
    var json = {
      user_id: this.helper.user_details._id, token: this.helper.user_details.server_token, trip_id: this.tripdetail._id
    }
    this._createTripService.cancle_Trip(json).then(response => {
      if (response.success) {
        this.cancel();
        this.historyDataHandler.emit();
      }
    });
  }

  //cancel trip modal close
  cancel(){
    this.confirmModelRef.hide()
  }

}
