import { Component, OnInit, TemplateRef, ViewChild, Input, ElementRef, HostListener } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CountryModel } from 'src/app/models/country.model';
import { UserModel } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service'
import { Helper } from 'src/app/shared/helper';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import country_list from 'src/assets/country_list.json'
import { LocationService } from 'src/app/services/location.service';
import { NotifiyService } from 'src/app/services/notifier.service';
// import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialAuthServiceConfig } from 'angularx-social-login';
import { NgZone } from '@angular/core';
import jwt_decode from "jwt-decode";
declare var google:any
declare var FB: any;

export class admin_detail {
  userSms: boolean;
  userEmailVerification: boolean;
  is_user_social_login:boolean;
}
@Component({
  selector: 'app-auth-modal',
  templateUrl: './auth-modal.component.html',
  styleUrls: ['./auth-modal.component.scss']
})
export class AuthModalComponent {
  admin_detail:admin_detail = new admin_detail();
  signUp_otp_Verification :boolean = false;
  emailotpValue:number;
  smsotpValue:number;
  otpForEmail:number;
  otpForSMS:number;
  emailError:boolean=false;
  smsError:boolean=false;

  modalRef: BsModalRef | null;
  country_code_modal: BsModalRef;
  forgot_Pass_modal: BsModalRef;
  modalRef2: BsModalRef;
  referral_modal: boolean = false;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-md modal-dialog-centered',
  };
  SearchText: any;
  otpvalue: number;

  buttonDisabled: boolean = false;
  user: UserModel;
  country: Array<CountryModel> = [];
  countries: any = [];
  maxNumberLength: Number;
  minNumberLength: Number;
  signupForm: UntypedFormGroup;
  loginForm: UntypedFormGroup;
  forgotPasswordForm: UntypedFormGroup;
  isChecked: boolean = true;

  contryname: string = '';
  sel_countryphonecode: string = '';
  referral_code: string = '';
  otpFiled: boolean;
  otpSMS: number;
  updatePassField: boolean;
  updatePassForm: UntypedFormGroup;
  is_invalid_email = false;
  userSubscription: Subscription;
  toastSubscription: Subscription;

  TERMS_URL = "legal/user-terms-conditions";
  PRIVACY_URL = "legal/user-privacy-policy";
  IMAGE_URL = environment.IMAGE_URL;
  current_location_phone_code: any;
  is_social_login: boolean = false;

  darkTheme = localStorage.getItem('vien-themecolor')
  logoClr:boolean=false;
  google_object :any
  facebook_object : any
  register_with_social = false
  is_register = false
  
  @Input() islogin: any;
  @ViewChild('template', { static: true }) template: TemplateRef<any>;
  @ViewChild('templateNested', { static: true }) templateNested: TemplateRef<any>;
  forgotCaptchaWidgetId: any;
  registerCaptchaWidgetId: any;
  loginCaptchaWidgetId: any;
  loginCaptchaToken: any;
  registerCaptchaToken: any = "123";
  forgotCaptchaToken: any = "123";



  constructor(private modalService: BsModalService, public _authService: AuthService,
    public _commanService: CommonService,
    public helper: Helper,
    public _locationService: LocationService,
    private _notifierService: NotifiyService,
    // private _socialAuthService: SocialAuthService,
    private ngZone:NgZone) { }
  ngOnInit(): void {
    this._locationService.set_current_location();
    this._initForms();

    this.userSubscription = this._authService.loginObservable.subscribe((user) => {
      if (user) {
        this.user = user;
      }
    });
    if(this.darkTheme.slice(0,4) == 'dark' ){
      this.logoClr=true;
    }
  }
  
  @HostListener('document:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    if (event.key === 'Escape' || event.code === 'Escape') {
      if(this.modalRef){
        this.modalRef.onHidden.subscribe(() => {
          this.is_register = true
          setTimeout(() => {
            this.loginForm.reset()
            this.register_with_social = false
          }, 500)
        })
      }
      if(this.forgot_Pass_modal){
        this.forgot_Pass_modal.onHidden.subscribe(() => {
          this.show();
          setTimeout(() => {
            this.isChecked = true;
            this.otpFiled = false;
            this.updatePassField = false;
            this.otpvalue = null;
            this.forgotPasswordForm.reset();
            this.updatePassForm.reset();
          }, 500)
        })
      }
      if(this.modalRef2){
        this.modalRef2.onHidden.subscribe(() => {
          this.is_register = false;
          setTimeout(() => {
            this.signupForm.reset()
            this.register_with_social = false
          }, 500)
        })
      }
      if(this.referral_modal){
        this.referral_modal = false;
        setTimeout(() => {
          this.referral_code = ''
        }, 500);
      }
    }
  }

  google_initialize(){
        google.accounts.id.initialize({
          client_id: "291342805628-pjkukve8137kif2smf5ggp2dchfc7j59.apps.googleusercontent.com",
          callback: data => this.handleCredentialResponse(data)
        });
    
        google.accounts.id.renderButton(
          document.getElementById("buttonDiv"),
          { theme: "outline", size: "large" ,shape:'circle',type:'icon'}
        );
  }

  facebook_initialize(){
    FB.init({
      appId: '638653764267149',
      xfbml: true,
      status: true,
      version: 'v16.0'
    });
    FB.AppEvents.logPageView();
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }


  google_initialize_1(){
    google.accounts.id.initialize({
      client_id: "291342805628-pjkukve8137kif2smf5ggp2dchfc7j59.apps.googleusercontent.com",
      callback: data => this.handleCredentialResponse(data)
    });

    google.accounts.id.renderButton(
      document.getElementById("buttonDiv1"),
      { theme: "outline", size: "large" ,shape:'circle',type:'icon'}
    );
  }


  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  openModal2(template: TemplateRef<any>): void {
    this.countrylist();
    if (this.current_location_phone_code) {
      this.sel_countryphonecode = this.current_location_phone_code;
    } else {
      this.sel_countryphonecode = this.countries[0].code;
    }
    this.modalRef2 = this.modalService.show(template, this.config);
    this.google_initialize_1()
    this.registerCaptchaWidgetId = (window as any).turnstile.render('#captcha-container-register', {
      sitekey: environment.CLOUDFLARE_CAPTCHA_SITEKEY,
      callback: function (token) {
        (window as any).registerCaptchaToken = token;
      },
      theme: this.logoClr ? 'dark' : 'light'
    });
  }
  openPhoneCodeModal(openPhoneCode: TemplateRef<any>): void {
    setTimeout(() => {
      let search_country = document.getElementById('search-country');
      search_country.focus();
    }, 500);
    this.country_code_modal = this.modalService.show(openPhoneCode, { class: 'modal-sm modal-dialog-centered ' });
  }
  openforgotPassModal(forgotPass: TemplateRef<any>): void {
    if (this.current_location_phone_code) {
      this.sel_countryphonecode = this.current_location_phone_code;
      this.forgotPasswordForm.patchValue({
        country_phone_code: this.sel_countryphonecode,
      })
    } else {
      this.sel_countryphonecode = this.countries[0].code;
      this.forgotPasswordForm.patchValue({
        country_phone_code: this.sel_countryphonecode,
      })
    }
    this.forgot_Pass_modal = this.modalService.show(forgotPass, this.config);
    setTimeout(() => {
      this.forgotCaptchaWidgetId = (window as any).turnstile.render('#captcha-container-forgot', {
        sitekey: environment.CLOUDFLARE_CAPTCHA_SITEKEY,
        callback: function (token) {
          (window as any).forgotCaptchaToken = token;
        },
        theme: this.logoClr ? 'dark' : 'light'
      });
    }, 100)
  }

  show(): void {
    this.countrylist();
    if (this.sel_countryphonecode) {
      if (this.current_location_phone_code) {
        this.sel_countryphonecode = this.current_location_phone_code;
      } else {
        this.sel_countryphonecode = this.countries[0].code;
      }
    }
    this.modalRef = this.modalService.show(this.template, this.config);
    this.google_initialize()
    this.facebook_initialize()
    this.forgotCaptchaWidgetId = (window as any).turnstile.render('#captcha-container-login', {
      sitekey: environment.CLOUDFLARE_CAPTCHA_SITEKEY,
      callback: function (token) {
        (window as any).loginCaptchaToken = token;
      },
      theme: this.logoClr ? 'dark' : 'light'
    });
    if (this.modalRef2) {
      this.modalRef2.hide();
    }
  }
  handleCredentialResponse(value:any){
    this.register_with_social = true
    this.google_object = jwt_decode(value.credential)
    if(this.is_register == true){
      this.registerWithGoogle(this.google_object)
      this.ngZone.run(()=>{
        this.register_with_social = true
      })
    }else{
      this.signInWithGoogle(this.google_object)
    }
  }

  showRegister(): void {
    this.countrylist();
    if (this.sel_countryphonecode) {
      if (this.current_location_phone_code) {
        this.sel_countryphonecode = this.current_location_phone_code;
        this.contryname =this._locationService._current_location.country_name;
      } else {
        this.sel_countryphonecode = this.countries[0].code;
        this.contryname =this.countries[0].name;
      }
    }
    this.is_register=true;
    this.modalRef2 = this.modalService.show(this.templateNested, this.config);
    this.google_initialize_1()
    if (this.modalRef) {
      this.modalRef.hide();
    }
    this.registerCaptchaWidgetId = (window as any).turnstile.render('#captcha-container-register', {
      sitekey: environment.CLOUDFLARE_CAPTCHA_SITEKEY,
      callback: function (token) {
        (window as any).registerCaptchaToken = token;
      },
      theme: this.logoClr ? 'dark' : 'light'
    });
  }
  closeFirstModal(): void {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
  }
  _initForms() {
    this.signupForm = new UntypedFormGroup({
      first_name: new UntypedFormControl(null, Validators.required),
      last_name: new UntypedFormControl(null, Validators.required),
      email: new UntypedFormControl(null, Validators.required),
      phone: new UntypedFormControl(null, Validators.required),
      country_phone_code: new UntypedFormControl(null, Validators.required),
      password: new UntypedFormControl(null, [Validators.minLength(6)]),
      is_term_checked: new UntypedFormControl(null, Validators.required),
      login_by: new UntypedFormControl('manual'),
      city: new UntypedFormControl(''),
      address: new UntypedFormControl(''),
      social_id:new UntypedFormControl('')
    })
    this.loginForm = new UntypedFormGroup({
      phone: new UntypedFormControl(null, [Validators.required]),
      password: new UntypedFormControl(null, Validators.required),
      country_phone_code: new UntypedFormControl(null, Validators.required),
      recaptcha: new UntypedFormControl(null)
    })
    this.forgotPasswordForm = new UntypedFormGroup({
      email: new UntypedFormControl(null, [Validators.required]),
      phone: new UntypedFormControl(null, [Validators.required]),
      country_phone_code: new UntypedFormControl(null, Validators.required)
    })
    this.updatePassForm = new UntypedFormGroup({
      password: new UntypedFormControl(null, [Validators.required]),
      confirmPassword: new UntypedFormControl(null, [Validators.required]),
    })
  }
  countryPhoneCode(country) {
    this.contryname = country.name;
    this.sel_countryphonecode = country.code;
    this.country_code_modal.hide();
    this.SearchText = '';
  }
  forgotInput(boolean) {
    if (boolean == true) {
      this.isChecked = true;
    } else {
      this.isChecked = false;
    }
  }
  signIn() {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }
    let uuid = this.helper.uuid;
    let device_token = uuid;
    localStorage.setItem('device_token', device_token);
    if (this.sel_countryphonecode) {
      this.loginForm.value.country_phone_code = this.sel_countryphonecode;
    }
    this.buttonDisabled = true;
    var json: any = { country_phone_code: this.loginForm.value.country_phone_code, email: this.loginForm.value.phone, password: this.loginForm.value.password, device_token: device_token, login_by: "manual", device_type: 'web', captchaToken: (window as any).loginCaptchaToken };
    (window as any).turnstile.reset(this.loginCaptchaWidgetId);
    this._authService.user_login(json).then(islogin => {
      this.helper.islogin = islogin;

      if (islogin) {
        this.modalRef.hide();
        setTimeout(() => {
          this.buttonDisabled = false;
          this.loginForm.reset();
        }, 500)
      } else {
        this.buttonDisabled = false;
      }
    });
  }

  countrylist() {
    let json = {}
    this._commanService.get_setting_detail(json).then(data => {
      this.maxNumberLength = data.setting_detail.maximum_phone_number_length;
      this.minNumberLength = data.setting_detail.minimum_phone_number_length;
      this.admin_detail=data.setting_detail; 
    })
    this.countries = country_list;
    this.countries.forEach((data) => {
      if (data.alpha2 == this._locationService._current_location.country_code) {
        this.current_location_phone_code = data.code;
      }
    })
    if (this.current_location_phone_code) {
      this.loginForm.patchValue({
        country_phone_code: this.current_location_phone_code,
      })
      
      this.contryname=this._locationService._current_location.country_name,
      this.signupForm.patchValue({
        country_phone_code: this.current_location_phone_code,
      })
      this.forgotPasswordForm.patchValue({
        country_phone_code: this.current_location_phone_code,
      })
    } else {
      this.loginForm.patchValue({
        country_phone_code: this.countries[0].code,
      })
      this.contryname= this.countries[0].name,
      this.signupForm.patchValue({
        country_phone_code: this.countries[0].code,
      })
      this.forgotPasswordForm.patchValue({
        country_phone_code: this.countries[0].code,
      })
    }
  }

  // Forgot Password Flow

  forgotPassword() {
    if (this.isChecked == true) {
      if (!this.forgotPasswordForm.value.email) {
        this.forgotPasswordForm.get('email').markAllAsTouched();
        return;
      }
      this.buttonDisabled = true;
      let json: any = { email: this.forgotPasswordForm.value.email, type: 1, captchaToken: (window as any).forgotCaptchaToken };
      (window as any).turnstile.reset(this.forgotCaptchaWidgetId);
      this._authService.user_forgot_password_Email(json).then(isForgot => {
        if (isForgot) {
          this.forgot_Pass_modal.hide();
          setTimeout(() => {
            this.buttonDisabled = false;
            this.isChecked = true;
            this.forgotPasswordForm.reset();
          }, 500)
        } else {
          this.buttonDisabled = false;
        }
      });
    }
    else {
      if (!this.forgotPasswordForm.value.phone) {
        this.forgotPasswordForm.get('phone').markAllAsTouched();
        return;
      }
      if (this.sel_countryphonecode) {
        this.forgotPasswordForm.value.country_phone_code = this.sel_countryphonecode;
      }
      this.buttonDisabled = true;
      let json: any = { phone: this.forgotPasswordForm.value.phone, country_phone_code: this.forgotPasswordForm.value.country_phone_code, captchaToken: (window as any).forgotCaptchaToken };
      (window as any).turnstile.reset(this.forgotCaptchaWidgetId);

      this._authService.forgotPasswordPhone(json).then(isotp => {

        if (isotp.otpForSMS) {
          this.buttonDisabled = false;
          this.otpFiled = true;
          this.otpSMS = isotp.otpForSMS;
          this.isChecked = true;
        } else {
          this.buttonDisabled = false;
        }
      });

    }
  }

  otpsmsMatch() {
    if (this.otpvalue == this.otpSMS) {
      this.updatePassField = true;
    } else {
      this._notifierService.showNotification('error', this.helper.trans.instant('validation-title.wrong-otp'));
    }

  }
  updatePassword() {
    if (this.updatePassForm.invalid) {
      this.updatePassForm.markAllAsTouched();
      return;
    }
    this.buttonDisabled = true;
    if (this.updatePassForm.value.password == this.updatePassForm.value.confirmPassword) {
      let json: any = { phone: this.forgotPasswordForm.value.phone, country_phone_code: this.forgotPasswordForm.value.country_phone_code, password: this.updatePassForm.value.confirmPassword }
      this._authService.userupdatePassword(json).then(isSuccess => {
        if (isSuccess) {
          this.forgot_Pass_modal.hide();
          setTimeout(() => {
            this.show();
            this.isChecked = true;
            this.otpFiled = false;
            this.updatePassField = false;
            this.otpvalue = null;
            this.forgotPasswordForm.reset();
            this.updatePassForm.reset();
            this.buttonDisabled = false;
          }, 500)
        }
      });
    } else {
      this.buttonDisabled = false;
      this._notifierService.showNotification('error', this.helper.trans.instant('validation-title.password-not-match'));
    }
  }
  signUp() {
    if(this.signupForm.value.login_by == 'google' || this.signupForm.value.login_by == 'facebook'){
      this.signupForm.controls['password'].setValidators(null); 
      this.signupForm.controls['password'].setErrors(null);     
    }
    if (this.signupForm.invalid) {
      this.signupForm.markAllAsTouched();
      return;
    }
    this.is_invalid_email = false;
    var email_validation = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email_validation.test(this.signupForm.value.email)) {
      this.is_invalid_email = true;
      return;
    }
    if (this.sel_countryphonecode) {
      this.signupForm.value.country_phone_code = this.sel_countryphonecode;
    }
    if (this.signupForm.value.is_term_checked == true) {
      
      if(this.admin_detail.userEmailVerification || this.admin_detail.userSms){
        let json: any = { type: 1, email: this.signupForm.value.email, phone: this.signupForm.value.phone, country_phone_code: this.signupForm.value.country_phone_code, captchaToken: (window as any).registerCaptchaToken };
        (window as any).turnstile.reset(this.registerCaptchaWidgetId);
        this._authService.Verification(json).then(data => {
          if(data){
          this.buttonDisabled = true;
          this.signUp_otp_Verification=true;
          this.otpForEmail=data.otpForEmail;
          this.otpForSMS=data.otpForSMS;
        }
      })
      }else{
        this.buttonDisabled = true;
        this.register();
      }
    }
  }

  otpVerification(){
  if(this.admin_detail.userEmailVerification && this.admin_detail.userSms){
    if(this.emailotpValue != this.otpForEmail){
      this.emailError=true;
    }
    if(this.smsotpValue != this.otpForSMS){
      this.smsError=true;
    }
    if((this.smsotpValue == this.otpForSMS) && (this.emailotpValue == this.otpForEmail)){
      this.emailError=false;
      this.smsError=false;
      this.signUp_otp_Verification=false;
      this.register();
    }
  }else{
    if(this.admin_detail.userSms){
      if(this.smsotpValue == this.otpForSMS){
        this.smsError=false;
        this.signUp_otp_Verification=false;
        this.register();
      }else{
        this.smsError=true;
      }
    }else if(this.admin_detail.userEmailVerification){
      if(this.emailotpValue == this.otpForEmail){
        this.emailError=false;
        this.signUp_otp_Verification=false;
        this.register();
      }else{
        this.emailError=true;
      }
    }
  }
  }

  register() {
    const signUpForm = new FormData();
    signUpForm.append('first_name', this.signupForm.value.first_name);
    signUpForm.append('last_name', this.signupForm.value.last_name);
    signUpForm.append('password', this.signupForm.value.password);
    signUpForm.append('email', this.signupForm.value.email);
    signUpForm.append('phone', this.signupForm.value.phone);
    signUpForm.append('country_phone_code', this.signupForm.value.country_phone_code);
    signUpForm.append('country', this.contryname);
    signUpForm.append('city', this.signupForm.value.city);
    signUpForm.append('login_by', this.signupForm.value.login_by);
    signUpForm.append('address', this.signupForm.value.address);
    signUpForm.append('device_type', 'web');
    if(this.google_object){
      signUpForm.append('social_unique_id',this.google_object.sub)
    }
    if(this.facebook_object){
      signUpForm.append('social_unique_id',this.facebook_object.id)
    }

    this._authService.user_register(signUpForm).then(Response => {
      if (Response.success) {
        this.emailotpValue = null;
        this.smsotpValue = null;
        var json = {};
        json['user_id'] = this._authService.user_detail.user_id;
        json['server_token'] = this._authService.user_detail.token;
        this.modalRef2.hide();
        if(Response.data.user_detail.country_detail.is_referral == true){
          this.referral_modal = true;
        }
        else{
          window.location.reload()
        }
        setTimeout(() => {
          this.buttonDisabled = false;
          this.signupForm.reset();
          this.referral_modal = true;
        }, 800);
        this._locationService.set_current_location();
        this.helper.isUpadtedlocalStorage();
      } else {
        this.buttonDisabled = false;
      }
    })
  }
  referralCode(is_skip: number) {
    if (is_skip == 0) {
      if(this.referral_code == ''){
        this._notifierService.showNotification('error', this.helper.trans.instant('error-code.please-enter-referral-code-first'))
        return
      }
      let json: any = { server_token: this.helper.user_details.server_token, user_id: this.helper.user_details._id, is_skip: is_skip, referral_code: this.referral_code };
      this._authService.UserRefrral(json).then(is_success => {
        if (is_success) {
          this.referral_modal = false;
          setTimeout(() => {
            this.referral_code = '';
          }, 500)
          this.helper.isUpadtedlocalStorage();
        }
      })
    } else {
      this.referral_modal = false;
      this.helper.isUpadtedlocalStorage();
    }

  }
  signUpClose() {
    this.is_register = false
    this.modalRef2.hide();
    (window as any).turnstile.remove(this.registerCaptchaWidgetId);
    setTimeout(() => {
      this.signupForm.reset()
      this.register_with_social = false
    }, 500)
  }
  loginClose() {
    this.is_register = true
    this.modalRef.hide();
    (window as any).turnstile.remove(this.loginCaptchaWidgetId);
    setTimeout(() => {
      this.loginForm.reset()
      this.register_with_social = false
    }, 500)
  }
  forgotClose() {
    this.forgot_Pass_modal.hide();
    (window as any).turnstile.remove(this.forgotCaptchaWidgetId);
    this.show();
    setTimeout(() => {
      this.isChecked = true;
      this.otpFiled = false;
      this.updatePassField = false;
      this.otpvalue = null;
      this.forgotPasswordForm.reset();
      this.updatePassForm.reset();
    }, 500)

  }

  // Clear App Data

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }

    if (this.toastSubscription) {
      this.toastSubscription.unsubscribe();
    }
  }

  signInWithGoogle(user:any): void {
    let uuid = this.helper.uuid
    let device_token = uuid
    localStorage.setItem('device_token', device_token);
    this._authService.user_social_login({
          social_unique_id: user.sub,
          login_by: 'google',
          device_token:device_token,
          device_type:'web'
    }).then((islogin)=>{
      this.ngZone.run(()=>{
        this.helper.islogin = islogin;
        if (islogin) {
            this.modalRef.hide();
          setTimeout(() => {
            this.buttonDisabled = false;
            this.loginForm.reset();
          }, 500)
        } else {
          this.buttonDisabled = false;
          this._notifierService.showNotification('error',this.helper.trans.instant('error-code.448'));
      }
      })
    })
  }

  signInWithFB(): void {
    // this._socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(user => {
    //   if (user) {
    //     let uuid = this.helper.uuid;
    //     let device_token = uuid;
    //     localStorage.setItem('device_token', device_token);

    //     this._authService.user_social_login({
    //       social_unique_id: user.id,
    //       login_by: 'facebook',
    //       password: '',
    //       username: '',
    //       device_token:device_token,
    //       device_type:'web'
    //     }).then(islogin => {
    //       this.ngZone.run(()=>{
    //         this.helper.islogin = islogin;
    //         if (islogin) {
    //             this.modalRef.hide();
    //           setTimeout(() => {
    //             this.buttonDisabled = false;
    //             this.loginForm.reset();
    //           }, 500)
    //         } else {
    //           this.buttonDisabled = false;
    //           this._notifierService.showNotification('error',this.helper.trans.instant('error-code.448'));
    //       }
    //       })
    //     });
    //   }
    // })

    FB.login( (response) => {
      console.log('get response');
      console.log(response);
      FB.api('/me', { fields: 'name,email' },  (data) => {
        if (data) {
          let uuid = this.helper.uuid;
          let device_token = uuid;
          localStorage.setItem('device_token', device_token);

          this._authService.user_social_login({
            social_unique_id: data.id,
            login_by: 'facebook',
            password: '',
            username: '',
            device_token:device_token,
            device_type:'web'
          }).then(islogin => {
            this.ngZone.run(()=>{
              this.helper.islogin = islogin;
              if (islogin) {
                  this.modalRef.hide();
                setTimeout(() => {
                  this.buttonDisabled = false;
                  this.loginForm.reset();
                }, 500)
              } else {
                this.buttonDisabled = false;
                this._notifierService.showNotification('error',this.helper.trans.instant('error-code.448'));
              }
            })
          });
        }
      });
    });

  }
  registerWithGoogle(user) {
    this.register_with_social = true
    let split_name = user.name.split(' ')
    this.signupForm.patchValue({
      first_name: split_name[0],
      last_name: split_name[1],
      social_id: user.sub,
      login_by: 'google',
      email: user.email
    })
  }

  registerWithFB() {
    // this.register_with_social = true
    // this._socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(user => {
    //   if (user) {
    //     this.is_social_login = true
    //     this.facebook_object = user
    //     this.signupForm.patchValue({
    //       first_name: user.firstName,
    //       last_name: user.lastName,
    //       social_id: user.id,
    //       login_by: 'facebook',
    //       email: user.email
    //     })
    //   }
    // })
    FB.login( (response) => {
      console.log('get res');
      console.log(response);
      FB.api('/me', { fields: 'name,email' },  (data) => {
        console.log(data);
        if (data) {
          this.register_with_social = true;
          let firstName = (data.name).split(' ')[0]
          let lastName = (data.name).split(' ')[1]
          this.signupForm.patchValue({
            first_name: firstName,
            last_name: lastName,
            social_id: data.id,
            login_by: 'facebook',
            email: data.email,
          });
        }
      });
    });

  }
}

