import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LightboxModule } from 'ngx-lightbox';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { ListPageHeaderComponent } from './list-page-header/list-page-header.component';
import { ComponentsPagesModule } from '../../components/pages/components.pages.module';
import { ComponentsCardsModule } from '../../components/cards/components.cards.module';
import { ComponentsPlayerModule } from 'src/app/components/player/components.player.module';
import { LayoutContainersModule } from '../layout/layout.containers.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { HistoryModalComponent } from './history-modal/history-modal.component';
import { ProfileEditModalComponent } from './profile-edit-modal/profile-edit-modal.component';
import { WalletHistoryModalComponent } from './wallet-history-modal/wallet-history-modal.component';
import { DocumentEditModalComponent } from './document-edit-modal/document-edit-modal.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { NouisliderModule } from 'ng2-nouislider';
import { TranslateModule } from '@ngx-translate/core';
import { AuthModalComponent } from './auth-modal/auth-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { WizardsContainersModule } from '../wizard/wizards.containers.module';
import { BooktripProfilesHeaderComponent } from './booktrip-profiles-header/booktrip-profiles-header.component';
import { CreateTripCarouselComponent } from './create-trip-carousel/create-trip-carousel.component';
import { ComponentsCarouselModule } from 'src/app/components/carousel/components.carousel.module';
import { FilterPipe } from 'src/app/pipe/search-pipe/filter.pipe'

import { RunningComponent } from './running/running.component';
import { AddcardModalComponent } from './addcard-modal/addcard-modal.component';
import { RegisterAddressModelComponent } from './register-address-model/register-address-model.component';

@NgModule({
  declarations: [
    ListPageHeaderComponent,
    HistoryModalComponent,
    ProfileEditModalComponent,
    WalletHistoryModalComponent,
    DocumentEditModalComponent,
    AuthModalComponent,
    BooktripProfilesHeaderComponent,
    CreateTripCarouselComponent,
    RunningComponent,
    // AddcardModalComponent,
    FilterPipe,
    RegisterAddressModelComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    CollapseModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutContainersModule,
    NgSelectModule,
    LightboxModule,
    ComponentsPagesModule,
    ComponentsCardsModule,
    ComponentsPlayerModule,
    BsDatepickerModule.forRoot(),
    RatingModule.forRoot(),
    TimepickerModule.forRoot(),
    TabsModule.forRoot(),
    AccordionModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    DropzoneModule,
    NouisliderModule,
    TranslateModule,
    WizardsContainersModule,
    ComponentsCarouselModule,
  ],
  exports: [
    ListPageHeaderComponent,
    HistoryModalComponent,
    ProfileEditModalComponent,
    WalletHistoryModalComponent,
    DocumentEditModalComponent,
    AuthModalComponent,
    BooktripProfilesHeaderComponent,
    CreateTripCarouselComponent,
    RunningComponent,
    // AddcardModalComponent,
    RegisterAddressModelComponent
  ]
})
export class PagesContainersModule { }
